.template-icon {
  width: 100%;
  height: auto;
  filter: none !important;
  transition: none;
}

.flyout-panel {
  position: absolute;
  left: -400px;
  width: 400px;
  background-color: #1a1a1a;
  color: #e0e0e0;
  padding: 15px;
  top: 0;
  bottom: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  max-height: 100vh;
}

.flyout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.flyout-content {
  overflow-y: auto;
  flex-grow: 1;
  padding: 15px;
}

.close-button {
  background: none;
  border: none;
  color: #e0e0e0;
  font-size: 1.2em;
  cursor: pointer;
  position: static;
  align-self: flex-end;
  padding: 8px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.close-button:hover {
  background-color: #252525;
  color: #fae150;
}

button {
  padding: 8px;
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

button img {
  width: 24px;
  height: 24px;
  transition: filter 0.2s ease;
}

button:hover img {
  filter: none !important;
}

/* 스크롤바 스타일 */
.flyout-content::-webkit-scrollbar {
  width: 4px;
}

.flyout-content::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.flyout-content::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 4px;
}

.template-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.remove-background-option {
  padding: 10px;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
}

.remove-background-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #252525;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  color: #e0e0e0;
}

.remove-background-button:hover {
  background-color: #333333;
}

.remove-background-button img {
  width: 20px;
  height: 20px;
}

.color-section {
  padding: 15px;
  margin-bottom: 10px;
}

.color-title {
  font-size: 14px;
  margin-bottom: 10px;
  color: #333;
  font-weight: 500;
}

.color-button {
  flex: 0 0 auto;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: 0 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.color-button:hover {
  transform: scale(1.1);
}

.color-palette {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 10px 25px;
  gap: 5px;
  scroll-behavior: smooth;
  white-space: nowrap;
}

.color-palette::-webkit-scrollbar {
  display: none;
}

.check-pattern-palette,
.half-pattern-palette {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 5px;
  padding: 10px 15px;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.check-pattern-palette::-webkit-scrollbar,
.half-pattern-palette::-webkit-scrollbar {
  display: none;
}

.template-section {
  margin-bottom: 20px;
}

.template-title {
  color: #e0e0e0;
  font-size: 14px;
  font-weight: 500;
  margin: 15px 0 10px 15px;
}

.palette-container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.scroll-button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  padding: 0;
}

.scroll-button.left {
  left: 0;
}

.scroll-button.right {
  right: 0;
}

.scroll-button svg {
  fill: #ffffff;
  width: 20px;
  height: 20px;
}

.scroll-button:hover {
  background: rgba(0, 0, 0, 0.7);
}

.bear-pattern-palette {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 5px;
  padding: 10px 15px;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.bear-pattern-palette::-webkit-scrollbar {
  display: none;
}
