.email-verify-page {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 100vh;
  background-color: white;
  padding: 40px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, sans-serif;
}

.email-verify-content {
  text-align: center;
  padding: 2rem;
}

.email-verify-logo {
  width: 48px;
  height: 48px;
  margin-bottom: 24px;
  object-fit: contain;
}

.email-verify-title {
  font-size: 24px;
  color: #1a1a1a;
  margin: 16px 0;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: -0.2px;
}

.email-verify-text {
  color: #4a4a4a;
  margin: 8px 0 20px 0;
  font-size: 14px;
  line-height: 1.5;
}

.email-verify-continue-btn {
  margin-top: 8px;
  padding: 8px 16px;
  background-color: white;
  color: #333;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.email-verify-continue-btn:hover {
  background-color: #f8f9fa;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.email-verify-continue-btn:active {
  background-color: #f1f3f5;
  transform: translateY(1px);
}
.verify-timer {
  color: #ff4d4f;
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1rem;
}

.spam-alert {
  background-color: #fff3cd;
  color: #856404;
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
  font-weight: 500;
}

.resend-button {
  background-color: #fae150;
  color: #231c16;
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  font-weight: 600;
  margin: 1rem 0;
  cursor: pointer;
  transition: all 0.2s ease;
}

.resend-button:hover:not(.disabled) {
  background-color: #f8d730;
  transform: translateY(-1px);
}

.resend-button.disabled {
  background-color: #e9ecef;
  color: #868e96;
  cursor: not-allowed;
}

.email-tips {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  margin-top: 2rem;
  text-align: left;
}

.email-tips h3 {
  color: #495057;
  margin-bottom: 1rem;
}

.email-tips ul {
  list-style-type: none;
  padding: 0;
}

.email-tips li {
  color: #495057;
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;
}

.email-tips li:before {
  content: "•";
  position: absolute;
  left: 0.5rem;
  color: #fae150;
}

.email-verify-warning {
  color: #ff6b6b;
  font-size: 0.9em;
  margin-top: 1em;
  padding: 1em;
  background-color: rgba(255, 107, 107, 0.1);
  border-radius: 4px;
}
