body,
#root,
.App {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 16px;
}

:root {
  --editor-font-family: Arial;
}

.custom-editor-container .DraftEditor-root,
.custom-editor-container .DraftEditor-editorContainer,
.custom-editor-container .public-DraftEditor-content {
  font-family: var(--editor-font-family) !important;
}

.custom-editor-container .DraftEditor-root *,
.custom-editor-container .DraftEditor-editorContainer *,
.custom-editor-container .public-DraftEditor-content * {
  font-family: inherit !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  line-height: 1.3;
  padding: 3px 2px;
}

h1 {
  font-size: 2.5em;
  margin-top: 1em;
  margin-bottom: 0.6em;
}

p {
  margin-top: 1em;
  margin-bottom: 1em;
}
/*
a {
  color: rgb(55, 53, 47);
  text-decoration: underline;
  opacity: 0.7;
}
*/
a:hover {
  opacity: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*
button {
  font-family: "Inter", sans-serif;
  background-color: #f7f6f3;
  color: #37352f;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

button:hover {
  background-color: #eae9e5;
}
  */
/*
input[type="text"],ㄴ
input[type="color"],
select {
  font-family: "Inter", sans-serif;
  border: 1px solid #e0e0e0;

  padding: 6px 8px;
  font-size: 14px;
  background-color: #ffffff;
  color: #37352f;
}

input[type="text"]:focus,
input[type="color"]:focus,
select:focus {
  outline: none;
  border-color: #0066ff;
}
*/

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  background-color: #ffffff;
  transition: background-color 0.3s ease;
}

/* MainLayout이 적용된 페이지와 Unitnote 페이지에 다크모드 적용 */
html.dashboard-page,
body.dashboard-page,
html[data-page="unitnote"],
body[data-page="unitnote"] {
  background-color: #000000 !important;
}
