/** {
  font-family: "DM Sans", sans-serif;
}

body:not(.custom-editor-container),
body:not(.custom-editor-container) * {
  font-family: "DM Sans", sans-serif;
}
*/
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: var(--dark-surface);
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  border: 1px solid var(--dark-border);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  position: relative;
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.modal-content h2 {
  margin-bottom: 15px;
  font-size: 24px;
  color: var(--dark-text);
}

.modal-content label {
  display: block;
  margin-top: 10px;
  text-align: left;
  font-weight: bold;
  color: var(--dark-text-secondary);
}
.modal-input {
  width: 80%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid var(--dark-border);
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
  background-color: var(--dark-bg);
  color: var(--dark-text);
}

.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--dark-border);
  border-radius: 5px;
  font-size: 16px;
  line-height: 1.5;
  background-color: var(--dark-bg);
  color: var(--dark-text);
}

.modal-content button {
  margin-top: -10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #fae150;
  color: #231c16;
}

.modal-content button:hover {
  background-color: #fae150;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.modal-content button:active {
  transform: translateY(0);
  box-shadow: none;
}

.modal-content button.primary {
  background-color: var(--accent-color);
  border: none;
}

.modal-content button.primary:hover {
  background-color: var(--accent-hover);
}

.modal-content button.secondary {
  background-color: #ffffff;
  border: 1px solid #d1d1d1;
}

.modal-content button.secondary:hover {
  background-color: #f7f6f3;
}

.modal-content button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(46, 170, 220, 0.3);
}

.modal-content button + button {
  margin-left: 10px;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
  color: var(--dark-text);
}

.close:hover {
  color: var(--accent-color);
  opacity: 0.8;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--dark-border);
  border-radius: 5px;
  font-size: 16px;
  line-height: 1.5;
  background-color: var(--dark-bg);
  color: var(--dark-text);
}

.open-modal-btn,
.add-course-btn {
  background-color: #333;
  color: #e0e0e0;
  border: 1px solid #444;
  padding: 8px 16px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.open-modal-btn:hover,
.add-course-btn:hover {
  background-color: #444;
}

.error {
  color: red;
  margin-bottom: 10px;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.tag {
  display: inline-flex;
  align-items: center;
  background-color: rgb(255, 190, 186);
  border-radius: 10px;
  box-sizing: border-box;
  color: rgb(55, 53, 47);
  font-size: 11.2px;
  line-height: 1;
  padding: 2px 6px;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
}

.tag .remove-tag-btn {
  background: none;
  border: none;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  cursor: pointer;
  padding: 0;
  margin-left: 4px;
  margin-top: 0px !important;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
}

.tag .remove-tag-btn:hover,
.tag .remove-tag-btn:focus {
  color: rgba(0, 0, 0, 0.8);
  background-color: transparent;
  outline: none;
}

.tag-colors {
  display: flex;
  margin-top: 10px;
}

.tag-color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
  border: 2px solid transparent;
}

.tag-color.selected {
  border: 2px solid #000;
}

.thumbnail-options {
  display: flex;
  margin-top: 10px;
}

.thumbnail-option {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid transparent;
}

.thumbnail-option.selected {
  border: 2px solid #000;
}

.selected-thumbnail {
  width: 50px;
  height: auto;
  margin-top: 25px;
  max-width: 100%;
}

.table-thumbnail {
  width: 50px;
  height: 50px;
}

.course-cards {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-right: 10px;

  height: 100%;
  overflow-y: auto;
}

.courses-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.courses-header {
  flex-shrink: 0;
  color: #e0e0e0;
}

.courses-actions {
  flex-shrink: 0;
}

.courses-list {
  flex-grow: 1;
  overflow: hidden;
}

.course-cards::-webkit-scrollbar {
  width: 8px;
}

.course-cards::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.course-cards::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.course-cards::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.course-card {
  display: flex;
  background-color: var(--dark-surface);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  transition: background-color 0.2s ease;
  cursor: pointer;
}

.course-card:hover {
  background-color: var(--dark-surface-hover);
  transform: translateY(-2px);
  transition: all 0.3s ease;
}

.course-card.dragging {
  background-color: var(--dark-surface-hover);
  opacity: 0.9;
  cursor: grabbing;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  transform: scale(1.02);
  transition: all 0.2s ease;
}

.course-card-left {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 10px;
  background-color: #1e1e1e;
  border-radius: 8px;
  margin: 10px;
}

.card-thumbnail {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: none;
}

.course-card-middle {
  flex-grow: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.course-name {
  font-size: 1em;
  margin: 0 0 3px 0;
  cursor: pointer;
  color: #e0e0e0;
}

.course-description {
  font-size: 0.8em;
  color: #b0b0b0;
  margin: 0 0 5px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.course-meta {
  display: flex;
  align-items: center;
  gap: 5px;
}

.created-at {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #666;
  font-size: 14px;
}

.meta-icon {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  margin-top: 5px;
}

.course-card-right {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  gap: 8px !important;
  padding: 10px 8px !important;
  background-color: #1e1e1e !important;
  width: 40px !important;
  align-items: center !important;
}

.course-icon-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.course-icon-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.course-icon-btn img {
  width: 15px;
  height: 15px;
  filter: invert(100%) !important;
}

.course-card-body h3 {
  margin: 0;
  font-size: 18px;
  color: #37352f;
  margin-bottom: 10px;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
}

.course-card-body p {
  margin: 4px 0;
  color: #4d4d4d;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.5;
}

.course-card-footer button {
  background-color: #ebeced;
  color: #37352f;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 12px;
}

.course-card-footer button:hover {
  background-color: #e0e0e0;
}

.course-card-footer .icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  transition: opacity 0.3s;
}

.course-card-footer .icon:hover {
  opacity: 0.7;
}

.empty-state {
  text-align: center;
  padding: 40px 0;
  color: #37352f;
}

.empty-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.open-modal-btn.large {
  font-size: 16px;
  padding: 10px 20px;
  margin-top: 20px;
}

.plus-icon {
  font-size: 20px;
  margin-right: 8px;
}

.guide-message {
  background-color: #f7f6f3;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  padding: 10px 15px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #37352f;
}

.open-modal-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 0;
  border: none;
  border-radius: 24px;
  background: linear-gradient(
    135deg,
    var(--yellow-primary),
    var(--yellow-hover)
  );
  color: #1a1a1a;
  font-weight: 600;
  padding: 10px 24px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px var(--yellow-shadow);
}

.open-modal-btn:hover {
  background: linear-gradient(
    135deg,
    var(--yellow-hover),
    var(--yellow-active)
  );
  transform: translateY(-2px);
  box-shadow: 0 4px 12px var(--yellow-shadow);
}

.open-modal-btn:active {
  transform: translateY(0);
  background: var(--yellow-active);
  box-shadow: 0 2px 4px var(--yellow-shadow);
}

.add-icon {
  filter: brightness(0) saturate(100%) invert(93%) sepia(0%) saturate(0%)
    hue-rotate(213deg) brightness(92%) contrast(92%);
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.courses-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.courses-header-image {
  width: 150px;
  height: 150px;
  margin-right: 25px;
}

.courses-header h1 {
  font-size: 3rem;
  margin: 0;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 3px;
  color: #37352f;
  font-size: 14px;
  transition: background-color 0.2s ease;
  white-space: nowrap;
  overflow: visible;
  min-width: max-content;
}

.custom-file-upload:hover {
  background-color: #f7f6f3;
}

.file-name {
  margin-left: 10px;
  font-size: 14px;
  color: #37352f;
}

.modal-input:focus,
.form-group input:focus,
.form-group textarea:focus,
.search-input:focus {
  outline: none;
  border-color: var(--accent-color) !important;
  box-shadow: 0 0 0 2px rgba(46, 170, 220, 0.2);
}

::selection {
  background-color: rgba(45, 170, 219, 0.3);
}

.modal .tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
}

.modal .tags-container .tag {
  display: inline-flex;
  align-items: center;
  background-color: rgb(255, 190, 186);
  border-radius: 10px;
  box-sizing: border-box;
  color: rgb(55, 53, 47);
  font-size: 11.2px;
  line-height: 1;
  padding: 2px 6px;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
}

.modal .tags-container .tag .remove-tag-btn {
  background: none;
  border: none;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  cursor: pointer;
  padding: 0;
  margin-left: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
}

.modal .tags-container .tag .remove-tag-btn:hover,
.modal .tags-container .tag .remove-tag-btn:focus {
  color: rgba(0, 0, 0, 0.8);
  background-color: transparent;
  outline: none;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  border-radius: 8px 8px 0 0;
  overflow: hidden;
}

th {
  background-color: #2a2a2a;
  font-size: 0.9em;
  font-weight: bold;
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #444;
}

th:first-child {
  border-top-left-radius: 8px;
}

th:last-child {
  border-top-right-radius: 8px;
}

tr:last-child td {
  border-bottom: none;
}

.thumbnail-options-container {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
}

.thumbnail-options {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  gap: 10px;
}

.thumbnail-option {
  width: 80%;
  height: auto;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s;
}

.thumbnail-option.selected {
  border-color: #fae150;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.courses-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.filter-search-container {
  display: flex;
  align-items: center;
  background-color: var(--dark-surface);
  border-radius: 20px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.custom-select-wrapper {
  position: relative;
  display: inline-block;
  width: 200px;
  margin-right: 10px;
}

.custom-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--dark-surface);
  border: 1px solid var(--dark-border);
  border-radius: 24px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  color: var(--dark-text);
}

.custom-select:hover {
  background-color: var(--dark-surface-hover);
}

.custom-select:focus {
  outline: none;
}

.custom-select-options {
  color: #e0e0e0;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #2a2a2a;
  border: 1px solid #444;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  list-style: none;
  padding: 0;
  margin: 0;
}

.custom-select-options.open {
  display: block;
}

.custom-select-option {
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.custom-select-option:hover {
  background-color: #333;
}

.search-container {
  position: relative;
  width: 421px;
}

.search-input {
  width: 100%;
  height: 40px;
  padding: 0px 8px;
  border: 0 !important;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: var(--dark-bg) !important;
  color: #ffffff !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  outline: none !important;
  padding-right: 32px;
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
}

.search-input::placeholder {
  color: #888;
}

.search-input:focus {
  background-color: #ffffff;
  box-shadow: 0 0 0 2px rgba(55, 53, 47, 0.16);
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  opacity: 0.5;
  cursor: pointer;
}

.search-icon:hover {
  opacity: 0.7;
}

.open-modal-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 0;
}

.drag-handle {
  margin-left: 10px;
  cursor: grab;
  display: flex;
}

.course-card .tag {
  padding: 4px 8px;
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: var(--dark-border);
  border-radius: 5px;
  margin-top: 10px;
}

.progress {
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(250, 225, 80, 0.5) 0%,
    rgba(245, 208, 32, 0.6) 100%
  ) !important;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
}

.progress-text {
  font-size: 12px;
  color: #666;
  margin-top: 5px;
  display: block;
}

:root {
  --dark-bg: #1a1a1a;
  --dark-surface: #242424;
  --dark-surface-hover: #2a2a2a;
  --dark-border: #333333;
  --dark-text: #e0e0e0;
  --dark-text-secondary: #a0a0a0;
  --accent-color: #fae150;
  --accent-hover: #f5d020;
  --yellow-primary: #ffd43b;
  --yellow-hover: #fab005;
  --yellow-active: #f59f00;
  --yellow-shadow: rgba(255, 212, 59, 0.25);
}

.modal-content {
  background: var(--dark-surface);
  border: 1px solid var(--dark-border);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
}

.modal-input,
.form-group input,
.form-group textarea,
.search-input {
  background-color: var(--dark-bg) !important;
  border: 1px solid var(--dark-border) !important;
  color: var(--dark-text);
}

.modal-input:focus,
.form-group input:focus,
.form-group textarea:focus,
.search-input:focus {
  border-color: var(--accent-color) !important;
  box-shadow: 0 0 0 2px rgba(46, 170, 220, 0.2);
}

.modal-content button.primary {
  background-color: var(--accent-color);
  border: none;
}

.modal-content button.primary:hover {
  background-color: var(--accent-hover);
}

.course-card {
  background-color: var(--dark-surface);
  border: 1px solid var(--dark-border);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.course-card:hover {
  background-color: var(--dark-surface-hover);
  transform: translateY(-2px);
  transition: all 0.3s ease;
}

.filter-search-container {
  background-color: var(--dark-surface);
  border: 1px solid var(--dark-border);
}

.custom-select {
  background-color: var(--dark-surface);
  border: 1px solid var(--dark-border);
}

.custom-select:hover {
  background-color: var(--dark-surface-hover);
}

.progress-bar {
  background-color: var(--dark-border);
}

.progress {
  background: linear-gradient(
    90deg,
    var(--accent-color) 0%,
    var(--accent-hover) 100%
  );
}

.open-modal-btn span {
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.2);
}

.new-course-btn {
  display: flex !important;
  align-items: center !important;
  padding: 8px 16px !important;
  border: none !important;
  border-radius: 8px !important;
  background: linear-gradient(
    135deg,
    rgba(250, 225, 80, 0.15) 0%,
    rgba(245, 208, 32, 0.15) 100%
  ) !important;
  border: 1px solid rgba(250, 225, 80, 0.3) !important;
  color: #fae150 !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  transition: all 0.2s ease-in-out !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.new-course-btn:hover {
  transform: translateY(-1px) !important;
  background: linear-gradient(
    135deg,
    rgba(250, 225, 80, 0.2) 0%,
    rgba(245, 208, 32, 0.2) 100%
  ) !important;
  border-color: rgba(250, 225, 80, 0.4) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
}

.new-course-btn:active {
  transform: translateY(0) !important;
  background: linear-gradient(
    135deg,
    rgba(250, 225, 80, 0.25) 0%,
    rgba(245, 208, 32, 0.25) 100%
  ) !important;
}

.new-course-btn .btn-content {
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
}

.new-course-btn .add-icon {
  width: 16px !important;
  height: 16px !important;
  filter: invert(83%) sepia(29%) saturate(754%) hue-rotate(332deg)
    brightness(101%) contrast(92%) !important;
}

.new-course-btn span {
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
}

[data-theme="dark"] .new-course-btn {
  background: linear-gradient(135deg, #fae150 0%, #f5d020 100%);
  box-shadow: 0 2px 4px rgba(250, 225, 80, 0.25);
  color: #1a1a1a;
}

[data-theme="dark"] .new-course-btn:hover {
  background: linear-gradient(135deg, #fbe666 0%, #fae150 100%);
  box-shadow: 0 4px 8px rgba(250, 225, 80, 0.3);
}

.course-name-text {
  color: #231c16;
}

.course-name strong {
  color: #231c16;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.delete-confirmation-content {
  background-color: #1a1a1a;
  border: 1px solid #333;
  border-radius: 12px;
  padding: 24px;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.3);
  animation: modalFadeIn 0.2s ease-out;
}

.delete-confirmation-content .course-name {
  color: #e0e0e0;
  text-align: center;
  margin-bottom: 16px;
}

.delete-confirmation-content .warning-text {
  color: #e0e0e0;
  text-align: center;
  margin-bottom: 24px;
}

.delete-confirmation-content .course-name-text {
  color: #fae150;
  font-weight: 600;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.course__action__button--unique {
  background: transparent !important;
  border: none !important;
  cursor: pointer !important;
  padding: 4px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 24px !important;
  height: 24px !important;
  border-radius: 50% !important;
  transition: all 0.2s ease-in-out !important;
}

.course__action__button--unique img {
  width: 14px !important;
  height: 14px !important;
  filter: brightness(0) invert(1) !important;
  transition: all 0.2s ease-in-out !important;
}

.course__action__button--unique:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.course__action__button--unique:hover img {
  filter: invert(83%) sepia(29%) saturate(754%) hue-rotate(332deg)
    brightness(101%) contrast(92%) !important;
}

.btn-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
}

.deleting-status {
  text-align: center;
  padding: 20px;
}

.deleting-status p {
  margin-top: 10px;
  color: #666;
  font-size: 14px;
}

/* 태그 입력 컨테이너 스타일 추가 */
.tag-input-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
  height: 32px;
}

.tag-input-wrapper {
  flex-grow: 1;
  height: 100%;
}

.tag-input-wrapper input {
  width: 100%;
  height: 100%;
  padding: 0 12px;
  background-color: var(--dark-bg);
  border: 1px solid var(--dark-border);
  border-radius: 5px;
  color: var(--dark-text);
  font-size: 14px;
}

.tag-add-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100% !important;
  width: 32px !important;
  background: linear-gradient(135deg, #fae150 0%, #f5d020 100%) !important;
  border: none !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  transition: all 0.2s ease !important;
  padding: 0 !important;
  margin: 0 !important;
}

.tag-add-button img {
  width: 12px;
  height: 12px;
  filter: brightness(0) saturate(100%);
}

/* 전역 폰트 설정을 더 구체적인 선택자로 변경 */
.courses-container * {
  font-family: "DM Sans", sans-serif;
}

/* 에디터 컨테이너에 대한 예외 처리 */
.courses-container *:not(.custom-editor-container *) {
  font-family: "DM Sans", sans-serif;
}
