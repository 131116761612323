.my-account-container {
  min-height: 100vh;
  background-color: var(--dark-bg);
  color: var(--dark-text);
}

/* Settings 컴포넌트의 스타일을 MyAccount 페이지에서도 동일하게 적용 */
.my-account-container .settings-container {
  background-color: var(--dark-bg);
}

.my-account-container .settings-section {
  background-color: var(--dark-surface);
}

.my-account-container .section-title {
  color: var(--dark-text);
}

/* 나머지 필요한 다크 테마 스타일들 추가 */

.settings-header {
  padding: 20px;
  margin-bottom: 20px;
  background-color: var(--dark-surface); /* 어두운 배경 */
}

.back-to-home-button {
  padding: 8px 16px;
  background: transparent;
  border: none;
  color: #ffffff; /* 흰색 텍스트 */
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
  border-radius: 4px;
}

.back-to-home-button:hover {
  background-color: rgba(255, 255, 255, 0.1); /* 호버 시 살짝 밝은 배경 */
}
