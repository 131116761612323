.cal-todo-sidebar-container {
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;
  height: 100vh;
  background-color: #1a1a1a;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  z-index: 1000;
  color: #e0e0e0;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  transform: translateX(100%);
}

.cal-todo-sidebar-container.open {
  transform: translateX(0);
}

.cal-todo-header-wrapper {
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cal-todo-header-wrapper h3 {
  margin: 0;
  font-size: 18px;
  color: #e0e0e0;
}

.cal-todo-content-scroll {
  padding: 20px;
  height: calc(100vh - 70px);
  overflow-y: auto;
}

.cal-todo-card-wrapper {
  background-color: #2a2a2a;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.cal-todo-items-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.cal-todo-item-single {
  margin-bottom: 8px;
}

.cal-todo-item-content {
  display: flex;
  align-items: center;
  gap: 12px;
}

/* 체크박스 스타일 */
.cal-todo-item-content input[type="checkbox"] {
  width: 16px !important;
  height: 16px !important;
  min-width: 16px !important;
  min-height: 16px !important;
  border: 2px solid #404040 !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  margin: 0 !important;
  padding: 0 !important;
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-color: transparent !important;
  position: relative !important;
}

.cal-todo-item-content input[type="checkbox"]:checked {
  background-color: #fae150 !important;
  border-color: #fae150 !important;
}

.cal-todo-item-content input[type="checkbox"]:checked::after {
  content: "" !important;
  position: absolute !important;
  left: 4px !important;
  top: 1px !important;
  width: 4px !important;
  height: 8px !important;
  border: solid #000 !important;
  border-width: 0 2px 2px 0 !important;
  transform: rotate(45deg) !important;
}

.cal-todo-add-form {
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.cal-todo-input-field {
  flex: 1;
  background-color: #333;
  border: 1px solid #404040;
  color: #e0e0e0;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 13px;
  height: 24px;
}

.cal-todo-input-field:focus {
  border-color: #fae150 !important;
  outline: none !important;
  box-shadow: none !important;
}

.cal-todo-add-btn {
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  background-color: transparent !important;
  color: #fae150 !important;
  border: 1px solid #fae150 !important;
  border-radius: 4px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0;
  transition: all 0.2s ease;
}

.cal-todo-add-btn:hover {
  background-color: #fae150 !important;
  color: #1a1a1a !important;
}

.cal-todo-completed-text {
  text-decoration: line-through;
  color: #808080;
}

/* 스크롤바 스타일링 */
.cal-todo-content-scroll::-webkit-scrollbar {
  width: 8px;
}

.cal-todo-content-scroll::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.cal-todo-content-scroll::-webkit-scrollbar-thumb {
  background-color: #404040;
  border-radius: 4px;
}

.cal-todo-content-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #505050;
}

/* 기존 CSS에 추가 */
.cal-todo-add-list-form {
  padding: 10px;
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #eee;
  width: 400px;
}

.cal-todo-add-list-form .cal-todo-input-field {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.cal-todo-add-list-form .cal-todo-add-btn {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cal-todo-add-list-form .cal-todo-add-btn:hover {
  background-color: #0056b3;
}

/* 반응형 스타일 추가 */
@media screen and (max-width: 1200px) {
  .cal-todo-sidebar-container {
    width: 350px;
  }
}

@media screen and (max-width: 768px) {
  .cal-todo-sidebar-container {
    width: 300px;
  }
}
