.review-mode-page {
  padding: 2rem;
  /*max-width: 1200px;*/
  margin: 0 auto;
  background-color: #fff !important;
  min-height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
}

h1 {
  text-align: center;
  color: #333;
  margin-bottom: 3rem;
}

.feature-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 3rem;
  padding: 4rem;
  border-radius: 10px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feature-image {
  width: 400px;
  height: 300px;
  object-fit: contain;
  border-radius: 8px;
  background-color: #f8f9fa;
}

.feature-description {
  flex: 1;
}

.feature-description h2 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.feature-description p {
  color: #666;
  line-height: 1.6;
}

.logo-link {
  position: absolute;
  top: 2rem;
  left: 2rem;
}

.logo {
  width: 120px;
  height: auto;
}
