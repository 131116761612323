.right-bar {
  width: 80px;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  background-color: #1a1a1a;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0;
  z-index: 5000;
  border-left: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.menu-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.menu-item:hover {
  background-color: rgba(128, 128, 128, 0.1) !important;
}

.menu-item.menu1:hover,
.menu-item.menu2:hover,
.menu-item.menu3:hover,
.menu-item.menu5:hover,
.menu-item.menu8:hover,
.menu-item.menu6:hover {
  background-color: #404040 !important;
  color: #e0e0e0 !important;
  transform: none !important;
}

.menu-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.menu-icon {
  width: 24px;
  height: 24px;
  filter: brightness(0) invert(0.8);
  transition: all 0.2s ease;
}

.menu-label {
  font-size: 12px;
  color: #b0b0b0;
  text-align: center;
  transition: color 0.2s ease;
}

.menu-item.active {
  background-color: rgba(250, 225, 80, 0.08);
  box-shadow: inset 0 0 0 1px rgba(250, 225, 80, 0.15);
}

.menu-item.active .menu-icon {
  filter: invert(83%) sepia(32%) saturate(638%) hue-rotate(332deg)
    brightness(90%) contrast(90%);
}

.flyout-panel {
  position: absolute;
  left: -220px;
  width: 200px;
  background-color: #1a1a1a;
  color: #e0e0e0;
  padding: 15px;
  top: 0;
  bottom: 0;
  border-left: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: -4px 0 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
  animation: slideIn 0.3s ease;
}

@keyframes slideIn {
  from {
    transform: translateX(20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* 스크롤바 스타일 */
.flyout-panel::-webkit-scrollbar {
  width: 4px;
}

.flyout-panel::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.flyout-panel::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 2px;
}

.flyout-panel::-webkit-scrollbar-thumb:hover {
  background: #444;
}
