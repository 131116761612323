.main-content {
  width: 210mm; /* A4 너비 */
  min-height: 297mm; /* 고정 높이 대신 최소 높이 사용 */
  max-height: 297mm; /* 고정 높이 대신 최소 높이 사용 */
  height: auto; /* 내용에 따라 자동으로 높이 조정 */
  padding: 20px;
  background-color: #000000; /* 검정색으로 변경 */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px; /* 중앙 정렬을 위한 마진 */
  margin-top: 100px; /* Topbar 높이만큼 마진 추가 */
  background-size: cover; /* 이미지 크기를 조정 */
  background-position: center; /* 이미지 위치를 중앙으로 설정 */
  background-repeat: no-repeat; /* 이미지 반복을 방지 */
  position: relative; /* 삽입된 이미지를 위한 상대 위치 설정 */
  overflow: visible; /* 내용이 넘칠 경우 보이도록 설정 */
  display: flex;
  flex-direction: column;
  background-transition: background-image 0.3s ease-in-out;
  will-change: background-image;
  backface-visibility: hidden;
  transform: translateZ(0);
}

.main-content .another-table-wrapper {
  transition: all 0.3s ease-in-out !important;
  z-index: 1;
  position: relative;
  overflow: visible !important;
}
body {
  background-color: #000000;
}

.layout {
  background-color: #000000;
}
.note-content {
  position: relative; /* 삽입된 이미지를 위한 상대 위치 설정 */
  overflow: visible; /* 내용이 넘칠 경우 보이도록 설정 */
  flex: 1;
  position: relative;
  white-space: pre-wrap; /* Added this line */
  z-index: 1;
}

.inserted-images {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  pointer-events: none;
  min-height: 100%;
}

.inserted-image {
  pointer-events: auto;
  transform-origin: center center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.unit-name {
  margin-top: 85px; /* 상단 마진 추가 */
  margin-left: 30px;
  font-size: 16px; /* 폰트 크기 조정 */
  color: #333; /* 텍스트 색상 */
  white-space: nowrap; /* 텍스트 줄바꿈 방지 */
  overflow: hidden; /* 오버플로우 숨기기 */
  text-overflow: ellipsis; /* 텍스트가 넘칠 경우 생략 부호 추가 */
  padding: 10px;
  text-decoration: none !important;
  font-weight: normal !important;
  cursor: default !important;
  line-height: 1.2 !important;
  font-family: var(--editor-font-family), "TTAgainSea", sans-serif !important;
}

.image-container {
  position: relative; /* 이미지 컨테이너를 상대 위치 설정 */
  display: inline-block;
}

.button-container {
  position: absolute; /* 버튼 컨테이너를 절대 위치로 설정 */
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: 100%;
}

.fixed-button {
  position: absolute;
  transform: none;
  width: 30px; /* 튼 크기 고정 */
  height: 30px; /* 버튼 크기 고정 */
  margin-top: 5px; /* 이미와 버튼 사이의 간격 */
}

/* 선택된 이미지 스타일 */

/* 드래그 영역 스타일 */
.selecto-area {
  position: absolute;
  z-index: 100;
  border: 1px dashed #4caf50; /* 드래그 영역에 녹색 점선 테두리 추가 */
  background: rgba(76, 175, 80, 0.1); /* 드래그 영역에 반투명 녹색 배경 추가 */
}

.crop-button,
.delete-button {
  position: absolute;
  top: -35px; /* 이미지 위에 위치하도록 조정 */
  width: 30px;
  height: 30px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000; /* 다른 요소들 위에 표시되도록 함 */
}

.crop-button {
  left: 0;
}

.delete-button {
  left: 35px; /* crop 버튼 옆에 위치하도록 조정 */
}

.crop-button img,
.delete-button img {
  width: 100%;
  height: 100%;
}

.inserted-audios {
  position: relative;
  z-index: 10; /* AnotherComponent 위에 표시되도록 z-index 설정 */
  margin-bottom: 20px; /* AnotherComponent와의 간격 설정 */
  position: relative;
  min-height: 100%;
}

.audio-container {
  position: absolute;
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: move;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.audio-button {
  width: 30px;
  height: 30px;
  background-color: #4caf50;
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.audio-button:hover {
  background-color: #45a049;
}

.audio-name {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.audio-container audio {
  width: 200px;
}

.audio-trimmer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.audio-trimmer input[type="range"] {
  width: 100%;
  margin: 10px 0;
}

.audio-trimmer button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.audio-trimmer button:hover {
  background-color: #45a049;
}

/* 스크롤바 숨기기 (선택사항) */
.note-content::-webkit-scrollbar {
  display: none;
}

.note-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.video-container {
  position: absolute;
  width: 560px;
  height: 315px;
}

.video-container iframe {
  width: 100%;
  height: 100%;
}

.drawing-mode .note-content {
  pointer-events: all;
}

.pen-mode {
  cursor: url("../assets/icons/cursor-pen.svg") 1 1, auto !important;
}

body.pen-mode,
body.pen-mode .note-content,
body.pen-mode .note-content * {
  cursor: url("../assets/icons/cursor-pencil.svg") 1 1, auto !important;
}

.custom-menu-item .contexify_itemContent {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 10px !important;
  line-height: 1 !important;
  height: 18px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.context-menu-item {
  padding: 8px 16px;
  color: var(--dark-text-secondary);
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.context-menu-item:hover {
  background-color: var(--dark-surface) !important;
  color: var(--accent-yellow) !important;
}
