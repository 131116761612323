/* 공통 설정 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--dark-bg);
  padding-top: 60px; /* Topbar 고정 시 추가적인 패딩 */
}

/* Topbar 스타일 */
.topbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: #1e1e1e;
  color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  z-index: 999; /* 다른 요소들 위에 고정 */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.topbar .search {
  position: relative;
}

.topbar .search input {
  padding: 5px 10px;
  border: 1px solid #333;
  border-radius: 5px;
  font-family: "Inter", sans-serif;
  background-color: #2a2a2a;
  color: #e0e0e0;
}

.topbar .profile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.topbar .profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.topbar .profile .username {
  font-size: 1em;
}

.topbar-content {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.topbar-logo {
  height: 30px;
  margin-right: 20px;
}

.topbar-search-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 0;
  border: 0 !important;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #2a2a2a !important;
}

.topbar-search-input {
  border: 0 !important;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #2a2a2a !important;
  color: #ffffff !important;
  outline: none;
  padding: 8px 12px;
}

.topbar-search-input::placeholder {
  color: #888888;
}

.topbar-search-input:focus {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
  transition: box-shadow 0.2s ease-in-out;
}

.topbar-search-button {
  background: none;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.topbar-search-button:hover {
  color: #fae150;
  background: transparenet !important; /* 아이콘 색상만 약간 어둡게 변경 */
}

.topbar-search-icon {
  color: #888;
}

.topbar-search-button:hover .topbar-search-icon {
  color: inherit; /* 버튼의 호버 색상을 상속받음 */
}

.search-results {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #333;
  border-radius: 4px;
  margin-top: 10px;
  padding: 10px;
  background-color: #1e1e1e;
}

.search-results ul {
  list-style-type: none;
  padding: 0;
}

.search-results li {
  cursor: pointer;
  padding: 5px;
  border-bottom: 1px solid #333;
  color: #e0e0e0;
}

.search-results li:hover {
  background-color: #2a2a2a;
}

.topbar-save-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  margin-left: 16px;
  border: none;
  border-radius: 4px;
  background-color: #333;
  color: #e0e0e0;
  cursor: pointer;
  transition: all 0.2s;
}

.topbar-save-button.unsaved {
  background-color: #2e7d32;
}

.topbar-save-button:disabled {
  background-color: #333;
  opacity: 0.4;
}

.topbar-save-icon {
  font-size: 16px;
}

/* 스크롤바 스타일 */
.search-results::-webkit-scrollbar {
  width: 8px;
}

.search-results::-webkit-scrollbar-track {
  background: #1e1e1e;
}

.search-results::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 4px;
}

.search-results::-webkit-scrollbar-thumb:hover {
  background: #444;
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 0;
  z-index: 1;
}

.logo-text {
  font-size: 1.2rem;
  font-weight: bold;
  color: #ffffff;
}

.logo-link {
  text-decoration: none;
  color: inherit;
}

.logo-link:hover {
  color: inherit;
}
