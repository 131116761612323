.subscription-container {
  width: 100%;
  margin: 0 auto;
  padding: 60px 20px;
  text-align: center;
  background-color: #feefd8;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.subscription-image {
  flex: 1;
  max-width: 500px;
  display: none; /* 모바일에서는 숨김 */
}

.subscription-image img {
  width: 100%;
  height: auto;
}

.subscription-content {
  flex: 1;
  max-width: 500px;
}

h1 {
  margin-bottom: 40px;
  color: #333;
  font-size: 2.5rem;
}

.billing-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin: 32px 0;
}

.billing-toggle span {
  color: #666;
  font-size: 16px;
}

.billing-toggle span.active {
  color: #333;
  font-weight: 600;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #fae150;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.plan-card {
  max-width: 400px;
  margin: 40px auto;
  background: white;
  border-radius: 12px;
  padding: 32px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  border: 2px solid #fae150;
}

.plan-header {
  position: relative;
  margin-bottom: 24px;
}

.plan-header h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 16px;
}

.savings-badge {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #fae150;
  padding: 6px 16px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
}

.price {
  font-size: 48px;
  font-weight: bold;
  color: #333;
  margin: 16px 0;
}

.price span {
  font-size: 16px;
  color: #666;
}

.billing-info {
  color: #666;
  font-size: 14px;
}

.features-list {
  list-style: none;
  padding: 0;
  margin: 30px 0;
  text-align: left;
}

.features-list li {
  margin: 16px 0;
  color: #333;
  font-size: 16px;
}

.subscribe-button {
  width: 100%;
  padding: 16px;
  background-color: #fae150;
  color: #231c16;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.subscribe-button:hover {
  background-color: #f5d63d;
}

.header-section {
  position: absolute;
  top: 20px;
  left: 20px;
}

.back-button {
  background: none;
  border: none;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.back-button:hover {
  color: #666;
}

@media (min-width: 1024px) {
  .subscription-container {
    padding: 60px;
  }

  .subscription-image {
    display: block; /* 데스크톱에서만 이미지 표시 */
  }

  .header-section {
    left: 60px;
    top: 40px;
  }
}

.subscription-alert {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404;
  padding: 12px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 90%;
  text-align: center;
}
