.sidebar {
  /* 기본 사이드바 스타일 */
  background-color: #1e1e1e;
  color: #e0e0e0;
  padding: 1.5rem 1rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100vh;
  min-height: 450px; /* 최소 높이를 450px로 증가 */
  width: 280px;
  transition: width 0.4s;
  position: relative;
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, sans-serif; /* 여기를 수정 */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
}

.sidebar.minimized {
  /* minimized 상태일 때의 스타일 */
  width: 60px;
  padding: 1rem 0;
  align-items: center;
}

.sidebar.minimized ul {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar.minimized li {
  margin-bottom: 1rem;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.sidebar.minimized li a {
  justify-content: center;
  width: 40px; /* 아이콘 컨테이너의 너비 */
  height: 40px; /* 아이콘 컨테이너의 높이 */

  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar.minimized li.active a {
  background-color: #333;
}

.sidebar.minimized .sidebar-icon {
  margin: 0;
  width: 20px; /* 아이콘 크기 조정 */
  height: 20px;
}

.sidebar.minimized li.active .sidebar-icon {
  opacity: 1;
  transform: scale(1.1); /* 활성화된 아이콘 약간 확대 */
}

.toggle-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  opacity: 0.6;
  transition: opacity 0.2s ease;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar.minimized .toggle-button {
  position: static;
  margin-bottom: 1rem;
  right: auto;
  transform: none;
}

.toggle-icon {
  filter: invert(1) !important; /* 아이콘을 하얀색으로 변경 */
  width: 20px;
  height: 20px;
  opacity: 0.8; /* 약간의 투명도 추가 */
}

.toggle-icon:hover {
  opacity: 1;
}

.icon-container {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-icon {
  /* 아이콘 스타일 */
  float: right;
  cursor: pointer;
  margin-left: 1rem;
  width: 22px;
  height: 22px;
  margin-right: 12px;
  opacity: 0.75;
  transition: opacity 0.2s ease;
  filter: invert(90%);
}

.sidebar-icon:hover {
  opacity: 1;
}

.sidebar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 50px; /* 상단 마진을 줄여서 간격을 좁게 만듦 */
  flex-grow: 1;
  overflow-y: auto;
  min-height: 250px; /* 메뉴의 최소 높이를 250px로  */
}

.sidebar li {
  margin-bottom: 0.8rem;
  padding: 10px 12px;
  border-radius: 8px;
  transition: all 0.2s ease;
  border-left: none;
  position: relative;
}

.sidebar li:hover {
  background-color: #2a2a2a;
  transform: translateX(4px);
}

.sidebar li.active {
  background-color: #333;
  border-left: none;
  position: relative;
}

.sidebar li.active::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 60%;
  background-color: #fae150;
  border-radius: 0 4px 4px 0;
}

.sidebar li.active a {
  font-weight: bold;
}

.sidebar li a {
  color: #e0e0e0;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.sidebar a:hover {
  color: #ffffff;
}

.sidebar.minimized span {
  display: none;
}

.user-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0.5rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.logo-container {
  width: 100px;
  height: 100px;
  margin-bottom: 1rem;
  overflow: hidden;
  transition: all 0.2s ease;
}

.logo-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.user-name {
  color: #e0e0e0;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0.5rem;
  display: block;
  text-align: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 1;
  z-index: 1;
  padding: 0.25rem 0;
  text-shadow: none;
}

.user-name:hover {
  color: #fae150;
}

/* 사이드바가 최소화되었을 때 */
.minimized .user-profile {
  padding: 1rem 0.5rem;
}

.minimized .logo-container {
  width: 50px;
  height: 50px;
  margin-bottom: 0;
}

.minimized .user-name {
  display: none;
}

.no-wrap {
  white-space: nowrap; /* 줄바꿈 방지 */
}

.sidebar.minimized li.active {
  background-color: #333;
  border-left: none;
}

.sidebar.minimized li.active .sidebar-icon {
  opacity: 1;
  transform: scale(1.2);
}

.avatar-container,
.avatar-icon,
.avatar-container:hover {
  display: none;
}
