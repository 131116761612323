.habit-tracker {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.habit-tracker-list {
  display: flex;
  flex-direction: column;
  width: 20%; /* 습관 목록의 너비 조정 */
}

.habit-tracker-item {
  margin: 10px 0;
  cursor: pointer; /* 클릭 가능하도록 커서 변경 */
}

.habit-tracker-grid {
  width: 80%; /* 그리드의 너비 조정 */
  margin-left: 40px; /* 습관 목록과의 간격 조정 */
}

.habit-tracker-grid-container {
  display: grid;
  grid-template-columns: repeat(19, 35px); /* 각 셀의 너비 조정 */
  grid-template-rows: repeat(20, 35px); /* 각 셀의 높이 조정 */
  gap: 0; /* 셀 간격 제거 */
}

.habit-tracker-cell {
  border: 1px solid rgba(255, 255, 255, 0.3); /* 각 셀의 테두리 유지 */
  background-color: rgba(50, 50, 50, 0.8); /* 다크 모드에 맞게 배경색 조정 */
  height: 30px; /* 셀 높이 조정 */
  width: 30px; /* 셀 너비 조정 */
  padding: 0; /* 패딩 제거 */
  display: flex; /* 중앙 정렬을 위해 flex 사용 */
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: center; /* 세로 중앙 정렬 */
  color: rgba(255, 255, 255, 0.7); /* 폰트 색상 완화 */
}

.habit-tracker-item {
  background-color: rgba(70, 70, 70, 0.8); /* 배경색 조정 */
  color: rgba(255, 255, 255, 0.9); /* 텍스트 색상 조정 */
  padding: 10px; /* 여백 추가 */
  border-radius: 5px; /* 모서리 둥글게 */
  margin: 10px 0; /* 위아래 여백 추가 */
  cursor: pointer; /* 클릭 가능하도록 커서 변경 */
  transition: background-color 0.3s; /* 배경색 변화 애니메이션 */
}

.habit-tracker-item:hover {
  background-color: rgba(100, 100, 100, 0.9); /* 호버 시 배경색 변화 */
}

.habit-tracker-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.habit-tracker-item.selected {
  background-color: rgba(255, 223, 126, 0.3); /* 선택된 습관의 배경색 */
  border-radius: 4px; /* 모서리 둥글게 */
}

.add-habit {
  width: 100%; /* 추가 UI의 너비를 100%로 설정하여 목록과 맞춤 */
  display: flex; /* 입력 필드와 버튼을 가로로 배치 */
  align-items: center; /* 세로 중앙 정렬 */
}

.add-habit input {
  width: calc(100% - 60px); /* 버튼 크기를 고려하여 입력 필드 너비 조정 */
}

.add-habit button {
  padding: 10px 15px; /* 버튼 패딩을 늘려서 크기 증가 */
  font-size: 16px; /* 글자 크기 조정 */
  /* 기존 스타일 유지 */
  margin-left: 10px;
  background-color: rgba(100, 100, 100, 0.8);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-habit button:hover {
  background-color: rgba(150, 150, 150, 0.9); /* 호버 시 배경색 변화 */
}

.grid-size-controls {
  width: 200px;
  padding: 20px;
  background-color: rgba(70, 70, 70, 0.8);
  border-radius: 8px;
  margin-left: 20px;
}

.grid-size-controls h3 {
  margin-bottom: 15px;
  color: rgba(255, 255, 255, 0.9);
}

.grid-size-input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.grid-size-input input[type="range"] {
  width: 100%;
}

.grid-size-input span {
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
}

.quick-select-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.quick-select-buttons button {
  padding: 8px;
  background-color: rgba(100, 100, 100, 0.8);
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.quick-select-buttons button:hover {
  background-color: rgba(150, 150, 150, 0.9);
}

.habit-tracker-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.period-settings-button {
  background-color: rgba(70, 70, 70, 0.8);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  gap: 6px;
}

.period-settings-button:hover {
  background-color: rgba(90, 90, 90, 0.9);
}

.period-settings-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(40, 40, 40, 0.95);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 300px;
}

.period-settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  padding: 4px;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.close-button:hover {
  opacity: 1;
}

.period-settings-content {
  width: 100%;
}

/* 기존 grid-size-input과 quick-select-buttons 스타일 유지 */

.completion-rate {
  background-color: rgba(70, 70, 70, 0.8);
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 0.9em;
}
