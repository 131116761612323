.signup-container {
  display: flex;
  min-height: 100vh;
  width: 100%;
  position: relative;
  background-color: #feefd8;
}

.signup-image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2rem;
}

.signup-image img {
  max-width: 100%;
  max-height: 100vh;
  object-fit: contain;
}

.signup-form-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.signup-form {
  width: 100%;
  max-width: 400px;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  font-family: "Montserrat";
}

.signup-form h2 {
  color: #414042;
  font-size: 32px;
  font-family: "Dela Gothic One";
  line-height: 42px;
  margin-bottom: 2rem;
  text-align: center;
}

.input-group {
  margin-bottom: 20px;
}

.input-group input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #e0e0e0;

  font-size: 14px;

  color: #424242;
  font-size: 14px;
  font-family: "Montserrat";
}

.input-group input:focus {
  outline: none !important;
  border-color: #faaf3d !important;
  box-shadow: 0 0 0 2px rgba(250, 175, 61, 0.2) !important;
}

.password-input-container {
  position: relative;
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #666;
}

.password-strength {
  margin-top: 5px;
  height: 5px;
  background-color: #e0e0e0;
  border-radius: 2px;
  overflow: hidden;
}

.password-strength-bar {
  height: 100%;
  transition: width 0.3s ease, background-color 0.3s ease;
}

.password-strength-text {
  font-size: 12px;
  margin-top: 5px;
}

.password-requirements {
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}

.error-message,
.success-message {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
}

.error-message {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  color: #ff4d4f;
}

.success-message {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
  color: #52c41a;
}

button[type="submit"] {
  width: 100%;
  padding: 12px;
  background-color: #0066cc;
  color: #ffffff;
  border: none;
  /* border-radius: 4px;*/
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

button[type="submit"]:hover {
  background-color: #005bb5;
}

button[type="submit"]:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.social-login {
  margin-top: 20px;
  text-align: center;
}

.social-login p {
  margin-bottom: 10px;
  font-size: 14px;
  color: #666;
}

.google-login,
.facebook-login {
  width: 100%;
}

.login-link {
  margin-top: 15px;
  text-align: center;
}

.login-link a {
  color: #414042;
  text-decoration: underline;
}

.login-link a:hover {
  text-decoration: underline;
}

.terms-agreement {
  margin-top: 15px;
  font-size: 12px;
  color: #666;
  text-align: center;
}

.terms-agreement a {
  color: #faaf3d;
  text-decoration: none;
  font-weight: bold;
}

.terms-agreement a:hover {
  text-decoration: underline;
}

.signup-form input:focus {
  outline: none !important;
  border-color: #faaf3d !important;
  box-shadow: 0 0 0 2px rgba(250, 175, 61, 0.2) !important;
}

.password-input-container input:focus {
  outline: none !important;
  border-color: #faaf3d !important;
  box-shadow: 0 0 0 2px rgba(250, 175, 61, 0.2) !important;
}
