.planner-page {
  padding: 2rem;
  /* max-width: 1200px;*/
  margin: 0 auto;
  background-color: #fff !important;
  min-height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
}
/*
h1 {
  text-align: center;
  color: #333;
  margin-bottom: 3rem;
}
*/
.feature-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.feature-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 0;
  padding: 0 20px;
  max-width: 1000px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.feature-image {
  width: 100%;
  max-width: 800px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  object-fit: contain;
}

.feature-description {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 30px 20px;
}

.feature-description h2 {
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #333;
}

.feature-description p {
  font-size: 1.2em;
  line-height: 1.6;
  color: #666;
  margin: 0;
  padding: 0 20px;
}

.logo-link {
  position: absolute;
  top: 2rem;
  left: 2rem;
}

.logo {
  width: 120px;
  height: auto;
}
