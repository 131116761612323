.privacy-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.privacy-content {
  flex: 1;
  max-width: 1200px;
  margin: 0 auto;
  padding: 120px 40px 60px;
}

.privacy-content h1 {
  font-size: 2.5rem;
  color: #111827;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .privacy-content {
    padding: 100px 20px 40px;
  }

  .privacy-content h1 {
    font-size: 2rem;
  }
}

.legal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #eee;
}

.home-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 25px;
  font-weight: 500;
  transition: all 0.3s ease;
  background-color: #1a1a1a;
  color: #fff;
}

.home-button:hover {
  transform: translateY(-2px);
  background-color: #ffb800;
  color: #1a1a1a;
}

.home-icon {
  margin-right: 8px;
  font-size: 18px;
}

.legal-title {
  font-size: 2.5em;
  color: #1a1a1a;
  text-align: center;
  margin: 0;
  flex-grow: 1;
}

.privacy-section {
  margin-bottom: 50px;
  padding: 30px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.privacy-title {
  font-size: 2.5em;
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 40px;
}

.privacy-section h2 {
  font-size: 1.8em;
  color: #1a1a1a;
  margin-bottom: 20px;
}

.privacy-section p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
}

.legal-list {
  list-style: none;
  padding-left: 20px;
}

.legal-list li {
  color: #666;
  margin-bottom: 10px;
  position: relative;
}

.legal-list li:before {
  content: "•";
  color: #ffd700;
  position: absolute;
  left: -20px;
}

.about-logo {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #414042;
  font-size: 24px;
  font-family: "Dela Gothic One";
  line-height: 31px;
  cursor: pointer;
  text-decoration: none;
}
