.calendar-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: var(--calendar-font) !important;
  position: relative;
  transition: all 0.3s ease;
  padding: 20px 20px 40px 20px;
  box-sizing: border-box;
  overflow: hidden;
}
.calendar-page.sidebar-open {
  padding-right: 420px;
}

.calendar-page
  *:not(.sidebar-font-options *, .mini-bar .sidebar-font-options *) {
  font-family: var(--calendar-font) !important;
}

.sidebar-font-options li span,
.mini-bar .sidebar-font-options li span {
}

.sidebar-font-options,
.mini-bar .sidebar-font-options {
  width: 250px;
  max-height: 300px;
  overflow-y: auto;
}

.sidebar-font-options li,
.mini-bar .sidebar-font-options li {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sidebar-font-options li span,
.mini-bar .sidebar-font-options li span {
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.calendar-page {
  display: flex;
  height: 100%;
  font-family: inherit;
  position: relative;
  transition: margin-right 0.3s ease-in-out;
  padding: 20px 20px 40px 20px;
  box-sizing: border-box;
}

.calendar-page.sidebar-open {
  margin-right: 400px;
}

.calendar-wrapper {
  flex: none;
  display: flex;
  flex-direction: column;
  height: min(calc(100vh - 140px), 900px);
  width: 100%;
  max-width: 1400px;
  margin: 20px auto;
  padding: clamp(10px, 2vw, 20px);
  background-color: #1e1e1e;
  border: 1px solid rgba(225, 225, 225, 0.5);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  overflow: hidden;
}

.calendar-wrapper:hover {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(225, 225, 225, 0.3);
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: var(--background-secondary);
  border-bottom: 1px solid var(--border-color);
}

.year-month-display,
.year-month-buttons,
.view-selector,
.calendar-toolbar {
  display: flex;
  align-items: center;
}

.date-selector {
  display: flex;
  align-items: center;
}

.year-month-container {
  display: flex;
  align-items: center;
}

.year-month-display {
  font-size: clamp(24px, 3vw, 32px);
  font-weight: bold;
  margin-right: 15px;
}

.year-month-buttons {
  margin-right: auto;
}

.month-selector {
  margin: 0 10px;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #888;
  background-color: transparent;
}

.style-selector {
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.calendar-content {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.calendar-content::-webkit-scrollbar {
  display: none;
}

.calendar-container {
  position: relative;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-bottom: 40px;
}

.calendar-container::-webkit-scrollbar {
  width: 6px;
}

.calendar-container::-webkit-scrollbar-thumb {
  background-color: rgba(225, 225, 225, 0.2);
  border-radius: 3px;
}

.calendar-container::-webkit-scrollbar-track {
  background: transparent;
}

.month-selector,
.style-selector {
  padding: 5px 10px;
  font-size: 16px;
  border: 0px;
  background-color: #ffffff;
  cursor: pointer;
}

.style-selector {
  margin-left: 10px;
}

.calendar-grid {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 25px repeat(6, minmax(100px, 1fr));
  gap: 0;
  height: 100%;
  min-height: 0;
}

.calendar-grid > .weekday-cell {
  height: auto;
  padding: 8px;
  text-align: center;
}

.calendar-cell {
  min-height: 0;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: clamp(4px, 1vw, 8px);
  font-size: clamp(1px, 1vw, 14px);
  /*background-color: #1e1e1e;*/
  color: #e1e1e1;
  border: 1px solid rgba(225, 225, 225, 0.5);
  overflow: hidden;
}

.calendar-cell::-webkit-scrollbar {
  display: none;
}

.calendar-cell:hover {
  scrollbar-width: thin;
  -ms-overflow-style: auto;
}

.calendar-cell:hover::-webkit-scrollbar {
  display: block;
  width: 6px;
}

.calendar-cell:hover::-webkit-scrollbar-track {
  background: transparent;
}

.calendar-cell:hover::-webkit-scrollbar-thumb {
  background-color: rgba(225, 225, 225, 0.2);
  border-radius: 3px;
}

.calendar-cell:hover::-webkit-scrollbar-thumb:hover {
  background-color: rgba(225, 225, 225, 0.3);
}

.calendar-cell .ql-editor:hover,
.day-calendar .ql-editor:hover {
  background-color: transparent;
}

.calendar-cell .ql-editor,
.day-calendar .ql-editor {
  cursor: text;
  height: 100% !important;
  background-color: transparent;
  padding: 5px;
  color: #e1e1e1;
  box-sizing: border-box;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  overflow-wrap: break-word;
}

.calendar-cell .ql-editor:hover,
.day-calendar .ql-editor:hover {
  border-color: #e1e1e1;
}

.calendar-cell .ql-editor {
  padding: 5px;
  color: #e1e1e1;
}

.day-calendar .ql-editor {
  padding: 5px;
  color: #e1e1e1;
}

.weekday-row {
  display: contents;
}

.weekday-cell {
  height: 25px !important;
  min-height: 25px !important;
  padding: 2px 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-weight: normal !important;
  color: #e1e1e1 !important;
  padding: 5px 0 !important;
  font-size: clamp(10px, 1vw, 12px) !important;
  border: 1px solid rgba(225, 225, 225, 0.4);
}

.year-selector {
  display: flex;
  align-items: center;
}

.year-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #e1e1e1;
}

.year-display {
  font-size: 20px;
  margin: 0 10px;
  font-weight: bold;
  color: #e0e0e0;
}

.canvas-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.day-calendar-content {
  flex: 1;
  overflow-y: auto;
  position: relative;
}

.time-row {
  display: flex;
  align-items: stretch;
}

.time-label {
  width: 50px;
  flex-shrink: 0;
}

.time-content {
  display: flex;
  width: 100%;
  overflow-x: hidden;
  position: relative;
}

.time-column {
  position: relative;
  min-width: 50px;
  flex: 0 0 auto;
}

.view-selector button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  filter: brightness(0) invert(0.6) !important;
}

.view-selector button img {
  width: 24px;
  height: 24px;
  /* filter: brightness(0) invert(0.8) !important;*/
}

.daytable-header {
  display: flex;
  font-size: 10px;
  /*background-color: #1e1e1e;*/
}

.daytable-header .time-label {
  width: 60px;
}

.daytable-header {
  flex: 1;

  text-align: right;
}
.header-column {
  position: sticky;
  left: 0;
  z-index: 2;
  /*background-color: #1e1e1e;*/
  color: #e1e1e1;
  flex: 1;
  padding: 0;
  text-align: right;
  position: relative;
  font-size: 14px;
  padding-bottom: 15px;
}

.header-column::after {
  content: "|";
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
  text-align: right;
  color: #ddd;
  font-size: 12px;
  line-height: 1;
}

.daytable-header .header-column:last-child {
  border-right: none;
}

.day-time-column {
  padding: 5px;
  text-align: right;
  border-right: 1px solid #ddd;
  transition: background-color 0.3s ease;
}

.week-navigation {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 16px;
  color: #e0e0e0;
}

.week-navigation button {
  background: none;
  border: none;
  color: var(--text-primary);
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
}

.week-navigation button:hover {
  background-color: var(--background-hover);
}

.week-navigation span {
  font-size: 1em;
  font-weight: 500;
}

.calendar-grid.week-view {
  display: grid;
  grid-template-rows: auto 1fr;

  overflow: hidden;
}

.calendar-grid.week-view .calendar-cell {
  height: auto;
}

.sidebar-toggle {
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.sidebar-toggle:hover {
  background-color: #e0e0e0;
}

.cell-content {
  flex: 1;
  overflow-y: auto;
  padding-top: 12px;
  margin-top: 5px;
  scrollbar-width: thin;
  scrollbar-color: rgba(225, 225, 225, 0.2) transparent;
}

.cell-content .ql-container {
  margin-top: 5px;
}

.floating-toolbar {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 1000;
}

.floating-toolbar button {
  margin: 0 2px;
  padding: 2px 5px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
}

.floating-toolbar button:hover {
  background-color: #e0e0e0;
}

.calendar-toolbar {
  bottom: 20px;
  right: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 5px;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  z-index: 1000;
  transition: all 0.3s ease;
}

.calendar-toolbar:hover {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.calendar-toolbar button {
  margin: 0 3px;
  padding: 5px 8px;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  color: #333;
  transition: all 0.2s ease;
}

.calendar-toolbar button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.calendar-toolbar button:active {
  background-color: rgba(0, 0, 0, 0.2);
}

.ql-container.ql-snow {
  border: none;
}

.ql-toolbar.ql-snow {
  border: none;
}

.ql-container {
  font-size: 16px;
}

.ql-container .ql-editor {
  font-size: 16px;
  cursor: text;
}

.year-calendar {
  width: 100%;
  overflow-x: auto;
  padding: 20px;
  /*background-color: #1e1e1e;*/
}

.year-grid {
  display: flex;
  flex-direction: column;
}

.year-row {
  display: flex;
}

.year-month-header {
  flex: 1;
  text-align: center;
  font-weight: bold;
  padding: 10px;
  /* background-color: #1e1e1e;*/
  color: #e1e1e1;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.year-cell {
  position: relative;
  min-height: 24px;
  padding: 2px 4px;
  border: 1px solid rgba(225, 225, 225, 0.1);
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: #ffffff;
}

.year-cell .day-number {
  font-size: 11px;
  position: absolute;
  top: 2px;
  left: 4px;
  color: #e1e1e1;
  opacity: 0.8;
}

.year-cell .ql-editor {
  padding: 2px 2px 2px 20px;
  font-size: 11px;
  min-height: 20px;
  line-height: 1.2;
}

.year-cell.invalid {
  background-color: transparent;
  border: none;
  pointer-events: none;
}

.year-cell.weekend {
  background-color: rgba(225, 225, 225, 0.02);
}

.year-cell:hover:not(.invalid) {
  background-color: rgba(225, 225, 225, 0.05);
}

.year-cell.selected {
  background-color: rgba(225, 225, 225, 0.08);
}

.year-calendar {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.year-row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0;
}

.year-month-header {
  padding: 4px;
  text-align: center;
  font-size: 12px;
  color: #e1e1e1;
  border: 1px solid rgba(225, 225, 225, 0.1);
  background-color: rgba(225, 225, 225, 0.02);
}

.day-calendar {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  /*background-color: #1e1e1e;*/
}

.day-grid {
  display: flex;
  flex-direction: column;
  position: relative;
}

.day-cell {
  display: flex;
  border-bottom: 1px solid rgba(238, 238, 238, 0.5);
  min-height: 40px;
  height: 40px;
}

.time-label {
  width: 60px;
  padding: 5px;
  text-align: right;
  font-weight: bold;
  color: #888;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.time-content {
  display: flex;
  width: 100%;
}

.time-column {
  flex: 1 0 0%;

  border-right: 1px solid rgba(240, 240, 240, 0.5);
  position: relative; /* 추가 */
}

.calendar-resize-handle {
  position: absolute;
  top: 0;
  right: -3px;
  width: 6px;
  height: 100%;
  background-color: transparent;
  cursor: col-resize;
  z-index: 10;
}

.calendar-resize-handle:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.time-column:last-child {
  border-right: none;
}

.time-column .ql-editor {
  padding: 5px;
  font-size: 12px;
}

.day-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.day-header button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  font-size: 1.2em;
  color: #e1e1e1;
}

.day-header h2 {
  margin: 0;
  min-width: 120px;
  text-align: center;
  color: #e1e1e1;
}

.time-label {
  margin-top: 5px;
  margin-right: 10px;
}

.no-spellcheck .ql-editor {
  -webkit-spell-check: none;
  -moz-spell-check: none;
  -ms-spell-check: none;
  spell-check: none;
}

.month-tabs {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 20px;
}

.month-tab {
  padding: 8px 16px;
  border: 1px solid rgba(225, 225, 225, 0.5);
  background: transparent;
  color: #e1e1e1;
  cursor: pointer;
  border-radius: 20px;
  transition: all 0.3s ease;
  font-size: 14px;
  position: relative;
}

.month-tab:hover {
  background-color: rgba(225, 225, 225, 0.1);
}

.month-tab.active {
  background-color: rgba(225, 225, 225, 0.15);
  border: 2px solid #e1e1e1;
  font-weight: bold;
  transform: scale(1.05);
}

/* 선택되지 않은 탭은 약간 흐리게 처리 */
.month-tab:not(.active) {
  opacity: 0.7;
}

.year-selector {
  display: flex;
  align-items: center;
}

.year-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0 10px;
}

.year-display {
  font-size: 24px;
  font-weight: bold;
  margin: 0 10px;
}

.week2-view {
  display: grid;
  grid-template-columns: 60px repeat(7, 1fr);
  gap: 0;
  width: 100%;
  height: auto;
  min-height: 100%;
  align-items: start;
}

.week2-cell {
  flex: 1;
  display: flex;
  flex-direction: column;
  /*border-right: 1px solid #ccc;*/
}

.week2-cell:last-child {
  /*border-right: 1px solid #ccc;*/
}

.week2-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0;
  margin-bottom: 10px;
}

.week2-day-name {
  font-size: 0.8em;
  color: #666;
}

.week2-day-number {
  font-size: 1.2em;
  font-weight: bold;
}

.week2-cell .cell-content {
  flex: 1;
  overflow-y: auto;
}

.calendar-cell.week2-cell {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 750px;
  border: 0.5px solid rgba(238, 238, 238, 0.5);
  padding: 0;
}

.week2-date {
  height: 30px;
  flex-shrink: 0;
  padding: 5px;
}

.time-blocks-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(24, 20px); /* 각 시간 블록의 높이를 30px로 고정 */
  overflow-y: auto;
  height: 100%;
}

.time-block {
  border: 0.5px solid rgba(238, 238, 238, 0.1);
  min-height: 20px;
  padding: 0;
  cursor: pointer;
  transition: background-color 0.2s;
}

.time-block:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.time-blocks-container .time-block.selected {
  background-color: rgba(252, 248, 235, 0.5);
}

.week2-time-column {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: inherit;
  width: 60px !important; /* 고정 너비 설정 */
}

.week2-time-cells-container {
  height: 100%;
  margin-top: 40px;
  height: calc(100% - 30px);
}

.week2-time-cell {
  border-bottom: 0px solid #eee;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;
  min-height: 20px;
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.mini-toolbar {
  background-color: rgba(30, 30, 30, 0.95);
  border: 1px solid rgba(225, 225, 225, 0.2);
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  z-index: 1000;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  width: 600px;
  position: fixed;
  transform: translate(-50%, -50%);
}

.mini-toolbar:hover {
  background-color: rgba(35, 35, 35, 0.98);
  border-color: rgba(225, 225, 225, 0.25);
}

.calendar-color-option {
  display: flex;
  align-items: center;
  gap: 10px;
}

.color-name-input {
  flex-grow: 1;
  background-color: rgba(30, 30, 30, 0.95);
  border: 1px solid rgba(225, 225, 225, 0.2);
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 12px;
  color: #e1e1e1;
  caret-color: #e1e1e1;
  transition: all 0.2s ease;
  -webkit-appearance: none; /* 기본 스타일 제거 */
  outline: none; /* 기본 아웃라인 제거 */
}

.color-name-input:focus {
  outline: none;
  border-color: rgba(255, 223, 0, 0.5); /* 노란색 테두리 추가 */
  box-shadow: 0 0 0 1px rgba(255, 223, 0, 0.2); /* 은은한 노란색 글로우 효과 */
  background-color: rgba(40, 40, 40, 0.95);
}

.color-name-input::placeholder {
  color: rgba(225, 225, 225, 0.5);
}

.color-name-input::selection {
  background-color: rgba(225, 225, 225, 0.2);
  color: #e1e1e1;
}

.week2-color-boxes {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  margin-top: 10px;
  background-color: rgba(40, 40, 40, 0.95); /* 배경색 추가 */
  border: 1px solid rgba(225, 225, 225, 0.2); /* 테두리 추가 */
  border-radius: 4px; /* 모서리 둥글게 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* 그림자 효과 */
}

.week2-color-box {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
}

.color-indicator {
  width: 12px;
  height: 12px;
  border-radius: 2px;
}

.color-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.week2-total-study-time {
  margin-top: 4px;
  font-weight: bold;
  font-size: 12px;
}

.mini-bar {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(30, 30, 30, 0.95);
  padding: 8px 15px;
  border-radius: 20px;
  border: 1px solid rgba(225, 225, 225, 0.2);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 1000;
  transition: all 0.3s ease;
}

.mini-bar:hover {
  background-color: rgba(30, 30, 30, 1);
  border: 1px solid rgba(225, 225, 225, 0.3);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.4);
  transform: translateX(-50%) translateY(-2px);
}

.mini-bar button {
  margin: 0 3px;
  padding: 5px 8px;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  color: #e1e1e1;
  transition: all 0.2s ease;
}

.mini-bar button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: scale(1.1);
}

.mini-bar button:active {
  background-color: rgba(255, 255, 255, 0.2);
  transform: scale(0.95);
}

.mini-bar-icon {
  width: 16px;
  height: 16px;
  filter: invert(0.9);
}

.mini-bar button {
  padding: 4px;
  margin: 0 2px;
}

.mini-bar .sidebar-font-options li {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.mini-bar .sidebar-font-options li span {
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mini-bar .sidebar-font-selector button {
  background: none;
  border: none;
  font-size: 14px;
  color: #e1e1e1;
  cursor: pointer;
  padding: 5px 10px;
  margin-left: 10px;
}

.mini-bar .sidebar-font-selector button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.mini-bar .sidebar-font-options {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 200px;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #2a2a2a;
  border: 1px solid #555;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.mini-bar .sidebar-font-options li {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #e1e1e1;
}

.mini-bar .sidebar-font-options li:hover {
  background-color: #3a3a3a;
}

.mini-bar .sidebar-font-options {
  width: 250px;
  max-height: 300px;
  position: absolute;
  bottom: 100%;
  left: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #2a2a2a;
  border: 1px solid #555;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  overflow-y: auto;
}

.mini-bar .sidebar-font-options::-webkit-scrollbar {
  width: 8px;
}

.mini-bar .sidebar-font-options::-webkit-scrollbar-track {
  background: #2a2a2a;
}

.mini-bar .sidebar-font-options::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 4px;
}

.mini-bar .sidebar-font-options::-webkit-scrollbar-thumb:hover {
  background: #666;
}

.mini-bar input[type="color"] {
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.mini-bar select {
  margin-left: 10px;
  padding: 5px;
  border: none;
  background-color: transparent;
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.mini-bar .sidebar-font-selector {
  position: relative;
}

.mini-bar .sidebar-font-options {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 200px;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #2a2a2a;
  border: 1px solid #555;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.mini-bar .sidebar-font-options::-webkit-scrollbar {
  width: 8px;
}

.mini-bar .sidebar-font-options::-webkit-scrollbar-track {
  background: #2a2a2a;
}

.mini-bar .sidebar-font-options::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 4px;
}

.mini-bar .sidebar-font-options::-webkit-scrollbar-thumb:hover {
  background: #666;
}

.mini-bar .sidebar-font-options li {
  padding: 10px;
  cursor: pointer;
}

.mini-bar .sidebar-font-options li:hover {
  background-color: #3a3a3a;
}

.mini-bar select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  padding-right: 25px;
}

.mini-bar .font-color-container {
  position: relative;
}

.mini-bar .font-colors {
  position: absolute;
  top: -40px;
  left: 0;
  display: flex;
  background-color: #2a2a2a;
  border: 1px solid #555;
  border-radius: 4px;
  padding: 5px;
  z-index: 1000;
}

.mini-bar .font-color-button {
  width: 20px;
  height: 20px;
  border: none;
  margin: 0 2px;
  cursor: pointer;
}

.mini-bar .font-color-button:hover {
  transform: scale(1.1);
}

.color-palette {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
}

.palette-selector {
  display: flex;
  margin-bottom: 5px;
}

.palette-selector button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 2px 5px;
  margin: 0 2px;
  cursor: pointer;
  font-size: 12px;
}

.palette-selector button:hover {
  background-color: #e0e0e0;
}

.color-options button {
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  margin: 2px;
  cursor: pointer;
}

.calendar-cell {
  transition: background-color 0.3s ease, border 0.3s ease;
}

.mini-bar .background-color-container {
  position: relative;
}

.mini-bar .background-color-container button {
  width: 24px;
  height: 24px;
  border: 1px solid #555;
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.mini-bar .background-color-container button::before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: inherit;
}

.mini-bar .background-colors {
  position: absolute;
  top: -80px;
  left: 0;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
  background-color: #2a2a2a;
  border: 1px solid #555;
  border-radius: 4px;
  padding: 8px;
  z-index: 1000;
  width: 245px;
}

.mini-bar .background-color-button {
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
}

.mini-bar .background-color-button:hover {
  transform: scale(1.1);
}

.calendar-cell:not(.week2-cell) {
  transition: background-color 0.3s ease, border 0.3s ease;
}

.time-content {
  display: flex;
  width: 100%;
}

.time-column {
  position: relative;
  min-width: 50px;
  flex: 0 0 auto;
}

.calendar-resize-handle {
  position: absolute;
  top: 0;
  right: -3px;
  width: 6px;
  height: 100%;
  background-color: transparent;
  cursor: col-resize;
  z-index: 10;
}

.calendar-resize-handle:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.font-size-selector {
  position: relative;
}

.font-size-options {
  position: absolute;
  bottom: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  list-style-type: none;
  z-index: 1000;
}

.font-size-options li {
  padding: 5px 10px;
  cursor: pointer;
}

.font-size-options li:hover {
  background-color: #f0f0f0;
}

.custom-todo-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 10px !important;
}

.custom-todo-container .list-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.custom-todo-container .list-form input {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.custom-todo-container .list-form button {
  padding: 10px;
  background-color: #fae150;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.custom-todo-container .list-form button img {
  margin-right: 5px;
}
.custom-task-lists {
  background-color: #f8f8f8;
}

.custom-task-list-header {
  border-radius: 10px !important;
  padding: 10px !important;
}
.custom-task-form input[type="text"] {
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* 패딩과 테두리를 포함한 너비 계산 */
}
.custom-task-list-content {
  padding: 0px;
  background-color: #ffffff;

  margin-top: 10px;
}

.tooltip {
  position: relative;
  display: inline-block;
  z-index: 1001;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 80px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1002;
  top: 125%;
  left: 50%;
  margin-left: -40px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
  pointer-events: none;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.today-date {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .calendar-page {
    padding: 15px 15px 30px 15px;
  }

  .calendar-wrapper {
    margin-left: 10px;
  }

  .calendar-container {
    min-width: 100%;
  }

  .calendar-cell textarea {
    font-size: clamp(10px, 1.5vw, 14px);
  }
}

@media screen and (max-width: 480px) {
  .calendar-page {
    padding: 10px 10px 25px 10px;
  }

  .calendar-wrapper {
    margin-left: 5px;
    padding: 5px;
  }
}

/* 데일리 1뷰의 폰트 사이즈 설정 */
.day-calendar .time-column .ql-editor {
  font-size: 16px; /* 원하는 크기로 조정 가능 */
  color: #e1e1e1;
}

.day-header button:hover {
  color: #2e2e2e;
}

.calendar-cell .ql-editor,
.day-calendar .ql-editor {
  cursor: text;
  height: 100% !important;
  background-color: transparent;
  padding: 5px;
  color: #e1e1e1;
  box-sizing: border-box;
  border: 1px dashed transparent;
  transition: border-color 0.2s ease;
}

.calendar-cell .ql-editor:hover,
.day-calendar .ql-editor:hover {
  border-color: rgba(225, 225, 225, 0.15);
}

.calendar-cell .date-display {
  height: 25px;
  position: absolute;
  top: 0;
  left: 5px;
}

/* 미니바 아이콘 색상 변경 */
.mini-bar button img.mini-bar-icon {
  width: 16px;
  height: 16px;
  filter: invert(0.9) !important; /* SVG 아이콘을 밝은 색상으로 변경 */
}

/* 호버 시 약간 더 밝게 */
.mini-bar button:hover img.mini-bar-icon {
  filter: invert(1);
}

/* 폰트 색상 선택 버튼은 원래 색상 유지 */
.mini-bar .font-color-container button {
  filter: none;
}

.week2-total-study-time {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-weight: bold;
  color: #e1e1e1;
}

@media screen and (max-width: 768px) {
  .calendar-wrapper {
    padding: 10px;
  }
}

/* border-left를 개별 셀에 적용 */
.calendar-cell,
.weekday-cell {
  /*border-left: 1px solid rgba(225, 225, 225, 0.1);*/
  border-top: 1px solid rgba(225, 225, 225, 0.1);
}

/* 빈 셀에는 border를 제거하고 아랫쪽만 유지 */
.calendar-cell:empty {
  /*border-bottom: 1px solid rgba(225, 225, 225, 0.1);*/
  background: transparent;
}

/* 주간 헤더 셀은 항상 border 유지 */
.weekday-cell {
  /* border-left: 1px solid rgba(225, 225, 225, 0.1);*/
  border-top: 1px solid rgba(225, 225, 225, 0.5);
}

.date-number {
  background: transparent !important;
  margin-bottom: 4px;
}

.unit-cards-container {
  display: block;
  width: 100% !important;
  margin-top: 10px !important;
}

.unit-card {
  display: block;
  width: 100% !important;
  border-radius: 4px;
  padding: 4px 8px;
  margin: 2px 0;
  font-size: 1 em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
  cursor: default;
  box-sizing: border-box;
  border: 1px solid rgba(225, 225, 225, 0.08);
  background-color: rgba(255, 255, 255, 0.03);
  transition: border-color 0.2s ease, background-color 0.2s ease;
}

.unit-card:hover {
  border-color: rgba(225, 225, 225, 0.15);
  background-color: rgba(255, 255, 255, 0.05);
}

/* Webkit 브라우저용 스크롤바 스타일링 */
.calendar-cell::-webkit-scrollbar {
  width: 6px;
}

.calendar-cell::-webkit-scrollbar-track {
  background: transparent;
}

.calendar-cell::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 3px;
  transition: background-color 0.2s;
}

/* 호버 시에만 스크롤바 보이기 */
.calendar-cell:hover::-webkit-scrollbar-thumb {
  background-color: rgba(225, 225, 225, 0.2);
}

/* 스크롤바 호버 시 약간 더 진하게 */
.calendar-cell::-webkit-scrollbar-thumb:hover {
  background-color: rgba(225, 225, 225, 0.3);
}

/* 반응형 조정 */
@media screen and (max-width: 1200px) {
  .calendar-wrapper {
    width: 88vw;
    padding: 15px;
  }

  .calendar-page.sidebar-open .calendar-wrapper {
    width: calc(88vw - var(--sidebar-width, 0px));
  }
}

@media screen and (max-width: 768px) {
  .calendar-wrapper {
    width: 90vw;
    padding: 10px;
    margin: 10px auto;
  }

  .calendar-page.sidebar-open .calendar-wrapper {
    width: calc(90vw - var(--sidebar-width, 0px));
  }
}

@media screen and (max-width: 480px) {
  .calendar-wrapper {
    width: 92vw;
    padding: 5px;
    margin: 5px auto;
  }

  .calendar-page.sidebar-open .calendar-wrapper {
    width: calc(92vw - var(--sidebar-width, 0px));
  }
}

/* 높이 관련 반응형 */
@media screen and (max-height: 800px) {
  .calendar-grid {
    grid-template-rows: 25px repeat(6, minmax(70px, 1fr));
  }
}

@media screen and (max-height: 600px) {
  .calendar-grid {
    grid-template-rows: 25px repeat(6, minmax(60px, 1fr));
  }
}

/* 날짜 표시 영역 스타일 */
.date-display {
  position: sticky;
  top: 0;
  left: 5px;
  height: 25px;
  z-index: 2;
  padding: 8px 0 5px 0; /* 상단 패딩 8px 추가 */
  margin-top: 3px; /* 추가적인 상단 여백 */
  display: flex;
  align-items: center;
}

/* 내용을 담는 컨테이너 스타일 */
.cell-content {
  flex: 1;
  overflow-y: auto;
  padding-top: 20px;
  scrollbar-width: thin;
  scrollbar-color: rgba(225, 225, 225, 0.2) transparent;
}

/* 스크롤바 스타일 위치 변경 */
.cell-content::-webkit-scrollbar {
  width: 6px;
}

.cell-content::-webkit-scrollbar-track {
  background: transparent;
}

.cell-content::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 3px;
}

.cell-content:hover::-webkit-scrollbar-thumb {
  background-color: rgba(225, 225, 225, 0.2);
}

.cell-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(225, 225, 225, 0.3);
}

/* 모든 뷰의 공통 스크롤바 숨기기 */
.calendar-page,
.calendar-content,
.calendar-container,
.calendar-cell,
.day-calendar,
.year-calendar,
.week2-view,
.day-calendar-content,
.cell-content,
.ql-editor {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.calendar-page::-webkit-scrollbar,
.calendar-content::-webkit-scrollbar,
.calendar-container::-webkit-scrollbar,
.calendar-cell::-webkit-scrollbar,
.day-calendar::-webkit-scrollbar,
.year-calendar::-webkit-scrollbar,
.week2-view::-webkit-scrollbar,
.day-calendar-content::-webkit-scrollbar,
.cell-content::-webkit-scrollbar,
.ql-editor::-webkit-scrollbar {
  display: none;
}

/* 데일리 뷰 스크롤바 숨기기 */
.day-calendar .ql-editor,
.day-calendar .time-column,
.ql-container {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.day-calendar .ql-editor::-webkit-scrollbar,
.day-calendar .time-column::-webkit-scrollbar,
.ql-container::-webkit-scrollbar {
  display: none;
}

/* 위클리 2 뷰 스크롤바 숨기기 */
.week2-cell,
.week2-content {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.week2-cell::-webkit-scrollbar,
.week2-content::-webkit-scrollbar {
  display: none;
}

/* 연간 뷰 스크롤바 숨기기 */
.year-grid,
.year-cell {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.year-grid::-webkit-scrollbar,
.year-cell::-webkit-scrollbar {
  display: none;
}

.today-button {
  padding: 5px 12px;
  margin-right: 15px;
  background-color: #2a2a2a;
  color: #e1e1e1;
  border: 1px solid #404040;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.today-button:hover {
  background-color: #404040;
  border-color: #505050;
}

.today-button:active {
  background-color: #505050;
}

/* 12개월 뷰 스타일링 */
.year12-calendar {
  width: 100%;
  height: calc(100vh - 200px);
  padding: 20px;
  overflow: auto;
}

.year12-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 20px;
  height: 100%;
}

.month-container {
  border: 1px solid rgba(225, 225, 225, 0.4);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /*background-color: #1e1e1e;*/
  transition: all 0.3s ease;
  min-height: 200px;
}

.month-container:hover {
  border-color: rgba(225, 225, 225, 0.2);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.month-header {
  padding: 12px;
  background-color: rgba(225, 225, 225, 0.05);
  text-align: center;
  font-weight: bold;
  color: #e1e1e1;
  border-bottom: 1px solid rgba(225, 225, 225, 0.1);
}

.month-content {
  flex: 1;
  padding: 10px;
  overflow: auto;
}

.month-content .ql-editor {
  padding: 10px;
  min-height: 150px;
  color: #e1e1e1;
  font-size: 18px;
  line-height: 1.5;
}

/* 스크롤바 스타일링 */
.month-content::-webkit-scrollbar {
  width: 6px;
}

.month-content::-webkit-scrollbar-track {
  background: transparent;
}

.month-content::-webkit-scrollbar-thumb {
  background-color: rgba(225, 225, 225, 0.1);
  border-radius: 3px;
}

.month-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(225, 225, 225, 0.2);
}

/* 반응형 디자인 */
@media screen and (max-width: 1200px) {
  .year12-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .year12-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);
  }
}

@media screen and (max-width: 480px) {
  .year12-grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(12, 1fr);
  }

  .month-container {
    min-height: 150px;
  }
}

/* 12개월 뷰 스크롤바 숨기기 */
.month-content,
.month-content .ql-container,
.month-content .ql-editor {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.month-content::-webkit-scrollbar,
.month-content .ql-container::-webkit-scrollbar,
.month-content .ql-editor::-webkit-scrollbar {
  display: none;
}

/* 12개월 뷰 전체 스크롤바만 보이도록 수정 */
.year12-calendar {
  scrollbar-width: thin;
  scrollbar-color: rgba(225, 225, 225, 0.2) transparent;
}

.year12-calendar::-webkit-scrollbar {
  width: 6px;
  display: block;
}

.year12-calendar::-webkit-scrollbar-track {
  background: transparent;
}

.year12-calendar::-webkit-scrollbar-thumb {
  background-color: rgba(225, 225, 225, 0.2);
  border-radius: 3px;
}

.year12-calendar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(225, 225, 225, 0.3);
}

/* 각 시간대의 첫 번째와 마지막 타임블록 구분선 제거 */
.calendar-cell.week2-cell .time-block:nth-child(6n + 1) {
  border-left: none; /* 각 시간대의 첫 번째 타임블록 왼쪽 선 제거 */
}

.calendar-cell.week2-cell .time-block:nth-child(6n) {
  border-right: none; /* 각 시간대의 마지막 타임블록 오른쪽 선 제거 */
}

.habit-calendar {
  width: 100%;
  height: calc(100vh - 200px);
  padding: 20px;
}

.habit-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.habit-row {
  display: flex;
  align-items: center;
  gap: 40px;
}

.habit-name {
  width: 150px;
  color: #e1e1e1;
  font-size: 14px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.habit-name:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.habit-name-input {
  width: 90%;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  color: #e1e1e1;
  font-size: 14px;
  padding: 4px 8px;
  outline: none;
}

.habit-name-input:focus {
  border-color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.3);
}

.habit-grid-container {
  display: flex;
}

.habit-grid-row {
  display: grid;
  grid-template-columns: repeat(16, 1fr); /* 4개의 열로 설정 */
  max-width: calc(4 * 25px + 3 * 4px); /* 4칸 * 최소너비 + 3개의 gap */
  gap: 4px;
}

.habit-grid-row.second {
  grid-template-columns: repeat(4, 1fr); /* 두 번째 줄도 4개의 열로 설정 */
}

.habit-cell {
  aspect-ratio: 1;
  background-color: rgba(50, 50, 50, 0.95);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.habit-cell .day-number {
  color: #888;
  font-size: 24px;
  padding: 5px;
}

.habit-cell:hover {
  background-color: rgba(60, 60, 60, 0.95);
}

/* 체크된 상태의 스타일 변경 */
.habit-cell.checked {
  background-color: rgba(255, 223, 126, 0.3); /* 연한 노란색으로 변경 */
}

.habit-cell.checked:hover {
  background-color: rgba(255, 223, 126, 0.4); /* 호버 시 약간 더 진하게 */
}

.week-view .calendar-cell {
  height: auto;
  min-height: 150px; /* 유닛카드를 위한 공간 확보 */
}

.week-view .unit-cards-container {
  margin-bottom: 8px;
  max-height: 80px; /* 위클리 뷰에서 유닛카드 컨테이너 높이 조정 */
}

.week-view .unit-card {
  padding: 2px 6px;
  margin: 2px 0;
  border-radius: 4px;
  font-size: 0.9em;
}

/* 글꼴 선택기 스타일 */
.font-select-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  background-color: #2a2a2a;
  border: 1px solid #3a3a3a;
  border-radius: 4px;
  color: #e1e1e1;
  cursor: pointer;
  transition: all 0.2s ease;
}

.font-select-button:hover {
  background-color: #3a3a3a;
}

.font-name {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}

.font-icon {
  width: 16px;
  height: 16px;
  filter: invert(1);
}

.font-option {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.font-option:hover {
  background-color: #2a2a2a;
}

.sidebar-font-options {
  position: absolute;
  bottom: 100%;
  left: 0;
  background-color: #1a1a1a;
  border: 1px solid #3a3a3a;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  margin: 0;
  padding: 4px 0;
  list-style: none;
  width: 200px;
}

/* 데일리 뷰 전체 구조 */
.day-calendar {
  height: 100%;
  display: flex;
  flex-direction: column;
  /*background-color: #1e1e1e;*/
}

/* 시간 헤더 고정 */
.daytable-header {
  position: sticky;
  top: 0;
  background-color: #1e1e1e;
  border-bottom: 1px solid rgba(225, 225, 225, 0.5);
  display: flex;
  z-index: 999;
}

/* 스크롤되는 컨텐츠 영역 */
.day-calendar-content {
  flex: 1;
  overflow-y: auto;
}

/* 스크롤바 스타일 */
.day-calendar-content::-webkit-scrollbar {
  width: 8px;
}

.day-calendar-content::-webkit-scrollbar-track {
  background: transparent;
}

.day-calendar-content::-webkit-scrollbar-thumb {
  background-color: rgba(225, 225, 225, 0.2);
  border-radius: 4px;
}

/* 사이드바 z-index 수정 */
.cal-todo-sidebar-container {
  z-index: 9999; /* 최상위 레벨로 설정 */
}

.cal-todo-sidebar-container.open {
  z-index: 9999; /* 최상위 레벨로 설정 */
}

.calendar-container {
  transition: margin-right 0.3s ease;
}

.calendar-container.sidebar-open {
  margin-right: 400px;
}

@media screen and (max-width: 1200px) {
  .calendar-container.sidebar-open {
    margin-right: 350px;
  }
}

@media screen and (max-width: 768px) {
  .calendar-container.sidebar-open {
    margin-right: 300px;
  }
}
/* 반응형 처리 */
@media screen and (max-width: 1200px) {
  .calendar-page.sidebar-open {
    margin-right: 350px;
  }
}

@media screen and (max-width: 768px) {
  .calendar-page.sidebar-open {
    margin-right: 300px;
  }
}

.add-habit-row {
  margin-top: 10px;
}

.add-habit-button {
  width: 150px;
  padding: 8px;
  background-color: rgba(255, 223, 126, 0.2);
  border: 1px solid rgba(255, 223, 126, 0.3);
  border-radius: 4px;
  color: #e1e1e1;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
}

.add-habit-button:hover {
  background-color: rgba(255, 223, 126, 0.3);
  border-color: rgba(255, 223, 126, 0.4);
}

.add-habit-button:active {
  background-color: rgba(255, 223, 126, 0.4);
}

/* 다크 모드 스타일 */
input[type="date"] {
  background-color: #2c2c2c; /* 어두운 배경색 */
  color: #ffffff; /* 흰색 글자색 */
  border: 1px solid #444; /* 어두운 테두리 */
  border-radius: 4px; /* 모서리 둥글게 */
  padding: 8px; /* 패딩 추가 */
  font-size: 16px; /* 글자 크기 */
  transition: background-color 0.3s, color 0.3s; /* 부드러운 전환 효과 */
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background-color: #444; /* 다크 모드에서의 캘린더 아이콘 배경색 */
  border-radius: 4px; /* 아이콘 모서리 둥글게 */
}

input[type="date"]:focus {
  outline: none; /* 포커스 시 아웃라인 제거 */
  border-color: #007bff; /* 포커스 시 테두리 색상 변경 */
}

.monthly-editable {
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  padding: 4px;
  color: inherit;
  font-family: inherit;
  font-size: 0.9em;
  line-height: 1.2;
  outline: none;
  overflow-y: auto;
}

.monthly-editable:empty:before {
  color: rgba(225, 225, 225, 0.5);
}

.monthly-editable:focus {
  background: rgba(0, 0, 0, 0.1);
}

/* 사이드바 컨테이너 기본 스타일 */
.cal-todo-sidebar-container {
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;
  height: 100vh;
  transition: width 0.3s ease;
  z-index: 9998;
}

/* 토글 버튼 스타일 */
.todo-toggle-button {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
  padding: 8px;
  background-color: #1a1a1a;
  border: none;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* 사이드바 열린 상태일 때 */
.calendar-page.sidebar-open {
  padding-right: 400px;
  transition: padding-right 0.3s ease;
}

.calendar-page.sidebar-open .todo-toggle-button {
  right: 400px;
  transition: right 0.3s ease;
}

/* 반응형 조정 */
@media screen and (max-width: 1200px) {
  .cal-todo-sidebar-container {
    width: 350px;
  }

  .calendar-page.sidebar-open {
    padding-right: 350px;
  }

  .calendar-page.sidebar-open .todo-toggle-button {
    right: 350px;
  }
}

@media screen and (max-width: 768px) {
  .cal-todo-sidebar-container {
    width: 300px;
  }

  .calendar-page.sidebar-open {
    padding-right: 300px;
  }

  .calendar-page.sidebar-open .todo-toggle-button {
    right: 300px;
  }
}

/* 뷰 셀렉터 전용 툴팁 클래스로 변경 */
.view-selector .view-tooltip {
  display: flex;
  align-items: center;
  position: relative;
  flex-shrink: 0;
}

.view-selector .view-tooltip .view-tooltiptext {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;

  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
  white-space: nowrap;
}

.view-selector .view-tooltip:hover .view-tooltiptext {
  visibility: visible;
  opacity: 1;
}

.view-selector .view-tooltip .view-tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
}

@media screen and (max-width: 768px) {
  .view-selector .view-tooltip .view-tooltiptext {
    display: none;
  }
}

/* 먼슬리 뷰에서 유닛카드 숨기기 */
.calendar-page[data-view="month"] .unit-cards-container {
  display: none;
}

/* 위클리1 뷰에서만 유닛카드 표시 */
.calendar-page[data-view="week1"] .unit-cards-container {
  display: block;
  margin-bottom: 8px;
  max-height: 80px;
  overflow-y: auto;
}

/* 위클리1 뷰의 유닛카드 스타일 */
.calendar-page[data-view="week1"] .unit-card {
  padding: 2px 6px;
  margin: 2px 0;
  border-radius: 4px;
  font-size: 0.9em;
}

/* 스크롤바 스타일링 */
.calendar-page[data-view="week1"] .unit-cards-container::-webkit-scrollbar {
  width: 4px;
}

.calendar-page[data-view="week1"]
  .unit-cards-container::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
}

.calendar-page[data-view="week1"]
  .unit-cards-container::-webkit-scrollbar-track {
  background: transparent;
}
