.about-container {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
}

.about-content {
  width: 100%;
  margin: 0;
  padding: 0;
}

/* Landing Page 헤더 스타일 */
.landing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: #ffffff;
  border-bottom: 1px solid #eee;
}

.landing-logo a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.landing-header-buttons {
  display: flex;
  gap: 20px;
  align-items: center;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1;
}

.dropdown-content a {
  color: #231c16;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a:hover {
  background-color: #f9f9f9;
}

.about-hero {
  width: 100%;
  padding: 120px 40px;
  background-color: #fff;
}

.about-hero-content {
  max-width: 1200px;
  margin: 0 auto;
}

.about-subtitle {
  font-size: 1.25rem;
  color: #6b7280;
  margin-bottom: 1rem;
  font-weight: 500;
}

.about-title {
  font-size: 4rem;
  line-height: 1.2;
  font-weight: bold;
  color: #111827;
  margin: 0;
}

@media (max-width: 768px) {
  .about-hero {
    padding: 80px 20px;
  }

  .about-title {
    font-size: 2.5rem;
  }
}

.about-welcome {
  width: 100%;
  padding: 60px 40px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
}

.welcome-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 60px;
}

.welcome-image {
  flex: 0 0 auto;
  width: 400px;
}

.welcome-image img {
  width: 100%;
  height: auto;
}

.welcome-text-container {
  flex: 1;
}

.welcome-text {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #374151;
  margin: 0;
  font-weight: 400;
}

@media (max-width: 968px) {
  .welcome-content {
    flex-direction: column;
    gap: 40px;
  }

  .welcome-image {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }

  .welcome-text {
    font-size: 1.25rem;
    text-align: center;
  }
}

.about-journey {
  width: 100%;
  padding: 100px 40px;
  background-color: #fff;
}

.journey-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 60px;
}

.journey-text-container {
  flex: 1;
}

.journey-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #111827;
  margin-bottom: 2rem;
}

.journey-text {
  font-size: 1.25rem;
  line-height: 1.7;
  color: #374151;
}

.journey-image {
  flex: 0 0 auto;
  width: 400px;
}

.journey-image img {
  width: 100%;
  height: auto;
}

@media (max-width: 968px) {
  .about-journey {
    padding: 60px 40px;
  }

  .journey-content {
    flex-direction: column;
    gap: 40px;
  }

  .journey-title {
    font-size: 2rem;
    text-align: center;
  }

  .journey-text {
    font-size: 1.125rem;
    text-align: center;
  }

  .journey-image {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    order: -1;
  }
}

.about-mission {
  width: 100%;
  padding: 100px 40px;
  background-color: #fff;
}

.mission-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 60px;
}

.mission-text-container {
  flex: 1;
}

.mission-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #111827;
  margin-bottom: 2rem;
}

.mission-text {
  font-size: 1.25rem;
  line-height: 1.7;
  color: #374151;
}

.mission-image {
  flex: 0 0 auto;
  width: 400px;
}

.mission-image img {
  width: 100%;
  height: auto;
}

@media (max-width: 968px) {
  .about-mission {
    padding: 60px 40px;
  }

  .mission-content {
    flex-direction: column;
    gap: 40px;
  }

  .mission-title {
    font-size: 2rem;
    text-align: center;
  }

  .mission-text {
    font-size: 1.125rem;
    text-align: center;
  }

  .mission-image {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    order: -1;
  }
}

.about-why {
  width: 100%;
  padding: 100px 40px;
  background-color: #fafafa;
}

.why-content {
  max-width: 1200px;
  margin: 0 auto;
}

.why-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #111827;
  margin-bottom: 3rem;
  text-align: center;
}

.why-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
}

.why-feature {
  background: #ffffff;
  padding: 32px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.why-feature:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}

.why-feature h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 1rem;
}

.why-feature p {
  font-size: 1rem;
  line-height: 1.6;
  color: #4b5563;
}

@media (max-width: 768px) {
  .about-why {
    padding: 60px 20px;
  }

  .why-title {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .why-features {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .why-feature {
    padding: 24px;
  }
}

.about-logo {
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
}

.about-logo {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #414042;
  font-size: 24px;
  font-family: "Dela Gothic One";
  line-height: 31px;
  cursor: pointer;
  text-decoration: none;
}
