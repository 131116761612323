.left-bar {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 154px);
  position: fixed;
  top: 110px;
  left: 0;
  background-color: #1a1a1a;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  transition: width 0.3s ease;
  z-index: 1000;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  color: #e0e0e0;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
}

.left-bar.open {
  width: 250px;
}

.left-bar.closed {
  width: 40px;
}

.sidebar-header {
  position: sticky;
  top: 0;
  background-color: #your-background-color;
  z-index: 1;
  padding: 10px;
}

.toggle-button {
  padding: 5px 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #e0e0e0;
  transition: all 0.2s ease;
}

.toggle-button:hover {
  color: #fae150;
}

.leftbar-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;
}

.leftbar-scroll {
  overflow-y: auto;
  height: calc(100vh - [header-height]px);
}

.leftbar-course-title {
  position: sticky;
  top: 40px;
  background-color: #1a1a1a;
  z-index: 2;
  padding: 10px;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* 섹션 스타일 */
.leftbar-section {
  margin-bottom: 15px;
  margin-top: 50px;
}

.section-header {
  padding: 8px 10px;
  font-weight: 500;
  color: #fae150;
  font-size: 14px;
  background-color: #252525;
  border-radius: 4px;
  margin-bottom: 5px;
  border-left: 3px solid #fae150;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* 챕터 스타일 */
.leftbar-chapter {
  margin-left: 10px;
  margin-bottom: 10px;
}

.chapter-header {
  padding: 6px 10px;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
  transition: color 0.2s ease;
}

.chapter-header:hover {
  color: #fae150;
}

/* 유닛 스타일 */
.leftbar-unit {
  position: relative;
  margin-left: 15px;
  margin-bottom: 8px;
}

.leftbar-unit-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #222;
  padding: 6px 10px;
  margin-bottom: 4px;
  border-radius: 4px;
  font-size: 13px;
  color: #e0e0e0;
}

.leftbar-unit-header:hover {
  background-color: #252525;
  color: #ffffff;
}

.leftbar-unit-toggle-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  min-width: 16px;
  margin-left: 5px;
  filter: invert(0.8);
  transition: transform 0.2s ease, filter 0.2s ease;
}

.leftbar-unit-header:hover .leftbar-unit-toggle-icon {
  filter: invert(1);
  transform: scale(1.1);
}

.leftbar-unit-name {
  padding-right: 10px;
}

/* 서브유닛 스타일 */
.leftbar-subunit {
  padding: 6px 10px;
  margin: 4px 0;
  font-size: 12px;
  color: #b0b0b0;
  background-color: #1a1a1a;
  border-radius: 3px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease;
  background-color: #222;
  border: 1px solid rgba(255, 255, 255, 0.05);
  user-select: none;
}

/* hover와 selected 스타일을 동일하게 설정 */
.leftbar-subunit:hover,
.leftbar-subunit.selected {
  background-color: rgba(250, 225, 80, 0.15) !important;
  color: #fae150 !important;
  border: 1px solid rgba(250, 225, 80, 0.3) !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  transform: translateX(4px);
  cursor: pointer;
}

/* 드래깅 상태 스타일 */
.leftbar-subunit.dragging {
  background: #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* 스크롤바 스타일 */
.leftbar-scroll::-webkit-scrollbar {
  width: 6px;
}

.leftbar-scroll::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.leftbar-scroll::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 3px;
}

.leftbar-scroll::-webkit-scrollbar-thumb:hover {
  background: #444;
}

.leftbar-subunit input {
  width: 90%;
  border: none;
  background: transparent;
  color: #e0e0e0;
  font-size: inherit;
  padding: 2px 4px;
  outline: none;
  border-bottom: 1px solid rgba(250, 225, 80, 0.3);
  transition: all 0.2s ease;
  background-color: #2a2a2a;
  color: #ffffff;
}

.leftbar-subunit input:focus {
  border-bottom: 1px solid #fae150;
  box-shadow: 0 1px 3px rgba(250, 225, 80, 0.2);
}

.leftbar-subunit-list {
  min-height: 2px; /* 드롭 가능한 영역을 위한 최소 높이 */
}

.leftbar-subunit {
  user-select: none; /* 드래그 중 텍스트 선택 방지 */
}

.leftbar-subunit:hover {
  background: #f5f5f5;
}

.leftbar-subunit.dragging {
  background: #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* 드래그 중일 때 드롭 가능한 영역 스타일 */
.leftbar-subunit-list.dragging-over {
  background-color: rgba(255, 255, 255, 0.05);
  min-height: 30px;
}

/* 닫힌 유닛에 드래그 오버 시 시각적 피드백 */
.leftbar-unit:not(.expanded) .leftbar-unit-header.drag-over {
  background-color: rgba(250, 225, 80, 0.1);
}

.leftbar-unit-header.drag-over {
  background-color: rgba(250, 225, 80, 0.1);
  border-radius: 4px;
}

.leftbar-unit {
  position: relative;
}

.leftbar-unit-header {
  transition: background-color 0.2s ease;
}

.subunit-list {
  min-height: 2px;
  transition: height 0.2s ease;
}

.subunit-list.collapsed {
  min-height: 2px;
  height: 2px;
  overflow: hidden;
}

.subunit-list.dragging-over {
  background-color: rgba(255, 255, 255, 0.05);
  min-height: 30px;
}

.leftbar-unit-header.drag-over {
  background-color: rgba(250, 225, 80, 0.1);
}

.leftbar-unit-header button:hover {
  background-color: rgba(250, 225, 80, 0.1);
  border-radius: 4px;
}

.leftbar-unit-header button img {
  opacity: 0.6;
}

.leftbar-unit-header button:hover img {
  opacity: 1;
}

/* 기존 스타일 유지하면서 추가 */
.leftbar-unit-header button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.leftbar-unit-header button img {
  width: 14px !important;
  height: 14px !important;
  filter: invert(1) !important; /* 이미지를 흰색으로 변경 */
  transition: all 0.2s ease !important;
}

.leftbar-unit-header button:hover {
  background-color: rgba(250, 225, 80, 0.1);
  border-radius: 4px;
}

.leftbar-unit-header button:hover img {
  filter: invert(83%) sepia(32%) saturate(638%) hue-rotate(332deg)
    brightness(103%) contrast(96%); /* #fae150 색상으로 변경 */
  opacity: 1;
  transform: scale(1.1);
}

/* 기존의 opacity 관련 스타일 제거 */
.leftbar-unit-header button img {
  opacity: 0.6; /* 이 스타일 제거 */
}

.leftbar-unit-header button:hover img {
  opacity: 1; /* 이 스타일 제거 */
}

/* 서브유닛 스타일 수정 */
.subunit-list {
  margin-left: 24px; /* 들여쓰기만 약간 증가 */
  position: relative;
}

.leftbar-subunit {
  padding: 6px 10px;
  margin: 4px 0;
  font-size: 12px;
  color: #b0b0b0;
  background-color: #1a1a1a;
  border-radius: 3px;
}

.leftbar-contextmenu {
  position: fixed;
  background-color: #2c2c2c;
  border: 1px solid #404040;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  min-width: 80px;
  width: fit-content;
}

.leftbar-contextmenu button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 10px 12px;
  margin: 2px 0;
  border: none;
  background-color: transparent;
  border-radius: 8px;
  color: var(--dark-text-secondary);
  font-size: 13px;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  line-height: 1;
}

.leftbar-contextmenu button:hover {
  background-color: var(--dark-surface) !important;
  color: var(--accent-yellow) !important;
}

.unit-container {
  display: flex;
  flex-direction: column;
}

.leftbar-unit-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--your-background-color);
}

.subunit-list-wrapper {
  max-height: 240px; /* 6개 서브유닛이 보이는 높이 */
  overflow-y: auto;
  transition: max-height 0.3s ease;
  position: relative;
}

.subunit-list-wrapper.expanded {
  max-height: 1000px; /* 충분히 큰 값 */
}

.expand-toggle-button {
  width: 100%;
  padding: 4px;
  background: #252525;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.expand-toggle-icon {
  width: 14px;
  height: 14px;
  filter: invert(0.7);
  transition: transform 0.2s ease;
}

.expand-toggle-button:hover .expand-toggle-icon {
  filter: invert(83%) sepia(32%) saturate(638%) hue-rotate(332deg)
    brightness(103%) contrast(96%);
}

/* 서브유닛 리스트의 스크롤바 스타일링 (선택사항) */
.subunit-list-wrapper::-webkit-scrollbar {
  /* width: 6px;*/
}

.subunit-list-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
