body {
  /*  background-color: #fff;*/
  margin: 0;
  padding: 0;
}

.note-taking-page {
  padding: 2rem;
  margin: 0 auto;
  background-color: #fff;
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
}

h1 {
  text-align: center;
  color: #333;
  margin-bottom: 3rem;
}

.feature-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 3rem;
  border-radius: 10px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feature-image {
  width: 400px;
  height: 300px;
  object-fit: contain;
  border-radius: 8px;
  background-color: #f8f9fa;
}

.feature-description {
  flex: 1;
}

.feature-description h2 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.feature-description p {
  color: #666;
  line-height: 1.6;
}

.logo-link {
  display: flex;
  align-items: center;
  position: absolute;
  top: 2rem;
  left: 2rem;
}

.logo {
  height: 40px;
  width: auto;
  display: block;
}

/* 비디오에 대한 추가 스타일 */
video.feature-image {
  background-color: #f8f9fa; /* 비디오 로딩 전 배경색 */
}

.header {
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 40px;
}

.nav-tabs {
  display: flex;
  gap: 20px;
}

.tab {
  padding: 10px 20px;
  text-decoration: none;
  color: #333;
  border-radius: 20px;
  transition: all 0.3s;
  font-weight: 500;
}

.tab:hover {
  background-color: #f0f0f0;
}

.tab.active {
  background-color: #ffd700; /* 노란색 */
  color: #333; /* 검은색 텍스트 */
}

.tab.active:hover {
  background-color: #ffc700;
}
