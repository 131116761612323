/* 대시보드 페이지의 부모 컨테이너에만 적용 */
.dashboard-page {
  overflow: hidden;
  height: 100vh;
}

.dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
  color: var(--dark-text);
  box-sizing: border-box;
  gap: 20px;
}

.overview {
  width: 100%;
  background-color: #1e1e1e;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
  border: 1px solid #333;
  height: 45%;
  display: flex;
  flex-direction: column;
}

.schedule-todo-container {
  display: flex;
  gap: 20px;
  height: 55%;
  min-height: 0;
}

.schedule {
  flex: 1;
  min-width: 300px;
  background-color: #1e1e1e;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
  border: 1px solid #333;
  overflow: auto;
}

.todo-list {
  flex: 1;
  background-color: #1e1e1e;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
  border: 1px solid #333;
  overflow: auto;
}

.overview,
.schedule {
  padding: 20px;
}

.overview-header {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
}

.overview-header img {
  margin-right: 10px;
}

.overview-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  filter: brightness(0) saturate(100%) invert(93%) sepia(0%) saturate(0%)
    hue-rotate(213deg) brightness(92%) contrast(92%);
}

.course-cards-mini {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-right: 10px;
  flex: 1;
  overflow-y: auto;
}

.course-card-mini {
  width: 100%;
  display: flex;
  background-color: var(--dark-surface);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: background-color 0.2s ease;
  cursor: pointer;
  border: 1px solid var(--dark-border);
  height: 76px;
  text-decoration: none;
  color: inherit;
  min-height: 76px;
  flex-shrink: 0;
}

.course-card-mini:hover {
  background-color: var(--dark-surface-hover);
  transform: translateY(-2px);
  transition: all 0.3s ease;
}

.dashboard-thumbnail-container {
  width: 60px;
  min-width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  background-color: #1e1e1e;
  border-radius: 8px;
  margin: 8px;
}

.dashboard-thumbnail-image {
  width: 50%;
  height: 50%;
  object-fit: contain;
  border-radius: 4px;
  background-color: #1e1e1e;
}

.course-card-middle {
  flex-grow: 1;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
}

.course-name-mini {
  font-size: 0.9rem;
  margin: 0 0 3px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--dark-text);
}

.progress-bar-mini {
  width: 100%;
  height: 4px;
  background-color: var(--dark-border);
  border-radius: 2px;
  margin-top: 4px;
}

.progress-mini {
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(250, 225, 80, 0.5) 0%,
    rgba(245, 208, 32, 0.6) 100%
  );
  border-radius: 2px;
  transition: width 0.5s ease-in-out;
}

.progress-text-mini {
  font-size: 0.75rem;
  color: var(--dark-text-secondary);
  margin-top: 4px;
  display: block;
}

.course-card-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  padding: 10px 8px;
  background-color: #1e1e1e;
  width: 40px;
  align-items: center;
}

.schedule-header,
.todolist-header {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 15px;
  border-bottom: 2px solid #f5f5f5;
}

.schedule-header,
.todolist-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.schedule-header h2,
.todolist-header h2 {
  font-size: 1.5rem;
  margin: 0;
}

.section-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  filter: brightness(0) saturate(100%) invert(93%) sepia(0%) saturate(0%)
    hue-rotate(213deg) brightness(92%) contrast(92%);
}

.dashboard-todo-list {
  max-height: 850px;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* Chrome, Safari용 스크롤바 숨기기 */
.dashboard-todo-list::-webkit-scrollbar {
  display: none;
}

/* hover 시에만 스크롤바 표시 */
.dashboard-todo-list:hover {
  scrollbar-width: thin;
  -ms-overflow-style: auto;
}

.dashboard-todo-list:hover::-webkit-scrollbar {
  display: block;
  width: 6px;
}

.dashboard-todo-list:hover::-webkit-scrollbar-track {
  background: transparent;
}

.dashboard-todo-list:hover::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}

.dashboard-todo-list:hover::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.dashboard-task-list {
  background-color: #1e1e1e;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border: 1px solid #333;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.dashboard-task-list:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.dashboard-task-list h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.dashboard-task-list ul {
  list-style-type: none;
  padding: 0;
}

.dashboard-task-list li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.dashboard-task-list li.completed span {
  text-decoration: line-through;
  color: #666;
}

.dashboard-task-list input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #fae150;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  margin-right: 10px;
}

.dashboard-task-list input[type="checkbox"]:checked {
  background-color: #fae150;
  position: relative;
}

.dashboard-task-list input[type="checkbox"]:checked::after {
  content: "\2714";
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.thumbnail-placeholder-mini {
  width: 100%;
  aspect-ratio: 16/9;
  background-color: #2a2a2a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnail-skeleton {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #2a2a2a 25%, #333 50%, #2a2a2a 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

/* 반응형 미디어 쿼리 */
@media screen and (max-width: 1200px) {
  .course-cards-mini {
    gap: 15px;
    height: auto;
    min-height: 200px;
  }
}

@media screen and (max-width: 900px) {
  .course-cards-mini {
    gap: 12px;
    grid-template-columns: repeat(2, 1fr);
  }

  .schedule-todo-container {
    flex-direction: column;
  }

  .schedule,
  .todo-list {
    width: 100%;
    flex: 1;
    min-height: 0;
  }

  .overview {
    height: 30vh;
  }
}

@media screen and (max-width: 600px) {
  .dashboard {
    padding: 10px;
  }

  .course-cards-mini {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px;
  }

  .overview,
  .schedule,
  .todo-list {
    padding: 15px;
  }

  .overview {
    height: 25vh;
  }

  .course-thumbnail-mini,
  .default-thumbnail-mini {
    height: 60px;
    min-height: 60px;
  }

  .course-name-mini {
    font-size: 11px;
  }

  .progress-text-mini {
    font-size: 9px;
  }
}

/* 스크롤바 기본 숨김 처리 */
.overview,
.schedule,
.todo-list,
.course-cards-mini {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* Webkit 브라우저용 스크롤바 숨김 */
.overview::-webkit-scrollbar,
.schedule::-webkit-scrollbar,
.todo-list::-webkit-scrollbar,
.course-cards-mini::-webkit-scrollbar {
  display: none;
}

/* 호버 시에만 스크롤바 표시 (선택적) */
.overview:hover,
.schedule:hover,
.todo-list:hover {
  scrollbar-width: thin;
}

.overview:hover::-webkit-scrollbar,
.schedule:hover::-webkit-scrollbar,
.todo-list:hover::-webkit-scrollbar {
  display: block;
  width: 6px;
}

.overview:hover::-webkit-scrollbar-track,
.schedule:hover::-webkit-scrollbar-track,
.todo-list:hover::-webkit-scrollbar-track {
  background: transparent;
}

.overview:hover::-webkit-scrollbar-thumb,
.schedule:hover::-webkit-scrollbar-thumb,
.todo-list:hover::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}

.overview:hover::-webkit-scrollbar-thumb:hover,
.schedule:hover::-webkit-scrollbar-thumb:hover,
.todo-list:hover::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* 전체 컨테이너들은 overflow: hidden으로 설정 */
.overview,
.schedule,
.todo-list {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

/* 헤더는 고정 */
.overview-header,
.schedule-header,
.todolist-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #1e1e1e;
}

/* 실제 스크롤되는 컨텐츠 영역 */
.course-cards-mini {
  overflow-y: auto;
  height: calc(100% - 40px);
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.review-units-container {
  padding: 10px;
  height: calc(100% - 60px);
  overflow-y: auto;
}

.review-course-section {
  margin-bottom: 16px;
}

.review-course-name {
  color: #e0e0e0;
  margin-bottom: 8px;
}

.review-unit-item {
  background: #2a2a2a;
  border: 1px solid #333;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 8px;
}

.review-unit-name {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 8px;
  display: block;
}

.review-message {
  color: #888;
  font-size: 0.9rem;
}

.review-message.overdue {
  color: #ff4444;
}

.no-reviews-message {
  color: #888;
  text-align: center;
  padding: 20px;
}

/* 코스명을 위한 스타일 */
.course-card-mini .dashboard-course-name {
  margin: 0;
  font-size: 1rem;
  line-height: 1.4;
  white-space: normal;
  overflow: visible;
  text-overflow: unset;
}

/* 화면 높이가 작아질 때 적용될 스타일 추가 */
@media screen and (max-height: 800px) {
  .course-card-mini {
    min-height: 60px;
  }

  .course-card-left {
    width: 44px;
    min-width: 44px;
    padding: 6px;
    margin: 6px;
  }

  .course-card-middle {
    padding: 6px;
  }

  .course-name-mini {
    font-size: 0.8rem;
    margin: 0 0 2px 0;
  }

  .progress-bar-mini {
    height: 3px;
    margin-top: 2px;
  }

  .progress-text-mini {
    font-size: 0.7rem;
    margin-top: 2px;
  }
}

@media screen and (max-height: 600px) {
  .course-card-mini {
    min-height: 50px;
  }

  .course-card-left {
    width: 36px;
    min-width: 36px;
    padding: 4px;
    margin: 4px;
  }

  .course-card-middle {
    padding: 4px;
  }

  .course-name-mini {
    font-size: 0.75rem;
    margin: 0 0 1px 0;
  }

  .progress-bar-mini {
    height: 2px;
    margin-top: 1px;
  }

  .progress-text-mini {
    font-size: 0.65rem;
    margin-top: 1px;
  }
}

/* 화면 비율에 따른 카드 크기 조절 */
@media screen and (min-width: 1600px) {
  .course-cards-mini {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
  }

  .course-card-mini {
    height: 76px;
  }
}

@media screen and (min-width: 2000px) {
  .dashboard {
    max-width: 2400px;
    margin: 0 auto;
    padding: 40px;
  }

  .overview {
    padding: 30px;
  }
}

/* 초광각 화면 대응 */
@media screen and (aspect-ratio > 21/9) {
  .overview {
    height: auto;
    min-height: 45%;
    max-height: 80vh;
  }

  .course-cards-mini {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
}

.today-units-list {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.course-units-group {
  margin-bottom: 15px;
}

.schedule-course-name {
  font-size: 1.1rem;
  color: #e9ecef;
  margin-bottom: 10px;
  padding-left: 10px;
  border-left: 4px solid;
}

.today-unit-item {
  padding: 10px;
  border-radius: 8px;
  margin: 15px 0px;
  background-color: #2a2a2a;
  border: 1px solid #404040;
  text-decoration: none;
  color: inherit;
  display: block;
  transition: all 0.2s ease;
}

.today-unit-item:hover {
  background-color: #333333;
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.today-unit-item h4 {
  color: #e9ecef;
  font-weight: 500;
}

.subunit-item {
  color: #adb5bd;
  font-size: 0.95rem;
}
