.login-container {
  display: flex;
  min-height: 100vh;
  width: 100%;
  background-color: #feefd8;
}

.login-image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #feefd8;
  padding: 2rem;
}

.login-image img {
  max-width: 80%;
  max-height: 60vh;
  object-fit: contain;
}

.login-form-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #feefd8;
}

.login-box {
  width: 100%;
  max-width: 400px;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.login-title {
  tolor: #414042;
  font-size: 32px;
  font-family: "Dela Gothic One";
  line-height: 42px;
  margin-bottom: 2rem;
  text-align: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #37352f;
}

.input-group input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #e0e0e0;

  font-size: 14px;
  color: #37352f;
  color: #424242;
  font-size: 14px;
  font-family: "Montserrat";
}

.input-group input:focus {
  border-color: #0066ff;
}

.login-button {
  background-color: #2ecc71;
  color: white;
  border: none;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.login-button:hover {
  background-color: #27ae60;
}

.login-options {
  margin-top: 20px;
  text-align: center;
}

.forgot-password {
  display: block;
  margin-bottom: 10px;
  color: #414042;
  text-decoration: none;
}

.signup-prompt {
  margin: 0;
}

.signup-prompt a {
  color: #faaf3d;
  text-decoration: none;
  font-weight: bold;
}

.password-input-container {
  position: relative;
}

.toggle-password {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.toggle-password img {
  width: 20px;
  height: 20px;
}

.error-message {
  color: #ff4d4f;
  margin-top: 10px;
}

input:focus {
  outline: none;
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

@media (max-width: 768px) {
  .login-box {
    width: 90%;
    max-width: 400px;
  }
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #231c16;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  position: relative;
}

.loading-spinner:before,
.loading-spinner:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  animation: pulse 1.8s ease-in-out infinite;
}

.loading-spinner:before {
  width: 100%;
  height: 100%;
  background-color: rgba(250, 225, 80, 0.5); /* fae150 with opacity */
  animation-delay: -0.9s;
}

.loading-spinner:after {
  width: 75%;
  height: 75%;
  background-color: #fae150;
  top: 12.5%;
  left: 12.5%;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1);
    opacity: 0.3;
  }
}

.keep-logged-in-container {
  margin-top: 15px;
  margin-bottom: 20px;
}

.keep-logged-in-label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;
}

.keep-logged-in-checkbox {
  appearance: none;
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #231c16;
  border-radius: 4px;
  margin: 0;
  cursor: pointer;
  position: relative;
  background-color: white;
}

.keep-logged-in-checkbox:checked {
  background-color: #fae150;
  border-color: #231c16;
}

.keep-logged-in-checkbox:checked::after {
  content: "✓";
  position: absolute;
  color: #231c16;
  font-size: 14px;
  left: 2px;
  top: -1px;
}

.keep-logged-in-text {
  font-size: 14px;
  color: #231c16;
}

.keep-logged-in-warning {
  font-size: 12px;
  color: #666;
  margin-top: 4px;
  margin-bottom: 0;
  padding-left: 26px;
  color: #ff6b6b;
}

.social-login {
  margin-bottom: 20px;
}

.google-login-button {
  width: 100%;
  padding: 10px;
  border: 1px solid #231c16;
  border-radius: 24px;
  background-color: white;
  color: #231c16;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: background-color 0.2s ease;
}

.google-login-button:hover {
  background-color: #f5f5f5;
}

.google-login-button img {
  width: 20px;
  height: 20px;
}

.divider {
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.divider::before,
.divider::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 45%;
  height: 1px;
  background-color: #e0e0e0;
}

.divider::before {
  left: 0;
}

.divider::after {
  right: 0;
}

.divider span {
  background-color: white;
  padding: 0 10px;
  color: #666;
  font-size: 14px;
}
