.reminder-items,
.completed-items {
  list-style-type: none;
  padding: 0;
}

.reminder-item,
.completed-item {
  margin-bottom: 10px;
}

.reminder-main {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  min-height: 32px;
}

.time-setting {
  display: block;
  margin-bottom: 10px;
}

.preset-times {
  display: flex;
  gap: 8px;
  margin-top: 12px;
  flex-wrap: wrap;
  padding: 0 4px;
}

.completed-item label {
  text-decoration: line-through;
}

.reminder-card,
.completed-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.reminder-text,
.completed-text {
  word-wrap: break-word;
  white-space: pre-wrap;
  margin-left: 8px;
  margin-right: 40px;
}

.ReminderList,
.completed-items {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.reminder-main {
  display: flex;
  align-items: flex-start;
}

.ReminderList h4,
.MemorizeDone h4 {
  margin: 24px 0 16px 0;
  font-size: 16px;
  color: #333;
  font-weight: 600;
  border-bottom: 2px solid #eee;
  padding-bottom: 8px;
}

.reminder-card {
  /* 기존 스타일 */
  transition: background-color 0.3s ease;
}

.reminder-card-scheduled {
  background-color: #fffbe6; /* 연한 노랑색 */
}

.preset-button {
  padding: 6px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  font-size: 13px;
  color: #666;
  background-color: #f8f8f8;
  cursor: pointer;
  transition: all 0.2s ease;
}

.preset-button:hover {
  background-color: #f0f0f0;
  border-color: #d0d0d0;
}

.preset-button.selected {
  background-color: #fae150;
  border-color: #fae150;
  color: #231c16;
}

.add-reminder-form {
  display: flex;
  margin: 16px 0;
  gap: 8px;
}

.add-reminder-input {
  flex: 1;
  padding: 10px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.2s ease;
}

.add-reminder-input:focus {
  border-color: #fae150;
  outline: none;
  box-shadow: 0 0 0 2px rgba(250, 225, 80, 0.2);
}

.add-reminder-button {
  padding: 10px 20px;
  background-color: #fae150;
  color: #231c16;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.add-reminder-button:hover {
  background-color: #fae150;
}

.add-reminder-input:focus {
  outline: none;
  box-shadow: none; /* 파란색 아웃라인 제거 */
  border-color: #fae150 !important; /* 포커스 시 테두리 색상 유지 */
}

.completed-main {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  margin-left: auto;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}

.delete-button:hover {
  opacity: 1;
}

.delete-icon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.button-group {
  display: flex;
  gap: 4px;
  margin-left: auto;
}

.button-group .more-button {
  padding: 4px;
}

/* 특정성을 높여서 button[type="submit"]보다 우선순위를 높임 */
.add-reminder-form button[type="submit"].add-reminder-button {
  width: 32px !important;
  height: 32px !important;
  background-color: transparent !important;
  color: #fae150 !important;
  border: 1px solid #fae150 !important;
  border-radius: 4px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-reminder-form button[type="submit"].add-reminder-button:hover {
  background-color: #f5dc4c;
}

.more-button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 8px;
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.2s ease;
}

.more-button:hover {
  opacity: 1;
}

.more-icon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

/* 체크박스 스타일 개선 */
input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin: 0;
  cursor: pointer;
}

/* 카운트다운 텍스트 스타일 */
.countdown-text {
  margin-top: 8px;
  font-size: 13px;
  color: #666;
  padding-left: 4px;
}

/* 완료된 항목 스타일 */
.completed-card {
  background-color: #f8f8f8;
  opacity: 0.8;
}

.completed-text {
  text-decoration: line-through;
  color: #888;
}

/* 기존 스타일 유지하면서 다크모드 스타일 추가 */
.flyout-panel {
  background-color: #1a1a1a !important;
  color: #e0e0e0 !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.flyout-header {
  padding: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #e0e0e0;
}

.flyout-header h3 {
  color: #e0e0e0;
  margin: 0;
  font-size: 16px;
}

.add-reminder-form {
  padding: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.add-reminder-input {
  background-color: #2a2a2a !important;
  border: 1px solid #404040 !important;
  color: #e0e0e0 !important;
  padding: 8px 12px;
  border-radius: 6px;
}

.add-reminder-input:focus {
  border-color: #fae150 !important;
  box-shadow: 0 0 0 2px rgba(250, 225, 80, 0.1);
}

.add-reminder-input::placeholder {
  color: #808080;
}

.add-reminder-button {
  width: 36px !important;
  height: 36px !important;
  min-width: 24px !important;
  background-color: transparent !important;
  color: #fae150 !important;
  border: 1px solid #fae150 !important;
  border-radius: 4px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0;
  transition: all 0.2s ease;
}

.add-reminder-button:hover {
  background-color: #f5dc4c !important;
}

.reminder-card {
  background-color: #2a2a2a !important;
  border: 1px solid #404040 !important;
  margin: 16px !important;
  border-radius: 8px !important;
  padding: 16px !important;
}

.reminder-card h4 {
  color: #e0e0e0 !important;
  margin-top: 0 !important;
  margin-bottom: 12px !important;
  font-size: 14px !important;
}

.reminder-text {
  color: #e0e0e0 !important;
}

.completed-text {
  color: #808080 !important;
  text-decoration: line-through !important;
}

.reminder-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reminder-item {
  margin-bottom: 8px;
}

.reminder-main {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* 체크박스 스타일링 */
.reminder-main input[type="checkbox"] {
  width: 18px;
  height: 18px;
  border: 2px solid #404040;
  border-radius: 4px;
  cursor: pointer;
}

.reminder-main input[type="checkbox"]:checked {
  background-color: #fae150;
  border-color: #fae150;
}

/* 스크롤바 스타일링 */
.flyout-content {
  scrollbar-width: thin;
  scrollbar-color: #404040 #1a1a1a;
}

.flyout-content::-webkit-scrollbar {
  width: 8px;
}

.flyout-content::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.flyout-content::-webkit-scrollbar-thumb {
  background-color: #404040;
  border-radius: 4px;
}

.flyout-content::-webkit-scrollbar-thumb:hover {
  background-color: #505050;
}

/* 닫기 버튼 스타일링 */
.close-button {
  background: none !important;
  border: none !important;
  padding: 8px !important;
  cursor: pointer !important;
  opacity: 0.8 !important;
  transition: opacity 0.2s ease !important;
}

.close-button:hover {
  opacity: 1 !important;
}

.close-button svg {
  fill: #e0e0e0 !important;
}

/* 기존 CSS에 추가 */
.reminder-card .add-reminder-form {
  border-bottom: none;
  margin-top: 12px;
  padding: 0;
}

.reminder-card .add-reminder-input {
  font-size: 13px;
  height: 32px;
}

.reminder-card .add-reminder-button {
  height: 32px;
  padding: 0 12px !important;
  font-size: 13px;
}

/* 체크박스 스타일 수정 */
.reminder-main input[type="checkbox"] {
  width: 16px !important;
  height: 16px !important;
  min-width: 16px !important;
  min-height: 16px !important;
  border: 2px solid #404040 !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  margin: 0 !important;
  padding: 0 !important;
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-color: transparent !important;
  position: relative !important;
}

.reminder-main input[type="checkbox"]:checked {
  background-color: #fae150 !important;
  border-color: #fae150 !important;
}

/* 체크 표시 추가 */
.reminder-main input[type="checkbox"]:checked::after {
  content: "" !important;
  position: absolute !important;
  left: 4px !important;
  top: 1px !important;
  width: 4px !important;
  height: 8px !important;
  border: solid #000 !important;
  border-width: 0 2px 2px 0 !important;
  transform: rotate(45deg) !important;
}

/* 체크박스와 텍스트 간격 조정 */
.reminder-main {
  display: flex !important;
  align-items: center !important;
  gap: 12px !important;
}

/* FlyoutMenu6.css에 추가 */
.add-list-input {
  padding: 8px;
  margin-right: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  flex: 1;
}

.add-list-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* CalendarTodoSidebar.css에 추가 */
.cal-todo-list-form {
  padding: 10px;
  display: flex;
  gap: 8px;
  border-bottom: 1px solid #eee;
}

.cal-todo-list-input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  flex: 1;
}

.cal-todo-list-add-btn {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.list-form {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px;
  margin: 0 16px; /* 좌우 여백 추가 */
  border-bottom: 1px solid var(--border-color);
  margin-top: 20px;
  margin-bottom: 20px;
}

/* 리마인더 카드 스타일 */
.reminder-card {
  background-color: #2a2a2a !important;
  border: 1px solid #404040 !important;
  margin: 16px !important;
  border-radius: 8px !important;
  padding: 16px !important;
}
