/* src/styles.css */
:root {
  --dark-bg: #000000;
  background-color: var(--dark-bg);
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: var(--dark-bg);
}

.layout {
  display: flex;
  height: 100vh; /* 부모 요소의 높이를 설정 */
  overflow: hidden; /* 레이아웃 자체의 스크롤을 방지 */
}

.content {
  flex: 1;
  padding: 20px;
  background-color: var(--dark-bg);
  overflow-y: auto;
}

.left-bar {
  grid-area: left-bar;
  overflow-y: auto; /* 독립적인 스크롤 컨테이너로 만듦 */
  max-height: 100vh; /* 최대 높이 설정 */
}

.main-content {
  grid-area: main-content;
  overflow-y: auto; /* 독립적인 스크롤 컨테이너로 만듦 */
  background-color: #fff;
  padding: 20px;
  background-size: cover; /* 이미지 크기를 조정 */
  background-position: center; /* 이미지 위치를 중앙으로 설정 */
  background-repeat: no-repeat; /* 이미지 반복을 방지 */
  max-height: 100vh; /* 최대 높이 설정 */
  margin-left: 30px;
}

.back-icon {
  position: absolute;
  left: 10px;
  width: 24px; /* 아이콘 크기 조정 */
  height: 24px; /* 아이콘 크기 조정 */
  cursor: pointer;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}
/* styles.css에 추가 */
.content {
  transition: all 0.3s ease-in-out;
}

.main-content {
  transition: background-image 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
