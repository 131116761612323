.search-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6000;
}

.search-modal {
  background-color: #1a1a1a;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  padding: 20px;
  overflow-y: auto;
}

.search-modal-header {
  padding: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #e0e0e0;
}

.search-modal-header button {
  background: none;
  border: none;
  color: #e0e0e0;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
}

.search-modal-header button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.search-modal-content {
  padding: 16px;
  overflow-y: auto;
  color: #e0e0e0;
}

.search-result-item {
  padding: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.search-result-item:hover {
  background-color: #252525;
}

.search-result-location {
  font-size: 12px;
  color: #888;
}

.no-results {
  text-align: center;
  color: #888;
}

.search-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6000;
}

.search-modal {
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 6001;
}

/* 스크롤바 스타일링 */
.search-modal-content::-webkit-scrollbar {
  width: 8px;
}

.search-modal-content::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.search-modal-content::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 4px;
}

.search-modal-content::-webkit-scrollbar-thumb:hover {
  background: #444;
}
