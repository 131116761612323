.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  position: relative;
}

.loading-spinner:before,
.loading-spinner:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  animation: pulse 1.8s ease-in-out infinite;
}

.loading-spinner:before {
  width: 100%;
  height: 100%;
  background-color: rgba(250, 225, 80, 0.5);
  animation-delay: -0.9s;
}

.loading-spinner:after {
  width: 75%;
  height: 75%;
  background-color: #fae150;
  top: 12.5%;
  left: 12.5%;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1);
    opacity: 0.3;
  }
}

.session-timeout-message {
  text-align: center;
  color: #666;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.session-timeout-message p {
  margin-bottom: 15px;
  font-size: 16px;
}
