/* 폰트 선언 - toolbar.css 최상단에 추가 */
@font-face {
  font-family: "THEGaeideuk";
  src: url("../assets/fonts/THEGaeideuk.woff") format("woff"),
    url("../assets/fonts/THEGaeideuk.ttf") format("truetype");
  font-display: swap;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "TTAgainSea";
  src: url("../assets/fonts/TTAgainSea.woff") format("woff"),
    url("../assets/fonts/TTAgainSea.ttf") format("truetype");
  font-display: swap;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "TTAPleasantDay";
  src: url("../assets/fonts/TTAPleasantDay.woff") format("woff"),
    url("../assets/fonts/TTAPleasantDay.ttf") format("truetype");
  font-display: swap;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "TTARainyDayBK";
  src: url("../assets/fonts/TTARainyDayBK.woff") format("woff"),
    url("../assets/fonts/TTARainyDayBK.ttf") format("truetype");
  font-display: swap;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "TTEveryDayThank";
  src: url("../assets/fonts/TTEveryDayThank.woff") format("woff"),
    url("../assets/fonts/TTEveryDayThank.ttf") format("truetype");
  font-display: swap;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "TTFlowerRoad";
  src: url("../assets/fonts/TTFlowerRoad.woff") format("woff"),
    url("../assets/fonts/TTFlowerRoad.ttf") format("truetype");
  font-display: swap;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "TTJustGift";
  src: url("../assets/fonts/TTJustGift.woff") format("woff"),
    url("../assets/fonts/TTJustGift.ttf") format("truetype");
  font-display: swap;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "TTLovelySSong";
  src: url("../assets/fonts/TTLovelySSong.woff") format("woff"),
    url("../assets/fonts/TTLovelySSong.ttf") format("truetype");
  font-display: swap;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "TTOhilyeoJoha";
  src: url("../assets/fonts/TTOhilyeoJoha.woff") format("woff"),
    url("../assets/fonts/TTOhilyeoJoha.ttf") format("truetype");
  font-display: swap;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "TTOnABetterDay";
  src: url("../assets/fonts/TTOnABetterDay.woff") format("woff"),
    url("../assets/fonts/TTOnABetterDay.ttf") format("truetype");
  font-display: swap;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "TTPenguinHeart";
  src: url("../assets/fonts/TTPenguinHeart.woff") format("woff"),
    url("../assets/fonts/TTPenguinHeart.ttf") format("truetype");
  font-display: swap;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "TTPenWorkBook";
  src: url("../assets/fonts/TTPenWorkBook.woff") format("woff"),
    url("../assets/fonts/TTPenWorkBook.ttf") format("truetype");
  font-display: swap;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "TTPrincessLike";
  src: url("../assets/fonts/TTPrincessLike.woff") format("woff"),
    url("../assets/fonts/TTPrincessLike.ttf") format("truetype");
  font-display: swap;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "TTSpringSun";
  src: url("../assets/fonts/TTSpringSun.woff") format("woff"),
    url("../assets/fonts/TTSpringSun.ttf") format("truetype");
  font-display: swap;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "TTTodayGulimM";
  src: url("../assets/fonts/TTTodayGulimM.woff") format("woff"),
    url("../assets/fonts/TTTodayGulimM.ttf") format("truetype");
  font-display: swap;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "TTWantToBeHappy";
  src: url("../assets/fonts/TTWantToBeHappy.woff") format("woff"),
    url("../assets/fonts/TTWantToBeHappy.ttf") format("truetype");
  font-display: swap;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  font-weight: 300;
  font-style: normal;
}

/* Windows 특정 스타일 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  * {
    text-rendering: geometricPrecision; /* optimizeLegibility 대신 */
    -webkit-font-smoothing: subpixel-antialiased; /* antialiased 대신 */
    font-weight: 300 !important; /* 폰트 두께 강제 조정 */
  }
}

/* Chrome/Edge for Windows */
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  * {
    letter-spacing: -0.02em; /* 자간 미세 조정 */
  }
}

.toolbar {
  position: fixed;
  top: 60px;
  left: 0;
  width: calc(100% - 30px);
  height: 50px;
  background-color: #1e1e1e;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  z-index: 3500;
  overflow: visible;
  border-bottom: 1px solid #333333;
  border-top: 1px solid #333333;
  background: linear-gradient(to bottom, #252525, #1e1e1e);
  min-width: fit-content;
}

.toolbar-left {
  flex: 0 0 auto;
  margin-right: 20px;
  min-width: fit-content;
}

.toolbar-center {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding-left: 10px;
  margin: 0 20px;
  max-width: calc(100% - 40px);
  min-width: 0;
  width: 100%;
}

.toolbar-center > * {
  flex: 0 0 auto;
  margin-right: 10px;
  height: 36px;
  white-space: nowrap;
  min-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 스크롤바 스타일링 */
.toolbar-center::-webkit-scrollbar {
  height: 4px;
}

.toolbar-center::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.toolbar-center::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 2px;
}

.toolbar-center::-webkit-scrollbar-thumb:hover {
  background: #444;
}

/* 버튼 스타일 조정 */
.toolbar button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  margin: 0 5px;
  padding: 5px 10px;
  background-color: #1e1e1e;
  color: #e1e1e1;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.2s ease, border-color 0.2s ease;
  vertical-align: middle;
}

/* 버튼 내 이미지 크기 조정 */
.toolbar button img {
  width: 20px;
  height: 20px;
  display: block;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%)
    contrast(100%) !important;
}

/* 그룹 스타일 조정 */
.stamp-buttons,
.eraser-size-control,
.color-options {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}
/* 불필요한 마진 제거 */
.eraser-size-control,
.color-options {
  margin: 0;
}

.eraser-size-control input[type="range"] {
  width: 60px;
}

.back-button {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: #ffffff;
  color: #37352f;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-right: 20px; /* 추가: 다른 버튼들과의 간격 */
}

.back-button img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.back-button span {
  font-size: 14px;
}

.back-button:hover {
  background-color: #f7f6f3;
}

.toolbar button {
  margin: 0 5px;
  padding: 5px 10px;
  background-color: #1e1e1e;
  color: #e1e1e1;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

.toolbar button:hover {
  background-color: #404040;
  color: #e0e0e0;
}

.toolbar button.active {
  border: 2px solid #606060;
  box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.1);
}

.toolbar select,
.toolbar input[type="color"] {
  margin: 0 5px;
  padding: 5px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
}

.toolbar-icon {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%)
    contrast(100%);
}
.toolbar-text {
  margin-left: 8px !important;
}
.movable-textbox {
  position: absolute;
  min-width: 100px;
  min-height: 40px; /* 24px에서 40px로 증가 */
  padding: 10px;
  background-color: transparent;
  border: 1px solid transparent;
  z-index: 100;
  cursor: move;
  white-space: pre-wrap;
}

.movable-textbox.editing {
  cursor: text;
  border: 2px solid #fae150; /* 여기서 편집 모드의 테두리 색상이 정의되어 있네요 */
  border-radius: 4px;
  user-select: text;
}

.movable-textbox:hover:not(.editing) {
  border: 1px solid rgba(223, 185, 109, 0.3);
  border-radius: 4px;
}

.movable-textbox.selected {
  border-color: #dfb96d;
  box-shadow: 0 0 5px rgba(223, 185, 109, 0.5);
}

.movable-textbox:focus {
  outline: 2px solid #fae150;
}

.movable-textbox .resize-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #dfb96d;
  border-radius: 50%;
  z-index: 1;
  display: none; /* 기본적으로 숨김 */
}

.movable-textbox .resize-handle-nw {
  top: -5px;
  left: -5px;
  cursor: nw-resize;
}

.movable-textbox .resize-handle-ne {
  top: -5px;
  right: -5px;
  cursor: ne-resize;
}

.movable-textbox .resize-handle-sw {
  bottom: -5px;
  left: -5px;
  cursor: sw-resize;
}

.movable-textbox .resize-handle-se {
  bottom: -5px;
  right: -5px;
  cursor: se-resize;
}

.movable-textbox:hover .resize-handle,
.movable-textbox:focus .resize-handle,
.movable-textbox.selected .resize-handle {
  display: block;
}

.resize-handle:hover {
  background-color: #fae150;
  transform: scale(1.2);
  transition: all 0.2s ease;
}

.delete-textbox {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  border: none;
  background: none;
  cursor: pointer;
  display: none;
  z-index: 101; /* 텍스트박스보다 위에 표시 */
}

/* hover 상태일 때 삭제 버튼 표시 */
.movable-textbox:hover .delete-textbox {
  display: block;
}

/* 빈 텍스트박스 스타일 */
.movable-textbox:empty::before {
  content: " ";
  display: inline-block;
  min-width: 1px;
  min-height: 1em;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* 스타  CSS */

.pastelOrangeHighlighter {
  background-color: rgba(255, 204, 153, 0.5) !important;
  border: none !important;
}

.redPencil {
  border: 2px solid #ff6b6b !important;
  box-shadow: 0 1px 1px rgba(255, 107, 107, 0.5) !important;
  background: none !important;
}

.drawn-line.pastelOrangeHighlighter,
.preview-line.pastelOrangeHighlighter {
  background-color: rgba(255, 204, 153, 0.5);
  height: 10px;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}

.stamp-buttons,
.drawing-buttons {
  display: flex;
  gap: 5px;
}

.recording-info {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.eraser-size-control {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.eraser-size-control input[type="range"] {
  width: 100px;
  margin-right: 5px;
}

.eraser-size-control span {
  font-size: 12px;
}

.pen-options {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1a1a1a;
  border: 1px solid #2d2d2d;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  z-index: 1001;
  min-width: 280px;
  height: 40px;
}

.pen-size-control {
  display: flex;
  align-items: center;
  gap: 6px;
}

.pen-size-control input[type="range"] {
  width: 60px;
  height: 2px;
  -webkit-appearance: none;
  background: #333;
  border-radius: 1px;
  outline: none;
}

.pen-size-control input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
  background: #fae150;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.15s ease;
}

.pen-size-control input[type="range"]::-webkit-slider-thumb:hover {
  transform: scale(1.1);
  box-shadow: 0 0 4px rgba(250, 225, 80, 0.4);
}

.pen-size-preview {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #444;
  transition: all 0.15s ease;
}

.color-options {
  display: flex;
  gap: 3px;
  padding: 2px;
  background-color: #222;
  border-radius: 3px;
}

.color-options button {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid transparent !important;
  transition: transform 0.15s ease;
  padding: 0;
}

.color-options button:hover {
  transform: scale(1.1);
}

.color-options button.active {
  border: 1px solid #fae150 !important;
  box-shadow: 0 0 4px rgba(250, 225, 80, 0.4);
}

.color-options button[style*="background-color: #FFFFFF"] {
  border: 1px solid #333 !important;
}

.text-options-toolbar {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px;
  background-color: #2d2d2d;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #404040;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.text-options-toolbar button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px; /* 버튼의 너비 지정 */
  height: 30px; /* 버튼의 높이 지정 */
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.text-options-toolbar button:hover {
  background-color: #f7f6f3;
}

.text-options-toolbar button img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.palette-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.palette-button {
  background-color: #f0f0f0;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  padding: 3px 8px;
  margin: 0 2px;
  cursor: pointer;
  font-size: 12px;
}

.palette-button.active {
  background-color: #e0e0e0;
  font-weight: bold;
}

.highlight-colors {
  display: flex;
  gap: 5px;
  justify-content: center;
}

.highlight-color-button {
  width: 20px;
  height: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.highlight-color-button:hover {
  transform: scale(1.1);
}

.text-color-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
  margin: 2px;
  cursor: pointer;
}

.text-color-button:hover {
  transform: scale(1.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.text-colors {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
  width: 320px;
  max-height: 180px; /* 6행 높이에 맞춤 */
  overflow-y: auto; /* 세로 스크롤 추가 */
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px;
  position: absolute;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

/* 스크롤바 스타일링 (선택사항) */
.text-colors::-webkit-scrollbar {
  width: 8px;
}

.text-colors::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.text-colors::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.text-colors::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.review-mode-options {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #2d2d2d;
  border: 1px solid #404040;
  border-radius: 3px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  z-index: 3500; /* Leftbar(2000)보다 높게 설정 */
  min-width: 400px;
  height: 60px;
  overflow-x: auto;
  white-space: nowrap;
}

.review-mode-options button {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 5px 10px;
  font-size: 12px;
}

.review-mode-options button img {
  width: 20px;
  height: 20px;
}

.stamp-options {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #2d2d2d;
  border: 1px solid #404040;
  border-radius: 3px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  z-index: 1001;
  height: 60px;
}

.stamp-options button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: transparent;
  border: 1px solid #404040;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.stamp-options button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.stamp-options button img {
  width: 30px;
  height: 30px;
  filter: none !important;
}

.stamp img {
  filter: none !important;
}

.color-option {
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.color-option:hover {
  transform: scale(1.1);
}

.pen-size-control {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.pen-size-control input[type="range"] {
  width: 100px;
  margin-right: 10px;
}

.pen-size-preview {
  display: inline-block;
  vertical-align: middle;
}

.stamp-options button img {
  width: 24px;
  height: 24px;
}

.review-mode-options button {
  display: flex;
  align-items: center;
  gap: 8px;
}

.review-mode-options button img {
  width: 24px;
  height: 24px;
}

.review-mode-options button span {
  font-size: 14px;
}

.review-mode-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px; /* 이미지와 텍스트 사이 간격 */
  height: 36px;
  padding: 0 10px;
}

.review-mode-button img {
  width: 20px;
  height: 20px;
}

.review-mode-button span {
  font-size: 14px;
}

.toolbar button:hover {
  background-color: #404040;
  color: #e0e0e0;
}

.toolbar button.active {
  border-color: #37352f;
  font-weight: bold;
  color: #e0e0e0;
}

.toolbar select,
.toolbar input[type="color"] {
  margin: 0 5px;
  padding: 5px;
  background-color: #ffffff;
  color: #37352f;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
}

.toolbar select:hover,
.toolbar input[type="color"]:hover {
  background-color: #f7f6f3;
}

.toolbar select:focus,
.toolbar input[type="color"]:focus {
  outline: none;
  border-color: #37352f;
}

.toolbar .color-picker {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  cursor: pointer;
  background-color: #ffffff;
}

.toolbar .color-picker:hover {
  background-color: #f7f6f3;
}

.toolbar .color-picker:focus {
  outline: none;
  border-color: #37352f;
}

.toolbar .color-picker-popup {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2000;
  animation: fadeInOut 2s ease-in-out;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  10%,
  90% {
    opacity: 1;
  }
}

.drawing-mode,
.pen-mode {
  cursor: url("../assets/icons/cursor-pen.svg") 1 1, auto !important;
}

body.drawing-mode {
  cursor: url("../assets/icons/cursor-pen.svg") 1 1, auto !important;
}

body.drawing-mode .ql-editor {
  cursor: url("../assets/icons/cursor-pen.svg") 1 1, auto !important;
}

.context-menu {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.context-menu-item {
  padding: 8px 12px;
  cursor: pointer;
}

.context-menu-item:hover {
  background-color: #f0f0f0;
}

.pen-opacity-control {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.pen-opacity-control label {
  margin-right: 10px;
}

.pen-opacity-control input[type="range"] {
  width: 100px;
  margin-right: 10px;
}

.pen-opacity-control span {
  min-width: 40px;
}

.text-color-options {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #2d2d2d;
  border: 1px solid #404040;
  border-radius: 3px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  z-index: 1001;
  min-width: 400px;
  height: 60px;
  overflow-x: auto;
  white-space: nowrap;
}

.resize-handle:hover {
  background-color: #dfb96d;
}
.resize-handle {
  position: absolute;
  right: -3px;
  top: 0;
  bottom: 0;
  width: 6px;
  cursor: col-resize;
  z-index: 1;
  display: none;
}

.movable-textbox:hover .resize-handle,
.movable-textbox:focus .resize-handle {
  display: block;
}

.resize-handle:hover {
  background-color: #dfb96d;
}

.toolbar button.remove-split-active {
  background-color: #e0e0e0;
  border: 2px solid #37352f;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.text-color-options button img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  filter: none !important;
}

.moveable-control.moveable-origin {
  display: none !important;
}

.review-mode-options button {
  display: flex;
  align-items: center;
  gap: 8px;
}

.review-mode-options button img {
  width: 24px;
  height: 24px;
}

.review-mode-options button span {
  font-size: 14px;
}

.review-mode-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.review-mode-button img {
  width: 24px;
  height: 24px;
}

.review-mode-button span {
  font-size: 14px;
}

.font-selector {
  position: relative;
  z-index: 1000;
}

.font-selector > button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* 툴팁 관련 스타일 추가 */
.tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  pointer-events: none;
  z-index: 9999; /* 높은 z-index 값 설정 */
  transform: translate(-50%, -100%);
  margin-top: -8px;
}

.tooltip::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}

/* 툴팁을 표시할 버튼에 hover 시 툴팁 표시 */
.toolbar button:hover .tooltip {
  display: block;
}

.toolbar-tooltip {
  position: relative;
  display: inline-block;
  overflow: visible;
}

.toolbar-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 9999;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
  pointer-events: none;
}

.toolbar-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}
