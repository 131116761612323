.pao-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #ffffff !important;
}

.pao-main-title {
  color: #414042 !important;
  font-size: 40px !important;
  font-family: "Dela Gothic One" !important;
  line-height: 52px !important;
  margin-bottom: 0.5rem !important;
  text-align: left !important;
}

/* 피처 섹션 스타일 */
.feature-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 100px 20px;
  background: #fff;
}

.feature-section:nth-child(even) {
  background: #f8f9fa;
}

.feature-content {
  max-width: 800px;
  margin: 0 auto;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.8s ease-out;
}

.feature-content.visible {
  opacity: 1;
  transform: translateY(0);
}

.feature-title {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 24px;
  color: #333;
  font-family: "Dela Gothic One" !important;
}

.feature-cards-grid {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  margin: 40px auto;
  padding: 0 20px;
}

.feature-card {
  flex: 0 1 calc(33.333% - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.feature-card-image {
  width: 120px;
  height: 120px;
  object-fit: contain;
  margin-bottom: 20px;
}

.feature-card-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #000;
  width: 100%;
  min-height: 24px;
}

.feature-card-subtitle {
  font-size: 14px;
  line-height: 1.5;
  color: #666;
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
}

/* 프리뷰 이미지/비디오 스타일 */
.preview-image {
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.preview-video {
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

/* 캐러셀 컨트롤 */
.carousel-controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 12px;
  z-index: 2;
}

.carousel-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0;
}

.carousel-dot:hover {
  background-color: rgba(255, 255, 255, 0.8);
  transform: scale(1.2);
}

.carousel-dot.active {
  background-color: white;
  transform: scale(1.2);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

/* 프라이싱 섹션 스타일 */
.pricing-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 40px;
  gap: 60px;
}

.pricing-image {
  flex: 1;
  max-width: 500px;
}

.pricing-image img {
  width: 100%;
  height: auto;
}

.pricing-cards {
  flex: 1;
  max-width: 500px;
}

.pricing-card {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s ease;
}

.pricing-card.featured {
  border: 2px solid #ffb800;
  transform: scale(1.05);
}

.card-header h3 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

.price {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 4px;
  margin-bottom: 20px;
}

.amount {
  font-size: 48px;
  font-weight: 700;
  color: #000;
}

.period {
  font-size: 16px;
  color: #666;
}

.features-list {
  list-style: none;
  padding: 0;
  margin: 2rem 0;
  text-align: left;
}

.features-list li {
  margin: 1rem 0;
  color: #555;
  font-size: 1rem;
}

.pricing-cta {
  display: inline-block;
  width: 100%;
  padding: 14px 28px;
  background-color: var(--accent-yellow);
  color: var(--dark-bg);
  text-align: center;
  border-radius: 8px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.2s ease;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.trial-note {
  margin-top: 12px;
  color: #666;
  font-size: 0.9rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

/* 반응형 디자인 */
@media (max-width: 968px) {
  .pricing-section {
    flex-direction: column;
    padding: 40px 20px;
  }

  .pricing-image {
    max-width: 400px;
    margin-bottom: 40px;
  }

  .feature-cards-grid {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  .feature-card {
    flex: none;
    width: 100%;
    max-width: 300px;
  }
}

@media (max-width: 768px) {
  .feature-section {
    padding: 60px 20px;
  }

  .feature-title {
    font-size: 36px;
  }
}

footer.business-info {
  background-color: #faaf3d;
  color: #414042;
  padding: 2rem 0;
  margin-top: auto;
}
