.bottom-bar {
  background-color: #1e1e1e;
  color: #e0e0e0;
  padding: 8px 16px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1);
}

.zoom-controls {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.zoom-controls button {
  background-color: transparent;
  color: #e0e0e0;
  border: none;
  border-radius: 3px;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.1s ease;
}

.zoom-controls button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.timer-container {
  display: flex;
  align-items: center;
}

.pomodoro-timer {
  display: flex;
  align-items: center;
}

.timer-display {
  font-size: 14px;
  margin-right: 8px;
  font-weight: 500;
  color: #e0e0e0;
}

.timer-controls button,
.timer-setup button {
  margin-right: 4px;
  padding: 4px 8px;
  background-color: #252525;
  color: #e0e0e0;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 5px;
}

.timer-controls button:hover,
.timer-setup button:hover {
  background-color: #333;
  border-color: rgba(250, 225, 80, 0.3);
  color: #fae150;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.timer-setup input {
  width: 40px;
  margin-right: 4px;
  padding: 4px;
  background-color: #2a2a2a;
  color: #e0e0e0;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  font-size: 14px;
}

.timer-setup input:focus {
  outline: none;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 1s infinite;
}

.zoom-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px;
  margin: 0 4px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.zoom-button:hover {
  background-color: #333;
  transform: translateY(-1px);
}

.zoom-button img {
  width: 16px;
  height: 16px;
  filter: invert(1);
  transition: all 0.2s ease;
}

.zoom-button:hover img {
  filter: invert(83%) sepia(32%) saturate(638%) hue-rotate(332deg)
    brightness(103%) contrast(96%);
  transform: scale(1.1);
}

.bottom-bar button.active {
  background-color: rgba(250, 225, 80, 0.15);
  border-color: rgba(250, 225, 80, 0.3);
  color: #fae150;
}

.bottom-bar button.active img {
  filter: invert(83%) sepia(32%) saturate(638%) hue-rotate(332deg)
    brightness(103%) contrast(96%);
}

.bottom-bar button img {
  width: 16px;
  height: 16px;
  filter: invert(1) !important;
  transition: all 0.2s ease;
}

.bottom-bar button:hover img {
  filter: invert(83%) sepia(32%) saturate(638%) hue-rotate(332deg)
    brightness(103%) contrast(96%);
  transform: scale(1.1);
}
