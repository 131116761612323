.flyout-panel {
  position: absolute;
  left: -400px !important;
  width: 400px !important;
  background-color: #1a1a1a !important;
  color: #e0e0e0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol" !important;
  padding: 15px !important;
  top: 0 !important;
  bottom: 0 !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3) !important;
  border-radius: 4px !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.close-button {
  background: none !important;
  border: none !important;
  color: #e0e0e0 !important;
  font-size: 14px !important;
  cursor: pointer !important;
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
  transition: all 0.2s ease !important;
  padding: 8px !important;
  border-radius: 4px !important;
}

.close-button:hover {
  background-color: #252525 !important;
  color: #fae150 !important;
}

.layout1-button {
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 20px;
  color: #e0e0e0;
  font-size: 14px;
  padding: 10px 12px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.layout1-button:hover {
  background-color: #252525;
  border-color: rgba(250, 225, 80, 0.3);
  color: #fae150;
  transform: translateY(-1px);
}

.layout1-button img {
  margin-right: 10px;
}

.icon {
  width: 50px; /* 아이콘의 너비를 설정합니다. */
  height: 50px; /* 아이콘의 높이를 설정합니다. */
}

.layout-warning-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.layout-warning-modal button {
  margin: 0 10px;
  padding: 5px 10px;
}

.layout-description {
  margin-top: 20px;
  padding: 10px;
  background-color: #f7f6f3;
  border-radius: 3px;
}

.layout-description h4 {
  margin-bottom: 10px;
  font-size: 16px;
  color: #37352f;
}

.layout-description p {
  font-size: 14px;
  color: #37352f;
  line-height: 1.5;
}
.flyout-warning {
  background-color: #2a2a2a;
  border: 1px solid #333;
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
}

.flyout-warning p {
  color: #e0e0e0;
  margin-bottom: 16px;
  font-size: 0.9rem;
}

.flyout-warning-confirm,
.flyout-warning-cancel {
  padding: 8px 16px;
  border-radius: 6px;
  border: none;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.flyout-warning-confirm {
  background-color: #fae150;
  color: #000000;
  margin-right: 8px;
}

.flyout-warning-confirm:hover {
  background-color: #fff06b;
}

.flyout-warning-cancel {
  background-color: transparent;
  color: #e0e0e0;
  border: 1px solid #444;
}

.flyout-warning-cancel:hover {
  border-color: #fae150;
  color: #fae150;
}

/* 모든 아이콘에 대한 기본 필터 적용 */
.flyout-panel.flyout-menu1 img,
.layout1-button img,
.icon,
.flyout-menu1 button img {
  filter: invert(0.8) !important; /* 밝은 색상으로 변경 */
  transition: filter 0.2s ease !important;
}

/* 호버 시 아이콘 색상 변경 */
.layout1-button:hover img,
button:hover img {
  filter: invert(83%) sepia(32%) saturate(638%) hue-rotate(332deg)
    brightness(103%) contrast(96%) !important; /* #fae150 색상으로 변경 */
}

/* 활성화된 버튼의 아이콘 색상 */
.layout1-button.active img,
button.active img {
  filter: invert(83%) sepia(32%) saturate(638%) hue-rotate(332deg)
    brightness(103%) contrast(96%) !important;
}
.close-button svg {
  fill: white;
}
