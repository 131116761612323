/* 테이블 스타일링 */
.main-content .another-table-wrapper,
.main-content .another-table-wrapper table,
.main-content .another-table-wrapper td,
.main-content .another-quill-editor,
.main-content .another-quill-editor .ql-editor {
  font-family: var(--editor-font-family) !important;
}

.main-content .another-table-wrapper {
  margin: 0;
  width: 700px;
  height: 900px;
  margin-top: 25px;
  margin-left: 25px;
  overflow: auto;
  padding: 0px !important;
}
.main-content .another-table-wrapper {
  transition: all 0.3s ease-in-out !important;
  z-index: 1;
  position: relative;
}

.main-content .another-table-wrapper table {
  width: 100%;
  height: 900px;
  table-layout: fixed;
  border-collapse: separate !important;
  border-spacing: 0 !important;
  border: none !important;
  border-radius: 0;
}

.main-content .another-table-wrapper tr:first-child td {
  border-top: none !important;
}

.main-content .another-table-wrapper tr:last-child td {
  border-bottom: none !important;
}

.main-content .another-table-wrapper td:first-child {
  border-left: none !important;
}

.main-content .another-table-wrapper td:last-child {
  border-right: none !important;
}

/* 테이블 셀 내부의 모든 요소에 대한 줄바꿈 방지 */
.another-table-cell:after {
  content: none;
  display: none;
}

.main-content .another-table-wrapper td {
  border: 1px solid rgba(224, 224, 224, 0.5);
  box-shadow: none !important;
  vertical-align: middle;
  position: relative !important;
  align-items: center !important; /* 추가 */
  padding: 0 !important;

  cursor: default;
}
/*
.main-content .another-table-wrapper td:hover {
  background-color: rgba(250, 225, 80, 0.5) !important;
  transition: background-color 0.2s ease;
}*/

/* 에디터 스타일링 */
.main-content .another-quill-editor {
  height: auto; /* inherit에서 변경 */
  background-color: #ffffff;
  border: none;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  cursor: default;
  z-index: 1;
  padding: 0px;
  overflow-wrap: break-word;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  margin-bottom: 0px !important;
  /* margin-top: 5px;*/
  padding-bottom: 5px;
  /*margin-right: 6px;*/
}

.main-content .another-quill-editor .ql-editor {
  padding: 0px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  margin: 0;
  line-height: 1.5;
  font-size: 14px;
}

/* 미니 툴바 스타일링 */
.another-note-mini-toolbar {
  background-color: #2d2d2d;
  border-radius: 4px;
  padding: 4px 8px;
  display: flex;
  gap: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.another-note-style-btn {
  background: none;
  border: none;
  color: white;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 2px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
}

.another-note-style-btn:hover {
  background-color: #404040;
}

.another-note-color-picker {
  position: relative;
  display: inline-block;
}

/* Quill 에디터 커스텀 스타일링 */
.another-ql-container {
  font-size: 14px;
  height: calc(100% - 10px);
}

.another-ql-editor {
  padding: 8px;
  min-height: 100px;
}

.another-ql-editor p {
  margin: 0;
  line-height: 1.5;
}

.another-color-picker-popup {
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 10000;
}

.another-color-option {
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.another-color-option:hover {
  transform: scale(1.1);
}

.another-note-align-buttons {
  display: inline-flex;
  gap: 2px;
  margin-left: 4px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  padding-left: 4px;
}

.another-note-align-buttons .another-note-style-btn {
  padding: 4px 6px;
}

/* 기존 스타일을 더 구체적으로 지정 */
.another-table-wrapper .ql-editor {
  font-family: var(--editor-font-family) !important;
  padding: 0px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  margin: 0;
  line-height: 1.5;
  font-size: 14px;
}

.another-table-wrapper .ql-editor.ql-blank::before {
  font-style: italic;
  color: #999;
}

.another-table-wrapper .ql-container {
  font-size: 13px;
  height: calc(100% - 10px);
  border: none;
}

.another-quill-editor .ql-editor {
  font-family: var(--editor-font-family) !important;
  padding: 0px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  margin: 0;
  line-height: 1.5;
  font-size: 14px;
}

/* 리사이즈 핸들 스타일링 */
.another-component-v2-resize-handle {
  width: 4px;
  height: 900px;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;
  cursor: col-resize;
  transition: background-color 0.2s;
}

.another-component-v2-resize-handle:hover,
.another-component-v2-resize-handle.dragging {
  background-color: #fff9c4;
}
.editor-divider {
  width: 1px;
  background-color: #e0e0e0;
  cursor: col-resize;
  top: 0px;
  bottom: 0px;
  /* margin: 0 2px;*/
}

/* 레이아웃 1 (기본) */
.main-content .another-table-wrapper.layout-1 td {
  border: 1px solid rgba(224, 224, 224, 0.5);
}

/* 레이아웃 2 */
.main-content .another-table-wrapper.layout-2 td {
  /*border: 1px solid rgba(224, 224, 224, 0.5) !important;*/
}

.main-content .another-table-wrapper.layout-2 tr td:first-child {
  border-top: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
}

/* 레이아웃 3 */
.main-content .another-table-wrapper.layout-3 tr td:last-child {
  border-top: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
}

/* 레이아웃 4 */
.main-content .another-table-wrapper.layout-4 tr td {
  border-top: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
}

/* 레이아웃 5 */
.main-content .another-table-wrapper.layout-5 td {
  border: 1px solid rgba(224, 224, 224, 0.5);
}

.main-content
  .another-table-wrapper.layout-5
  .another-component-v2-resize-handle {
  display: none !important;
}

/* 레이아웃 6 */
.main-content .another-table-wrapper.layout-6 td {
  border-left: 1px solid #e0e0e0 !important;
  border-right: 1px solid #e0e0e0 !important;
  border-top: none !important;
  border-bottom: none !important;
}

.main-content
  .another-table-wrapper.layout-6
  .another-component-v2-resize-handle {
  display: none;
}

/* 레이아웃 7 */
.main-content .another-table-wrapper.layout-7 td {
  border-color: transparent !important;
}

.main-content .another-table-wrapper.layout-7 td:nth-child(2) {
  display: none;
}

.main-content .another-table-wrapper.layout-7 td:first-child {
  width: 100% !important;
}

.main-content
  .another-table-wrapper.layout-7
  .another-component-v2-resize-handle {
  display: none !important;
}

/* 레이아웃 8 */
.main-content .another-table-wrapper.layout-8 td {
  border-left: none !important;
  border-right: none !important;
}

.main-content .another-table-wrapper.layout-8 td:nth-child(2) {
  display: none;
}

.main-content .another-table-wrapper.layout-8 td:first-child {
  width: 100% !important;
}

.main-content
  .another-table-wrapper.layout-8
  .another-component-v2-resize-handle {
  display: none !important;
}

/* 1. 더 강화된 폰트 렌더링 설정 */
.ql-editor {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
  font-smooth: always !important;
  /*transform: rotate(0.001deg); /* 폰트 렌더링 개선을 위한 미세 회전 */
}

/* 2. 더 세밀한 폰트 설정 */
.ql-editor p {
  font-weight: normal !important;
  letter-spacing: -0.3px !important;
  word-spacing: -0.1px !important;
  line-height: 1.5 !important;
  text-shadow: 0 0 0 rgba(0, 0, 0, 0.01); /* 텍스트 렌더링 개선 */
}

/* 3. 하드웨어 가속 및 픽셀 정렬 */
.ql-editor * {
  transform: translateZ(0) !important;
  backface-visibility: hidden !important;
  -webkit-perspective: 1000 !important;
  perspective: 1000 !important;
  -webkit-transform-style: preserve-3d !important;
  transform-style: preserve-3d !important;
}

/* 4. 특정 브라우저 최적화 */
@media screen and (-webkit-min-device-pixel-ratio: 2),
  screen and (min-resolution: 2dppx) {
  .ql-editor {
    -webkit-font-smoothing: subpixel-antialiased !important;
  }
}

.save-confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.save-confirmation-modal .modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 90%;
}

.save-confirmation-modal h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

.save-confirmation-modal .modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.save-confirmation-modal button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.save-confirmation-modal button:first-child {
  background-color: #007bff;
  color: white;
}

.save-confirmation-modal button:nth-child(2) {
  background-color: #dc3545;
  color: white;
}

.save-confirmation-modal button:last-child {
  background-color: #6c757d;
  color: white;
}

.another-toolbar-icon {
  filter: invert(1);
  vertical-align: middle;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.another-note-style-btn:hover .another-toolbar-icon {
  opacity: 1;
}

.hidden-editor {
  background-color: #f0f0f0 !important;
  height: 26px;
}

.hidden-editor .ql-editor {
  visibility: hidden !important;
}
/* Select Mode일 때는 모든 요소의 커서를 포인터로 */
/* Select Mode일 때 에디터 내용에 포인터 커서 강제 적용 */
.select-mode .another-quill-editor .ql-editor p {
  cursor: pointer !important;
}
/* Select Mode일 때 에디터 내용에 포인터 커서 강제 적용 */
.select-mode .another-quill-editor .ql-editor p {
  cursor: pointer !important;
}

/* Select Mode 활성화 시 모든 요소의 커서를 포인터로 */
body.select-mode-active,
body.select-mode-active *,
body.select-mode-active .ql-editor,
body.select-mode-active .ql-editor p {
  cursor: pointer !important;
}

/* Select Mode 스타일만 정리 */
/* 1. Select Mode 활성화 시 */
body.select-mode-active {
  cursor: pointer !important;
}

/* 2. Select Mode에서 에디터 내용 */
body.select-mode-active .ql-editor,
body.select-mode-active .ql-editor p {
  cursor: pointer !important;
}

/* 3. Select Mode 비활성화 시 에디터 기본 커서 복원 */
.ql-editor,
.ql-editor p {
  cursor: text;
}
