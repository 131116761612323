.curriculum-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #1e1e1e;
}

.curriculum-page h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.sections-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border: none;
  min-width: 100%;
  table-layout: fixed;
  word-wrap: break-word;
}

.sections-table table,
.sections-table th,
.sections-table td {
}

.sections-table th {
  background-color: #333;
  color: #e0e0e0;
  padding: 10px;
  font-weight: bold;
  border-top-left-radius: 8px; /* 왼쪽 상단 모서리 둥글게 */
  border-top-right-radius: 8px; /* 오른쪽 상단 모서리 둥글게 */
}

.sections-table th:first-child {
  border-top-left-radius: 8px;
}

.sections-table th:last-child {
  border-top-right-radius: 8px;
}

.sections-table td {
  padding: 10px;
}

.sections-table td.review-count-cell {
}

.sections-table input {
  width: 90%;
  padding: 10px;
  border: 0px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.unit-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.unit-name-column {
  width: 35%;
}
.date-column {
  width: 20%;
}
.reviews-column {
  width: 35%;
}
.actions-column {
  width: 10%;
}

.unit-table td {
  padding: 8px;
  border-bottom: 1px solid #444;
}

.review-checkboxes {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  padding-left: 10px;
}

.review-checkbox-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.unit-table th,
.unit-table td {
  padding: 8px;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #2a2a2a;
  /*border-bottom: 1px solid #444;*/
  border-right: 1px solid #444;
  color: #e0e0e0;
}

.unit-name-column {
  width: 40%;
}

.date-column {
  width: 15%;
}

.review-count-column {
  width: 15%;
}

.actions-column {
  width: 15%;
}

.actions-column,
.unit-table th.actions-column,
.unit-table td.actions-column {
  text-align: center;
}

.review-count-column,
.unit-table th.review-count-column,
.unit-table td.review-count-column {
  text-align: center;
}

.unit-table img {
  width: 10px;
  height: 10px;

  cursor: pointer;
  margin-left: 10px; /* 필요에 따라 조정 */
}

.unit-table img.delete-unit-icon {
  width: 15px;
  height: 15px;
  margin-left: 0;
}

.unit-table img.delete-unit-icon:hover {
  opacity: 0.7;
}

.curriculum-header {
  display: flex;
  align-items: center;
}

.close-sidebar {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin-right: 15px;
}

.close-sidebar img {
  width: 24px;
  height: 24px;
}

.curriculum-header h1 {
  margin-bottom: 20px;
  font-size: 2rem;
  color: #ffffff;
}

.curriculum-header-image {
  width: 150px;
  height: 150px;
}

.add-unit-button,
.add-first-unit-button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #e0e0e0;
  font-weight: bold;
  transition: background-color 0.3s;
}

.add-unit-button:hover,
.add-first-unit-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.add-unit-button svg,
.add-first-unit-button svg {
  margin-right: 8px;
}

.add-first-unit-button {
  font-weight: bold;
}

.review-count-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.review-count-button {
  width: 30px;
  height: 30px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 50%;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 2px 6px;
  margin: 0 2px;
}

.review-count-button:hover {
  background-color: #e0e0e0;
}

.review-count {
  font-size: 18px;
  font-weight: bold;
  margin: 0 5px;
}

.unit-actions {
  position: relative;
}

.delete-confirmation {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  color: #231c16;
}

.delete-confirmation p {
  margin-bottom: 10px;
}

.delete-confirmation button {
  margin-right: 5px;
}

.delete-confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
  pointer-events: auto;
}

.delete-confirmation-content {
  background-color: #1a1a1a;
  border: 1px solid #333;
  border-radius: 12px;
  padding: 24px;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.3);
  animation: modalFadeIn 0.2s ease-out;
  position: relative;
}

.delete-confirmation-content.deleting::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-confirmation-content p {
  color: #e0e0e0;
  font-size: 16px;
  margin-bottom: 24px;
  line-height: 1.5;
  text-align: center;
  white-space: pre-line;
}

.delete-confirmation-buttons {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.modal-button {
  padding: 10px 24px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.confirm-button {
  background-color: #fae150;
  color: #000;
  border: none;
}

.confirm-button:hover {
  background-color: #e5cd48;
  transform: translateY(-1px);
}

.cancel-button {
  background-color: transparent;
  color: #e0e0e0;
  border: 1px solid #666;
}

.cancel-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateY(-1px);
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.units-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.unit-card {
  border: 1px solid #ccc;
  border-radius: 8px;

  /*padding: 1rem;*/
  width: calc(50% - 0.5rem);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.unit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.unit-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.unit-date,
.review-count {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.review-count-controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.delete-unit-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  cursor: pointer;
}

.add-unit-button {
  margin-top: 1rem;
}

.edit-unit-icon {
  width: 20px; /* 원하는 크기로 조정 */
  height: 20px;
  cursor: pointer;
  margin-left: 5px; /* 필요에 따라 조정 */
  filter: invert(50%) sepia(0%) saturate(0%) hue-rotate(246deg) brightness(90%)
    contrast(92%);
}

.edit-unit-icon:hover {
  filter: invert(40%) sepia(0%) saturate(0%) hue-rotate(246deg) brightness(90%)
    contrast(92%);
  filter: invert(40%) sepia(0%) saturate(0%) hue-rotate(246deg) brightness(90%)
    contrast(92%);
}
.editable-text {
  cursor: default !important;
  padding-bottom: 2px;
}

.section-title .editable-text {
  cursor: text !important;
}

.editable-text:hover {
  background-color: transparent;
}

.editable-text:empty::before {
  content: "Add Section Title";
  color: #999;
  font-style: italic;
}

.delete-unit-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.delete-unit-icon:hover {
  opacity: 0.7;
}

.transparent-datepicker {
  background-color: transparent !important;
  border: none !important;
  color: inherit !important;
}

.transparent-datepicker::placeholder {
  color: inherit !important;
  opacity: 0.7;
}

.calendar-icon {
  width: 15px !important;
  height: 15px !important;
  cursor: pointer;
  margin-left: 0 !important;
}

.chapter-name {
  font-size: 1em;
  color: #e0e0e0;
  padding: 5px 0 5px 20px;
  margin-bottom: 5px;
}

.unit-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.unit-table th,
.unit-table td {
  padding: 8px;
  text-align: left;
  /* border-bottom: 1px solid #444;*/
  border-right: 1px solid #444;
  word-wrap: break-word;
  overflow-wrap: break-word;
  background-color: #2a2a2a;
  color: #e0e0e0;
}

.unit-table th {
  background-color: #333;
  border-top: 1px solid #444;
  font-weight: 500;
}

.unit-table th:last-child,
.unit-table td:last-child {
  border-right: none;
}

.unit-table tr:first-child th:first-child {
  border-top-left-radius: 5px;
}

.unit-table tr:first-child th:last-child {
  border-top-right-radius: 5px;
}

.unit-table tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}

.unit-table tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}

.unit-table th:nth-child(2),
.unit-table th:nth-child(3),
.unit-table td:nth-child(2),
.unit-table td:nth-child(3) {
  width: 120px; /* Plan Date와 Last Review Date 열의 너비 조정 */
}

.date-picker-container {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
}

.calendar-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  cursor: pointer;
}

.selected-date {
  font-size: 0.9em;
  white-space: nowrap;
}

.move-unit-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  transition: opacity 0.3s ease;
}

.move-unit-icon:hover {
  opacity: 0.7;
}

.action-icon {
  cursor: pointer;
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.action-icon:last-child {
  margin-right: 0;
}

.modal-content {
  max-height: 80vh; /* 뷰포트 높이의 80%로 제한 */
  overflow-y: auto; /* 내용이 칠 경우 스크롤 추 */
  display: flex;
  flex-direction: column;
}

.thumbnail-options {
  max-height: 30vh; /* 썸네일 옵션 영역의 최대 높이 제한 */
  overflow-y: auto; /* 내용이 넘칠 경우 스크롤 추가 */
}

.modal-buttons {
  margin-top: auto; /* 버튼을 항상 하단에 위치시킴 */
  padding: 10px;
}

.curriculum-content {
  flex: 1;
  overflow-x: auto;
  overflow-y: auto;
  padding: 20px;
}

.no-cursor {
  cursor: default;
}

@media (max-width: 768px) {
  .curriculum-content {
    font-size: 14px;
  }

  .unit-name-column {
    width: 30%;
  }

  .date-column {
    width: 25%;
  }

  .review-count-column {
    width: 20%;
  }

  .actions-column {
    width: 25%;
  }
}

@media (max-width: 480px) {
  .curriculum-content {
    font-size: 12px;
  }
}

.curriculumunitname {
  font-weight: 500;
  color: #e0e0e0;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 4px 8px;
  border-radius: 4px;
  text-align: left;
  font-size: 0.9em; /* 폰트 사이즈 줄이기 */
}

.curriculumunitname:hover {
  color: #fae150;
  background-color: rgba(250, 225, 80, 0.1);
}

.click-here-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #231c16;
  border-radius: 24px;
  background-color: #fae150;
  color: #231c16;
  font-size: 14px;
  font-weight: 700;
  outline: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.click-here-button:hover {
  background-color: #f8d730;
}

.click-here-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.scus-icon {
  cursor: pointer;
  width: 180px;
  height: 180px;
  transition: transform 0.3s ease;
  border: 1px solid #231c16;
  border-radius: 24px;
}

.scus-icon:hover,
.cus-icon:hover,
.us-icon:hover {
  transform: scale(1.1);
}
.cus-icon {
  cursor: pointer;
  width: 180px;
  height: 180px;
  transition: transform 0.3s ease;
  border: 1px solid #231c16;
  border-radius: 24px;
}

.us-icon {
  cursor: pointer;
  width: 180px;
  height: 180px;
  transition: transform 0.3s ease;
  border: 1px solid #231c16;
  border-radius: 24px;
}

.section-options {
  margin-top: 20px; /* 상단 여백 추 */
  display: flex;
  gap: 20px; /* 아이콘 사이의 간격 */
}

.click-here-button {
  margin-bottom: 20px; /* 버튼 아래 여백 추가 */
}

.unit-name-input {
  cursor: text !important;
}

.add-new-section-button {
  display: block;
  width: 100%; /* section-table과 같은 너비 */
  margin: 20px auto;
  padding: 10px 20px;
  background-color: transparent; /* 배경색 제거 */
  color: black; /* 글자색을 검정으로 변경 */
  border: 2px dashed black; /* 검은색 점선 테두리 적용 */
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold; /* 글자를 굵게 설정 */
}

.add-new-section-button:hover {
  background-color: rgba(0, 0, 0, 0.05); /* 호버 시 약간의 배경색 변화 */
}

.section-table {
  border: 2px solid #444;
  background-color: #1e1e1e;
  border-radius: 8px;
  margin-bottom: 20px;
  overflow: hidden;
  width: 100%;
}

.section-title {
  background-color: #333;
  color: #e0e0e0;
  padding: 10px;
  font-weight: bold;
}

.chapter-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.chapter-table th,
.chapter-table td {
  border: none;
  /* border-bottom: 1px solid #ddd;*/
  padding: 10px;
}

.chapter-table tr:last-child td {
  border-bottom: none;
}

.unit-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.unit-table th,
.unit-table td {
  border: none;
  /* border-bottom: 1px solid #ddd;*/
  padding: 8px;
}

.unit-table tr:last-child td {
  border-bottom: none;
}

.editable-text.section-title.no-cursor {
  font-size: 1.2em !important;
}

.add-unit-button,
.add-chapter-button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #e0e0e0;
  font-weight: bold;
  transition: background-color 0.3s;
}

.add-unit-button:hover,
.add-chapter-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.add-unit-button svg,
.add-chapter-button svg {
  margin-right: 8px;
}

.add-buttons-container {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 10px;
}

/* 입력 필드 스타일 */
.section-title input,
.chapter-name input,
.unit-name-input {
  background-color: transparent;
  color: #e0e0e0;
  border: none;
  border-bottom: 1px solid #444;
  outline: none !important;
  transition: none;
  width: 100%;
  box-shadow: none !important;
}

.chapter-name input:focus,
.unit-name-input:focus {
  background-color: transparent !important;
  box-shadow: none !important;
  border-bottom-color: #fae150 !important;
  outline: none !important;
}

.chapter-name input:not(:focus),
.unit-name-input:not(:focus) {
  background-color: transparent;
  box-shadow: none;
  border-bottom-color: #444;
}

.section-title input:focus {
  background-color: #333 !important;
  color: #e0e0e0 !important;
  border-bottom-color: #fae150 !important;
  outline: none !important;
  box-shadow: none !important;
}

.section-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  outline: none !important;
}

.chapter-name-container {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 0 5px 20px;
}

.chapter-name {
  flex-grow: 1;
  text-align: left;
  margin-right: auto;
}

.curriculum-toggle-icon {
  margin-right: 8px;
}

.icon-group {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.more-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.delete-icon {
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-right: 5px;
  opacity: 0.6;
  transition: opacity 0.2s ease;
}

.delete-icon:hover {
  opacity: 0.8;
}

.section-title-container,
.chapter-name-container {
  display: flex;
  align-items: center;
  outline: none !important;
  height: 100%;
}

.section-title-container .editable-text,
.chapter-name-container .editable-text {
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: 0 !important;
}

.section-title input,
.chapter-name input {
  display: flex;
  align-items: center;
  height: 100%;
}

.icon-group {
  display: flex;
  align-items: center;
  gap: 8px; /* 아이콘들 사이의 간격 추가 */
}

.action-icon {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  cursor: pointer;
}

.section-title-container,
.chapter-name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none !important;
}

.unit-checkbox {
  appearance: none;
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #ccc;
  border-radius: 3px;
  outline: none !important;
  cursor: pointer;
  margin-right: 15px !important;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent; /* 모바일에서 탭 시 하이라이트 제거 */
}

.unit-checkbox:focus {
  outline: none !important;
  box-shadow: none !important;
}

.unit-checkbox:checked {
  background-color: #fae150;
  border-color: #fae150;
}

.unit-checkbox:checked::after {
  content: "\2714";
  display: block;
  text-align: center;
  color: white;
  font-size: 14px;
  line-height: 18px;
}

.unit-name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 10px;
}

.curriculumunitname {
  flex-grow: 1;
  margin-right: 10px;
}

.unit-name-container .icon-group {
  display: flex;
  align-items: center;
}

.unit-name-container .edit-unit-icon {
  width: 14px;
  height: 14px;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.2s ease;
}

.unit-name-container .edit-unit-icon:hover {
  opacity: 0.8;
}

.add-buttons-group {
  display: flex;
  gap: 10px;
  margin: 20px 0;
  justify-content: center;
}

.add-section-button,
.add-chapter-button {
  padding: 10px 20px;
  background-color: transparent;
  color: #e0e0e0;
  border: 2px dashed #444;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;
}

.add-section-button:hover,
.add-chapter-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.add-unit-button {
  margin: 10px 0; /* 상하 여백 조정 */
  padding: 8px 15px;
  background-color: transparent;
  border: 1px dashed #ccc; /* 테두리 스타일 변경 */
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #666;
  font-weight: normal;
  width: 100%; /* 테이블 너비에 맞춤 */
  justify-content: center; /* 중앙 정렬 */
}

.add-unit-button:hover {
  background-color: rgba(0, 0, 0, 0.03);
  border-color: #999;
  color: #333;
}

.add-unit-button svg {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.add-chapter-button {
  margin: 10px 0;
  padding: 8px 15px;
  background-color: transparent;
  border: 1px dashed #ccc;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #666;
  font-weight: normal;
  width: 100%;
  justify-content: center;
}

.add-chapter-button:hover {
  background-color: rgba(0, 0, 0, 0.03);
  border-color: #999;
  color: #333;
}

.add-chapter-button svg {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}
.add-unit-button:hover img,
.add-chapter-button:hover img {
  filter: invert(83%) sepia(32%) saturate(638%) hue-rotate(332deg)
    brightness(103%) contrast(96%) !important;
}

.add-unit-button img,
.add-chapter-button img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  filter: invert(0.27) !important; /* #444 컬러로 변경 */
  transition: filter 0.2s ease;
}
/* 버튼 스타일 수정 */
.add-unit-button,
.add-chapter-button {
  margin: 10px 0;
  padding: 8px 15px;
  background-color: #252525;
  border: 1px dashed #444;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #444;
  font-weight: normal;
  width: 100%;
  justify-content: center;
  transition: all 0.2s ease;
}

.add-unit-button:hover,
.add-chapter-button:hover {
  background-color: #333;
  border-color: rgba(250, 225, 80, 0.3);
  color: #fae150;
  transform: translateY(-1px);
}

/* 아이콘 스타일 수정 */
.unit-table img,
.add-unit-button svg,
.add-chapter-button svg,
.action-icon,
.edit-unit-icon,
.delete-unit-icon,
.calendar-icon {
  filter: invert(0.8);
  transition: filter 0.2s ease;
}

.unit-table img:hover .add-icon-svg :hover,
.action-icon:hover,
.edit-unit-icon:hover,
.delete-unit-icon:hover,
.calendar-icon:hover {
  filter: invert(83%) sepia(32%) saturate(638%) hue-rotate(332deg)
    brightness(103%) contrast(96%);
}

/* 입력 필드 스타일 수정 */
.unit-name-input,
.transparent-datepicker {
  background-color: transparent !important;
  color: #e0e0e0 !important;
  border: none !important;
  border-bottom: 1px solid #fae150;
  outline: none !important;
  box-shadow: none !important;
  transition: all 0.2s ease-in-out;
}

.unit-name-input:focus,
.transparent-datepicker:focus {
  background-color: transparent !important;
  box-shadow: none !important;
  border-bottom-color: #fae150 !important;
  outline: none !important;
}

/* 삭제 확인 모달 스타일 수정 */
.delete-confirmation {
  background-color: #1a1a1a;
  border: 1px solid #444;
  color: #e0e0e0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.delete-confirmation button {
  background-color: #252525;
  color: #e0e0e0;
  border: 1px solid #444;
  padding: 6px 12px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.delete-confirmation button:hover {
  background-color: #333;
  border-color: rgba(250, 225, 80, 0.3);
  color: #fae150;
}

/* 리뷰 카운트 버튼 스일 수정 */
.review-count-button {
  background-color: #252525;
  color: #e0e0e0;
  border: 1px solid #444;
  transition: all 0.2s ease;
}

.review-count-button:hover {
  background-color: #333;
  border-color: rgba(250, 225, 80, 0.3);
  color: #fae150;
}

.section-title-container input {
  color: #000000;
}

.section-title-container,
.section-title,
.section-title input,
.chapter-name-container,
.chapter-name,
.chapter-name input,
.unit-name-container,
.unit-name-input {
  outline: none !important;
  -webkit-tap-highlight-color: transparent; /* 모바일에서 탭 시 하이라이트 제거 */
}

.section-title input:focus,
.chapter-name input:focus,
.unit-name-input:focus {
  background-color: transparent !important;
  box-shadow: none !important;
  border-bottom-color: #fae150 !important;
  outline: none !important;
}

/* SVG 아이콘 스타일 */
.add-unit-button svg path,
.add-chapter-button svg path {
  stroke: #e0e0e0 !important; /* 기본 색상 */
  transition: stroke 0.2s ease;
}

.add-unit-button:hover svg path,
.add-chapter-button:hover svg path {
  stroke: #fae150 !important; /* 호버 시 노란색으로 변경 */
}

/* 혹시 모를 다른 스타일 덮어쓰기 */
.add-unit-button:hover,
.add-chapter-button:hover {
  color: #fae150 !important;
}

.add-unit-button svg,
.add-chapter-button svg {
  color: #e0e0e0 !important;
}

.add-unit-button:hover svg,
.add-chapter-button:hover svg {
  color: #fae150 !important;
}

.add-icon-svg {
  transition: all 0.2s ease;
}

.add-icon-path {
  stroke: #e0e0e0;
  transition: stroke 0.2s ease;
}

.add-unit-button:hover .add-icon-path {
  stroke: #fae150 !important;
}

.empty-curriculum-button {
  background-color: #2c2c2c;
  color: #ffffff;
  border: 1px solid #404040;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.empty-curriculum-button:hover {
  background-color: #404040;
  border-color: #505050;
}

.empty-curriculum-button:active {
  transform: scale(0.98);
}

input[type="checkbox"] {
  outline: none !important;
  box-shadow: none !important;
  -webkit-tap-highlight-color: transparent;
}

input[type="checkbox"]:focus {
  outline: none !important;
  box-shadow: none !important;
}

.unit-checkbox {
  appearance: none;
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #ccc;
  border-radius: 3px;
  outline: none !important;
  cursor: pointer;
  margin-right: 15px !important;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

.unit-checkbox:focus {
  outline: none !important;
  box-shadow: none !important;
}
.empty-curriculum h2 {
  color: #ffffff !important;
}

.toggle-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 4px;
  filter: invert(1); /* 아이콘을 흰색으로 변경 */
}

.toggle-icon:hover {
  opacity: 0.7;
  filter: invert(83%) sepia(32%) saturate(638%) hue-rotate(332deg)
    brightness(103%) contrast(96%); /* 호버 시 노란색으로 변경 */
}

.triangle-icon {
  width: 0;
  height: 0;
  cursor: pointer;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  display: inline-block;
  margin-left: 4px;
}

.triangle-down {
  border-top: 8px solid #666;
  border-bottom: 0;
}

.triangle-up {
  border-bottom: 8px solid #666;
  border-top: 0;
}

.triangle-icon:hover {
  opacity: 0.7;
}

.curriculum-toggle-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 4px;
  color: #e0e0e0; /* 텍스트 색상을 밝은 회색으로 설정 */
  font-size: 16px; /* 화살표 크기 조정 */
  filter: none; /* 이전 필터 제거 */
  display: flex; /* 추가 */
  align-items: center; /* 추가 */
  line-height: 1; /* 추가 */
}

.curriculum-toggle-icon:hover {
  color: #fae150; /* 호버 시 노란색으로 변경 */
  opacity: 0.7;
}

.review-status-icon {
  cursor: pointer;
  font-size: 0.9rem;
  color: #666;
  position: relative;
}

.review-status-icon:hover::after {
  content: attr(title);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -30px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 9999;
}

.review-status-message {
  display: inline-flex; /* 인라인으로 변경 */
  align-items: center;
  margin-left: 4px; /* 날짜와의 간격 */
}

.review-status-icon {
  cursor: pointer;
  font-size: 0.9rem;
  color: #666;
  position: relative;
}

.review-status-icon:hover::after {
  content: attr(title);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -30px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 9999;
}

.date-info {
  display: flex;
  align-items: center;
  gap: 4px; /* 각 요소 사이의 간격 */
  position: relative; /* 추가: 상대 위치 설정 */
  overflow: visible; /* 추가: 오버플로우를 visible로 설정 */
}

.review-status-message {
  display: inline-flex;
  align-items: center;
}

.review-status-icon {
  cursor: pointer;
  font-size: 0.9rem;
  color: #666;
  position: relative;
}

.review-status-icon:hover::after {
  content: attr(title);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -30px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 9999;
}

.unit-table td {
  position: relative; /* 추가: 상대 위치 설정 */
  overflow: visible !important; /* 추가: 오버플로우를 visible로 강제 설정 */
}

/* DatePicker 관련 스타일 수정 */
.date-picker-container {
  position: relative;
  /* z-index: 1 !important;*/
}

.react-datepicker-wrapper {
  /*z-index: 999999999 !important;*/
}

.react-datepicker-popper {
  z-index: 999999999 !important;
  /*position: absolute !important;*/
}

.react-datepicker {
  z-index: 999999999 !important;
}

.react-datepicker__portal {
  z-index: 999999999 !important;
}

.calendar-icon {
  position: relative;
}

/* 테이블 관련 스타일 */
.section-table,
.chapter-table,
.unit-table {
  position: relative;
  z-index: 1;
  overflow: visible !important;
}

.unit-table td {
  position: relative;
  overflow: visible !important;
}

/* 모든 상위 컨테이너의 overflow 처리 */
.curriculum-container,
.curriculum-content,
.section-table,
.section-table > tbody,
.section-table > tbody > tr,
.section-table > tbody > tr > td,
.chapter-table,
.chapter-table > tbody,
.chapter-table > tbody > tr,
.chapter-table > tbody > tr > td,
.unit-table,
.unit-table > tbody,
.unit-table > tbody > tr,
.unit-table > tbody > tr > td {
  position: relative;
  overflow: visible !important;
  z-index: auto !important;
}

/* DatePicker 관련 스타일 */
.date-picker-container {
  position: relative;
}

.react-datepicker-wrapper,
.react-datepicker-popper,
.react-datepicker,
.react-datepicker__portal {
  /*z-index: 999999999 !important;*/
}

.react-datepicker-popper {
  position: absolute !important;
}

.calendar-icon {
  position: relative;
}

/* 각 테이블의 스택 컨텍스트 초기화 */
.unit-table {
  z-index: auto !important;
  isolation: auto !important;
}

.modal-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.confirm-button:disabled {
  background-color: #ccc;
}

.delete-confirmation-modal {
  /* 기존 스타일 */
  pointer-events: auto; /* 모달이 클릭 가능하도록 설정 */
}

.delete-confirmation-content {
  /* 기존 스타일 */
  position: relative;
}

/* 삭제 중일 때 로딩 표시 */
.delete-confirmation-content.deleting::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 유닛 테이블 내부의 기본 커서 스타일 제거 */
.unit-table td {
  cursor: default;
}

/* 클릭 가능한 요소들에만 포인터 커서 적용 */
.unit-table .curriculumunitname,
.unit-table .edit-unit-icon,
.unit-table .action-icon,
.unit-table .calendar-icon,
.unit-table .review-count-button,
.unit-table .unit-checkbox {
  cursor: pointer;
}

.header-with-icon {
  display: flex;
  align-items: center;
  gap: 4px;
}

.info-icon {
  color: #666;
  cursor: help;
  font-size: 14px;
  position: relative;
}

.info-icon:hover::after {
  content: attr(title);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -30px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.8rem;
  white-space: pre-line; /* 이 부분을 수정 */
  z-index: 999999999;
}

/* 두 아이콘에 대한 공통 스타일 */
.review-status-icon,
.info-icon {
  cursor: help;
  font-size: 0.9rem;
  color: #666;
  position: relative;
}

/* 두 아이콘의 툴팁에 대한 공통 스타일 */
.review-status-icon:hover::after,
.info-icon:hover::after {
  content: attr(title);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -30px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 999999999;
}

/* 모든 상위 컨테이너의 overflow 처리 */
.curriculum-container,
.curriculum-content,
.section-table,
.section-table > tbody,
.section-table > tbody > tr,
.section-table > tbody > tr > td,
.chapter-table,
.chapter-table > tbody,
.chapter-table > tbody > tr,
.chapter-table > tbody > tr > td,
.unit-table,
.unit-table > tbody,
.unit-table > tbody > tr,
.unit-table > tbody > tr > td {
  position: relative;
  overflow: visible !important;
  z-index: auto !important;
}

/* DatePicker 관련 스타일 */
.date-picker-container {
  position: relative;
}

.react-datepicker-wrapper,
.react-datepicker-popper,
.react-datepicker,
.react-datepicker__portal {
  /*z-index: 999999999 !important;*/
}

.react-datepicker-popper {
  position: absolute !important;
}

.calendar-icon {
  position: relative;
}

/* 각 테이블의 스택 컨텍스트 초기화 */
.unit-table {
  z-index: auto !important;
  isolation: auto !important;
}

.unit-table th {
  position: relative;
  overflow: visible !important;
  z-index: auto !important;
}

.header-with-icon {
  position: relative;
  overflow: visible !important;
  z-index: auto !important;
}

/* 삭제 아이콘 스타일 수정 */
.delete-icon {
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-right: 5px;
  opacity: 0.6;
  transition: opacity 0.2s ease;
}

.delete-icon:hover {
  opacity: 0.8;
}

.delete-unit-icon {
  width: 14px;
  height: 14px;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.2s ease;
}

.delete-unit-icon:hover {
  opacity: 0.8;
}

/* 날짜 컨테이너 스타일 수정 */
.date-picker-container,
.date-info {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
}

/* 삭제 아이콘 기본 스타일 */
.clear-date-icon {
  width: 12px;
  height: 12px;
  cursor: pointer;
  opacity: 0; /* 완전히 숨김 */
  transition: opacity 0.2s ease;
  position: absolute;
  right: 10px; /* 날짜 텍스트 옆에 위치 */
}

/* 날짜 컨테이너에 호버했을 때만 삭제 아이콘 표시 */
.date-picker-container:hover .clear-date-icon,
.date-info:hover .clear-date-icon {
  opacity: 0.6;
}

/* 삭제 아이콘에 호버했을 때 */
.clear-date-icon:hover {
  opacity: 0.8;
}
.days-passed {
  color: #666;
  font-size: 0.9em;
  margin-left: 4px;
}

.reviews-column {
  min-width: 300px;
}

.review-checkboxes {
  display: flex;
  /* gap: 12px;*/
  justify-content: flex-start;
}

.review-checkbox-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.review-checkbox {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.review-label {
  font-size: 12px;
  color: #333;
}

.review-checkbox-container:hover .review-label {
  color: #666;
}

.review-checkbox:checked + .review-label {
  color: #fae150;
  font-weight: 500;
}

.review-checkbox:checked {
  background-color: transparent;
  border-color: #fae150;
}

input[type="checkbox"].review-checkbox {
  appearance: none;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  cursor: pointer;
  border: 2px solid #666;
  border-radius: 3px;
  background-color: transparent !important;
  position: relative;
}

input[type="checkbox"].review-checkbox:checked {
  background-color: transparent !important;
  border-color: #f5dc4c;
}

input[type="checkbox"].review-checkbox:checked::after {
  content: "\2714";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #f5dc4c;
  font-size: 12px;
}

.chapter-name-container {
  display: flex;
  align-items: center;
  cursor: pointer !important;
  padding: 10px;
  width: 100%;
  transition: background-color 0.2s ease;
}

.chapter-name-container:hover {
  background-color: rgba(250, 225, 80, 0.05);
  border-radius: 6px;
}

/* 토글 아이콘 위치 조정 */
.curriculum-toggle-icon {
  margin-right: 12px;
  color: #666;
  font-size: 12px;
  transition: transform 0.2s ease;
}

/* 챕터가 열렸을 때 아이콘 회전 */
.curriculum-toggle-icon.open {
  transform: rotate(90deg);
}

.chapter-name-container .icon-group .edit-icon {
  width: 14px; /* 기존 20px에서 축소 */
  height: 14px;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.2s ease;
  margin-right: 5px; /* 삭제 아이콘과의 간격 */
}

.chapter-name-container .icon-group .edit-icon:hover {
  opacity: 0.8;
}

/* 기존 삭제 아이콘 스타일과 일관성을 위해 크기 맞춤 */
.chapter-name-container .icon-group .delete-icon {
  width: 14px;
  height: 14px;
}

.chapter-name-input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #666;
  color: #e0e0e0; /* 기존 텍스트 색상과 동일하게 */
  font-size: 0.9em; /* 기존 텍스트 크기와 동일하게 */
  padding: 4px 8px; /* 기존 패딩과 동일하게 */
  margin: 0;
  width: 100%;
  outline: none;
  transition: none;
  font-weight: 500; /* 기존 폰트 무게와 동일하게 */
  line-height: inherit; /* 기존 줄 높이 유지 */
  height: auto; /* 높이 자동 조정 */
}
.curriculum-page input[type="text"] {
  background-color: transparent;
  color: #e0e0e0;
  border: none;
  border-bottom: 1px solid #444;
  outline: none !important;
  width: 100%;
  box-shadow: none !important;
  font-size: inherit;
  font-family: inherit;
  padding: inherit;
  margin: inherit;
}
.unit-name-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.unit-checkbox {
  flex-shrink: 0;
}

.curriculumunitname {
  flex-grow: 1;
  cursor: pointer;
}

.icon-group {
  flex-shrink: 0;
  margin-left: auto;
}

.edit-unit-icon {
  width: 14px;
  height: 14px;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.2s ease;
}

.edit-unit-icon:hover {
  opacity: 0.8;
}

/* 이런 식으로 실제 사용되는 상위 클래스 내의 text input에 적용 */
.curriculum-container input[type="text"],
.unit-table input[type="text"],
.chapter-table input[type="text"] {
  background-color: transparent;
  color: #e0e0e0;
  border: none;
  border-bottom: 1px solid #444;
  outline: none !important;
  width: 100%;
  box-shadow: none !important;
  font-size: inherit;
  font-family: inherit;
  padding: inherit;
  margin: inherit;
}

.section-name,
.section-name[data-rbd-drag-handle-context-id] {
  cursor: default !important; /* important를 써서 우선순위 높임 */
}

/* drag handle만 grab 커서 유지 */
.section-drag-handle[data-rbd-drag-handle-context-id] {
  cursor: grab !important;
}

.section-drag-handle[data-rbd-drag-handle-context-id]:active {
  cursor: grabbing !important;
}
