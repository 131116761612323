.licensing-container {
  max-width: 100%;
  margin: 0;
  padding: 0;
  /*background-color: #fff;*/
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  background-color: #f8f9fa;
  border-bottom: 1px solid #eee;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.hero-section {
  padding: 60px 20px;
  text-align: center;
  margin-bottom: 40px;
}

.hero-section h1 {
  font-size: 2.8em;
  color: #1a1a1a;
  margin: 0;
  text-align: right;
  margin-right: 20px;
}

.licensing-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 120px 40px 60px;
}

.licensing-content h1 {
  margin-bottom: 40px;
  font-size: 2.5rem;
  color: #111827;
}

.licensing-sections {
  margin: 40px 0;
}

.licensing-section {
  margin-bottom: 50px;
  padding: 30px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.licensing-section h2 {
  font-size: 1.8em;
  color: #1a1a1a;
  margin-bottom: 20px;
  border-bottom: 2px solid #ffb800;
  padding-bottom: 10px;
}

.licensing-section p {
  font-size: 1.1em;
  line-height: 1.8;
  color: #666;
  margin-bottom: 20px;
}

.pricing-list,
.features-list {
  list-style: none;
  padding-left: 20px;
  margin: 20px 0;
}

.pricing-list li,
.features-list li {
  margin-bottom: 10px;
  padding-left: 25px;
  position: relative;
  color: #666;
}

.pricing-list li:before,
.features-list li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #ffb800;
}

.contact-details {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
}

.contact-details p {
  margin: 10px 0;
  color: #666;
}

.licensing-footer {
  text-align: center;
  padding: 40px 0;
  background-color: #f8f9fa;
  border-top: 1px solid #eee;
}

.cta-button {
  display: inline-block;
  padding: 12px 30px;
  background-color: #1a1a1a;
  color: white;
  text-decoration: none;
  border-radius: 25px;
  transition: all 0.3s ease;
  font-weight: 500;
}

.cta-button:hover {
  background-color: #ffb800;
  color: #1a1a1a;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2em;
    text-align: right;
  }

  .licensing-section h2 {
    font-size: 1.5em;
  }

  .licensing-section {
    padding: 20px;
  }

  .licensing-content {
    padding: 100px 20px 40px;
  }

  .licensing-content h1 {
    font-size: 2rem;
  }
}

.about-logo {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #414042;
  font-size: 24px;
  font-family: "Dela Gothic One";
  line-height: 31px;
  cursor: pointer;
  text-decoration: none;
}
