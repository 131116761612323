.flashcards-container {
  display: flex;
  gap: 24px;
  padding: 20px;
}

.flashcards-left {
  flex: 1;
  min-width: 300px;
  max-width: 800px;
}

.flashcards-right {
  flex: 2;
}

.deck-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.deck-item {
  padding: 20px;
  background-color: #2a2a2a;
  border: 1px solid #404040;
  border-radius: 12px;
  cursor: pointer;
}

/* 선택된 덱의 스타일 */
.deck-item.selected {
  background-color: #333333;
  border: 1px solid #fae150;
  box-shadow: 0 0 0 1px rgba(250, 225, 80, 0.2);
}

/* 기존 hover 스타일은 유지 */
.deck-item:hover {
  border-color: #fae150;
  background-color: #333333;
}

.deck-item h2 {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #ffffff;
  font-size: 16px;
  margin: 0;
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
}

.deck-item h2:hover {
  background-color: transparent;
}

.deck-item ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.card-preview {
  background-color: #333333;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 8px;
  border: 1px solid #404040;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.card-preview:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.card-preview-question {
  color: #ffffff;
}

.card-preview-answer {
  color: #b0b0b0;
}

.study-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: auto;
  padding: 8px 16px;
  margin-top: 12px;
  background-color: transparent;
  border: 1px solid #404040;
  border-radius: 8px;
  color: #b0b0b0;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: "DM Sans", sans-serif;
  align-self: stretch;
  height: 44px;
}

.study-button:hover {
  background-color: #333333;
  border-color: #fae150;
  color: #fae150;
}
.study-icon {
  width: 16px !important;
  height: 16px !important;
  opacity: 0.8 !important;
  filter: brightness(0) saturate(100%) invert(83%) sepia(3%) saturate(14%)
    hue-rotate(314deg) brightness(87%) contrast(84%) !important;
  transition: filter 0.2s ease !important;
}

.study-button:hover .study-icon {
  opacity: 1;
  filter: brightness(0) saturate(100%) invert(89%) sepia(19%) saturate(1401%)
    hue-rotate(332deg) brightness(103%) contrast(94%) !important;
}

.card-container {
  background-color: #2a2a2a;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid #3a3a3a;
  padding: 24px;
}

.card-content {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  padding: 32px;
  margin-bottom: 24px;
  background-color: #333333;
  border-radius: 8px;
}

.card-content.flipped {
  transform: rotateY(180deg);
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  padding: 0 16px;
}

.nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 8px 12px;
  background-color: transparent !important;
  border: 1px solid #404040 !important;
  border-radius: 8px;
  color: #b0b0b0 !important;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: "DM Sans", sans-serif;
  height: 36px;
}

.nav-button:hover:not(:disabled) {
  background-color: #333333 !important;
  border-color: #fae150 !important;
  color: #fae150 !important;
}

.nav-button:disabled {
  background-color: transparent !important;
  border-color: #404040 !important;
  color: #666666 !important;
  cursor: not-allowed;
}

.card-counter {
  font-size: 14px;
  color: #b0b0b0;
}

.add-deck,
.add-card {
  margin-bottom: 20px;
}

.add-deck input,
.add-card input {
  margin-right: 10px;
  padding: 5px;
}

.add-deck button,
.add-card button {
  padding: 5px 10px;
  background-color: transparent;
  color: #b0b0b0;
  cursor: pointer;
  border: 1px solid #404040;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.add-deck button:hover,
.add-card button:hover {
  background-color: #333333;
  border-color: #fae150;
  color: #fae150;
}

.add-deck input[type="text"] {
  outline: none;
  border: 1px solid #ccc; /* 기본 테두리 스타일 */
  padding: 8px;
  border-radius: 4px;
}

.add-deck input[type="text"]:focus {
  border-color: #aaa; /* 포커스 시 테두리 색상 변경 (선택사항) */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* 포커스 시 약간의 그림자 효과 (선택사항) */
}

.flashcards-container input[type="text"] {
  background-color: #333333;
  border: 1px solid #404040;
  color: #ffffff;
  padding: 10px;
  border-radius: 8px;
}

.flashcards-container input[type="text"]:focus {
  border-color: #fae150;
  box-shadow: 0 0 0 2px rgba(250, 225, 80, 0.2);
}

/* 추가: 카드 추가 폼의 입력 필드에 대한 특정 스타일 */
.add-card {
  display: flex;
  gap: 12px;
  align-items: flex-start;
  padding: 20px;
  background-color: #2a2a2a;
  border-radius: 12px;
  border: 1px solid #404040;
}

.card-inputs {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.deck-input {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #404040;
  border-radius: 8px;
  background-color: #333333;
  color: #ffffff;
  font-size: 14px;
  transition: all 0.3s ease;
  height: 44px;
}

.new-card-button {
  margin-top: 0; /* 기존 마진 제거 */
  height: fit-content; /* 높이 자동 조정 */
  padding: 12px 16px; /* 입력창과 동일한 패딩 */
  align-self: stretch;
}

/* 반응형 디자인을 위한 미디어 쿼리 */
@media (max-width: 768px) {
  .add-card input[type="text"] {
    flex: 1 1 100%;
  }

  .add-card button {
    width: 100%;
    margin-top: 10px;
  }
}

.flashcard {
  max-width: 800px;
  margin: 40px auto;
  background-color: #2a2a2a;
  border-radius: 12px;
  border: 1px solid #404040;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.flashcard-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #b0b0b0;
  font-size: 14px;
  padding: 0 8px;
  margin-bottom: 8px;
}

.flashcard-content {
  position: relative;
  min-height: 240px;
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  background-color: #333333;
  border-radius: 12px;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  line-height: 1.6;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s ease;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 8px;
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
}

.delete-card-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 8px 12px;
  background-color: transparent;
  border: 1px solid #404040;
  border-radius: 8px;
  color: #b0b0b0;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: "DM Sans", sans-serif;
  height: 36px;
}

.delete-card-button:hover {
  background-color: #333333;
  border-color: #fae150;
  color: #fae150;
}

.deck-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 16px;
  background-color: #2a2a2a;
  border-radius: 8px;
}

.view-mode-toggle {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
}

.view-mode-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 8px 12px;
  background-color: transparent;
  border: 1px solid #404040;
  border-radius: 8px;
  color: #b0b0b0;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: "DM Sans", sans-serif;
  height: 36px;
}

.view-mode-button:hover,
.add-card button:hover,
.delete-card-button:hover,
.nav-button:hover:not(:disabled) {
  background-color: #333333;
  border-color: #fae150;
  color: #fae150;
}

.view-mode-button.active {
  background-color: #333333;
  border-color: #fae150;
  color: #fae150;
}

.cards-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.card-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #2a2a2a;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  border: 1px solid #3a3a3a;
}

.card-list-content {
  flex: 1;
}

.card-list-question {
  margin-bottom: 8px;
}

.card-list-answer {
  color: #b0b0b0;
}

.add-deck {
  margin-bottom: 24px;
  padding: 16px;
  background-color: #2a2a2a;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-button-group {
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 20px;
  background-color: #2a2a2a;
  border-radius: 12px;
  border: 1px solid #404040;
}

.deck-input {
  flex: 1;
  padding: 0 16px;
  display: flex;
  align-items: center;
  height: 44px;
}

.deck-input:focus {
  border-color: #fae150;
  box-shadow: 0 0 0 2px rgba(250, 225, 80, 0.1);
}

.deck-input::placeholder {
  color: #808080;
}

.flashcards-container .add-deck .add-deck-button {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 12px;
  background-color: transparent;
  border: 1px solid #404040;
  border-radius: 8px;
  color: #b0b0b0;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 36px;
}

.flashcards-container .add-deck .add-deck-button:hover {
  background-color: #333333;
  border-color: #fae150;
  color: #fae150;
}

.flashcards-container .add-deck .plus-icon {
  width: 16px !important;
  height: 16px !important;
  opacity: 0.8 !important;
  filter: brightness(0) saturate(100%) invert(83%) sepia(3%) saturate(14%)
    hue-rotate(314deg) brightness(87%) contrast(84%) !important;
  transition: filter 0.2s ease !important;
}

.flashcards-container .add-deck .add-deck-button:hover .plus-icon {
  opacity: 1;
  filter: brightness(0) saturate(100%) invert(89%) sepia(19%) saturate(1401%)
    hue-rotate(332deg) brightness(103%) contrast(94%);
}

.button-text {
  font-family: "DM Sans", sans-serif;
  letter-spacing: 0.2px;
}

.plus-icon {
  width: 16px;
  height: 16px;
  opacity: 0.8;
  filter: brightness(0) saturate(100%) invert(83%) sepia(3%) saturate(14%)
    hue-rotate(314deg) brightness(87%) contrast(84%);
  transition: filter 0.2s ease;
}

.add-deck-button:hover .plus-icon {
  opacity: 1;
  filter: brightness(0) saturate(100%) invert(89%) sepia(19%) saturate(1401%)
    hue-rotate(332deg) brightness(103%) contrast(94%) !important;
}

.new-card-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 6px !important;
  padding: 10px 16px !important;
  background-color: transparent !important;
  border: 1px solid #404040 !important;
  border-radius: 8px !important;
  color: #b0b0b0 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  transition: all 0.2s ease !important;
  white-space: nowrap !important;
  height: 40px !important;
}

.new-card-button:hover {
  background-color: #333333 !important;
  border-color: #fae150 !important;
  color: #fae150 !important;
}

.new-card-icon {
  width: 16px !important;
  height: 16px !important;
  opacity: 0.8 !important;
  filter: brightness(0) saturate(100%) invert(83%) sepia(3%) saturate(14%)
    hue-rotate(314deg) brightness(87%) contrast(84%) !important;
  transition: filter 0.2s ease !important;
}

.new-card-button:hover .new-card-icon {
  opacity: 1 !important;
  filter: brightness(0) saturate(100%) invert(89%) sepia(19%) saturate(1401%)
    hue-rotate(332deg) brightness(103%) contrast(94%) !important;
}

.new-card-text {
  font-family: "DM Sans", sans-serif;
  letter-spacing: 0.2px;
}

.flip-hint {
  position: absolute;
  top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  opacity: 0.6;
  font-size: 14px;
  color: #b0b0b0;
  transition: opacity 0.2s ease;
}

.flip-icon {
  width: 16px;
  height: 16px;
  filter: brightness(0) saturate(100%) invert(83%) sepia(3%) saturate(14%)
    hue-rotate(314deg) brightness(87%) contrast(84%);
}

.flashcard-content:hover .flip-hint {
  opacity: 1;
  color: #fae150;
}

.flashcard-content:hover .flip-icon {
  filter: brightness(0) saturate(100%) invert(89%) sepia(19%) saturate(1401%)
    hue-rotate(332deg) brightness(103%) contrast(94%);
}

.card-text {
  width: 100%;
}

.confirm-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirm-dialog {
  background-color: #1a1a1a;
  border: 1px solid #ffd700;
  border-radius: 8px;
  padding: 20px;
  min-width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.confirm-dialog h3 {
  margin: 0 0 15px 0;
  color: #ffd700;
}

.confirm-dialog p {
  margin: 0 0 20px 0;
  color: #e0e0e0;
}

.confirm-dialog-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.confirm-dialog button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
}

.confirm-button {
  background-color: #ffd700;
  color: black;
}

.confirm-button:hover {
  background-color: #ffed4a;
}

.cancel-button {
  background-color: #6c757d;
  color: white;
}

.cancel-button:hover {
  background-color: #5a6268;
}

.flipped-content {
  background-color: #3f3a1c; /* 어두운 노란색 배경 */
}

.deck-name-input {
  background-color: #333333;
  border: 1px solid #fae150;
  border-radius: 4px;
  color: #ffffff;
  padding: 8px 12px;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  max-width: 400px;
  min-width: 200px;
}

.deck-name-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(250, 225, 80, 0.2);
}

.deck-header h3 {
  margin: 0;
  padding: 8px 12px;
  border-radius: 4px;
  width: 100%;
  max-width: 400px;
}

.deck-header h3:hover {
  background-color: #333333;
}
