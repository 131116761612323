/* 다크모드 색상 변수 */
:root {
  --dark-bg: #1a1a1a;
  --dark-surface: #242424;
  --dark-surface-hover: #2a2a2a;
  --dark-border: #333333;
  --dark-text: #e0e0e0;
  --dark-text-secondary: #a0a0a0;
  --accent-yellow: #ffd43b;
  --accent-yellow-hover: #fab005;
  --accent-blue: #339af0;
}

.settings-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.settings-title {
  color: var(--dark-text);
  margin-bottom: 2rem;
}

.settings-nav {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid var(--dark-border);
  padding-bottom: 1rem;
}

.nav-button {
  background-color: transparent;
  color: var(--dark-text);
  border: none;
  padding: 0.8rem 1.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 6px;
}

.nav-button:hover {
  background-color: var(--dark-surface-hover);
}

.nav-button.active {
  background-color: var(--accent-yellow) !important;
  color: var(--dark-bg) !important;
}

.billing-section {
  max-width: 800px;
  padding: 1rem;
}

.card-info {
  background: var(--dark-surface);
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.card-info p {
  color: var(--dark-text-secondary);
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.card-details {
  background: var(--dark-surface-hover);
  border: 1px solid var(--dark-border);
  border-radius: 8px;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}

.card-details span {
  color: var(--dark-text);
  font-size: 1rem;
  font-weight: 500;
}

.edit-card {
  background-color: transparent;
  color: var(--dark-text);
  border: 1px solid var(--accent-yellow);
  padding: 0.5rem 1rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
}

.edit-card:hover {
  background-color: var(--accent-yellow);
  color: var(--dark-bg);
}

.subscription-plan {
  background: var(--dark-surface);
  border-radius: 12px;
  padding: 1.5rem;
}

.subscription-plan p {
  color: var(--dark-text-secondary);
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.plan-options {
  background: var(--dark-surface-hover);
  border: 1px solid var(--dark-border);
  border-radius: 8px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.plan-options label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  border: 1px solid var(--dark-border);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.plan-options label:hover {
  background-color: var(--dark-surface);
}

.plan-options input[type="radio"] {
  width: 1.2rem;
  height: 1.2rem;
  accent-color: var(--accent-yellow);
}

.plan-options label span {
  color: var(--dark-text);
  font-size: 1rem;
}

.update-plan {
  background-color: var(--accent-yellow);
  color: var(--dark-bg);
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 1rem;
  font-weight: 500;
  width: fit-content;
  align-self: flex-end;
}

.update-plan:hover {
  background-color: var(--accent-yellow-hover);
  transform: translateY(-1px);
}

.billing-section h3 {
  color: var(--dark-text);
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.avatar-section {
  margin-top: 2rem;
}

.current-avatar {
  margin: 1.5rem 0;
  text-align: center;
}

.current-avatar p {
  color: var(--dark-text-secondary);
  margin-bottom: 1rem;
}

.current-avatar-img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 1rem 0;
  border: 3px solid var(--accent-yellow);
  padding: 0.5rem;
  background-color: var(--dark-surface);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.current-avatar-img:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.avatar-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
}

.avatar-option {
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 6px;
  transition: all 0.2s ease;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.avatar-option:hover {
  background: var(--dark-surface-hover);
  transform: scale(1.1);
}

.avatar-option.selected {
  background: var(--dark-surface);
  border: 2px solid var(--accent-yellow);
}

.avatar-option.selected::after {
  content: "✓";
  position: absolute;
  top: -5px;
  right: -5px;
  background: var(--accent-yellow);
  color: var(--dark-bg);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.avatar-option-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.email-section {
  background: var(--dark-surface);
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.email-display {
  background: var(--dark-surface-hover);
  border: 1px solid var(--dark-border);
  padding: 1rem 1.5rem;
  border-radius: 6px;
  margin-top: 0.5rem;
}

.email-text {
  color: var(--dark-text);
  font-size: 1rem;
  font-weight: 500;
  display: block;
}

.email-section h3 {
  color: var(--dark-text);
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

.plan-options label:has(input:checked) {
  background-color: var(--dark-surface);
  border-color: var(--accent-yellow);
}

.language-section {
  max-width: 600px;
  padding: 1rem;
}

.language-options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;
}

.language-option {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 1px solid var(--dark-border);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: var(--dark-surface);
}

.language-option:hover {
  background-color: var(--dark-surface-hover);
  border-color: var(--accent-yellow);
}

.language-option input[type="radio"] {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 1rem;
  accent-color: var(--accent-yellow);
}

.language-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.language-name {
  font-size: 1rem;
  color: var(--dark-text);
  font-weight: 500;
}

.language-native {
  font-size: 0.9rem;
  color: var(--dark-text-secondary);
}

.language-option:has(input:checked) {
  background-color: var(--dark-surface);
  border-color: var(--accent-yellow);
}

.logout-section {
  margin-top: 40px;
  border-top: 1px solid var(--border-color);
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.logout-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background-color: var(--background-primary);
  color: #e6b800;
  border: 1px solid #fae150;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.logout-button:hover {
  background-color: var(--accent-yellow-hover);
  color: var(--dark-bg) !important;
}

.logout-button:hover img {
  filter: brightness(0) !important;
}

.logout-button img {
  width: 20px !important;
  height: 20px !important;
  filter: invert(76%) sepia(32%) saturate(1144%) hue-rotate(359deg)
    brightness(103%) contrast(103%) !important;
}

.avatar-section h3,
.email-section h3,
.billing-section h3,
.section-title {
  color: var(--dark-text);
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.settings-security-section {
  padding: 20px;
  background-color: var(--dark-surface);
  border-radius: 8px;
  border: 1px solid var(--dark-border);
}

/* Password Management Section */
.settings-password-section {
  margin-bottom: 40px;
}

.settings-password-section h3,
.settings-delete-account-section h3 {
  color: var(--dark-text);
  font-size: 18px;
  margin-bottom: 12px;
}

.settings-password-info-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: var(--dark-surface-hover);
  border-radius: 8px;
}

.settings-password-info-box p {
  color: var(--dark-text);
  font-size: 14px;
  margin-bottom: 8px;
}

.settings-password-info-box p:last-child {
  color: var(--dark-text-secondary);
  font-size: 13px;
}

.settings-delete-account-section {
  margin-top: 2rem;
}

.settings-privacy-section {
  background: var(--dark-surface);
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid var(--dark-border);
  margin-bottom: 12px;
}

.plan-details {
  color: var(--dark-text);
  padding: 1rem;
  background: var(--dark-surface-hover);
  border-radius: 8px;
  border: 1px solid var(--dark-border);
}

.plan-details p {
  color: var(--dark-text);
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.subscription-actions {
  margin-top: 20px;
}

.subscription-actions .manage-subscription {
  padding: 10px 20px;
  margin-right: 10px;
  background-color: var(--accent-yellow);
  color: var(--dark-bg);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.subscription-actions .manage-subscription:hover {
  background-color: var(--accent-yellow-hover);
}

.subscription-actions .update-payment {
  padding: 10px 20px;
  background-color: transparent;
  color: var(--accent-yellow);
  border: 1px solid var(--accent-yellow);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.subscription-actions .update-payment:hover {
  background-color: var(--accent-yellow);
  color: var(--dark-bg);
}

.security-section h3 {
  color: var(--dark-text);
  font-size: 18px;
  margin-bottom: 12px;
}

/* 새로운 구독 상태 카드 스타일 */
.subscription-status-card {
  background: var(--dark-surface);
  border: 1px solid var(--dark-border);
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.subscription-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.status-row,
.plan-row,
.date-row,
.trial-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: var(--dark-surface-hover);
  border: 1px solid var(--dark-border);
  border-radius: 8px;
}

.label {
  color: var(--dark-text-secondary);
  font-size: 0.9rem;
}

.value {
  color: var(--dark-text);
  font-weight: 500;
}

.status-badge {
  padding: 0.25rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
  color: #e1e1e1;
}

.status-badge.active {
  background-color: #2b8a3e;
  color: #d3f9d8;
}

.status-badge.trial {
  background-color: var(--accent-yellow);
  color: var(--dark-bg);
}

.status-badge.cancelled {
  background-color: #c92a2a;
  color: #ffe3e3;
}

.manage-subscription {
  margin-top: 1.5rem;
  text-align: center;
}

.manage-button {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background-color: var(--accent-yellow);
  color: var(--dark-bg);
  border: none;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: pointer;
}

.manage-button:hover {
  background-color: var(--accent-yellow-hover);
  transform: translateY(-1px);
}

.no-subscription {
  text-align: center;
  padding: 2rem;
  background: var(--dark-surface-hover);
  border-radius: 8px;
  border: 1px solid var(--dark-border);
}

.no-subscription p {
  color: var(--dark-text);
  margin-bottom: 1rem;
  font-size: 1rem;
}

.subscribe-button {
  padding: 0.8rem 1.5rem;
  background-color: var(--accent-yellow);
  color: var(--dark-bg);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.subscribe-button:hover {
  background-color: var(--accent-yellow-hover);
  transform: translateY(-1px);
}

/* 섹션 제목 스타일 업데이트 */
.section-title {
  color: var(--dark-text);
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.settings-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.settings-section-title {
  color: var(--dark-text);
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  width: 100%;
  background-color: var(--dark-surface);
  border-bottom: 1px solid var(--dark-border);
}

/* Security Section Styles */
.security-section-title {
  color: var(--dark-text);
  font-size: 18px;
  margin-bottom: 12px;
}

.security-info-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: var(--dark-surface-hover);
  border: 1px solid var(--dark-border);
  border-radius: 8px;
}

.security-info-text {
  color: var(--dark-text);
  font-size: 14px;
  margin-bottom: 8px;
}

.security-info-subtext {
  color: var(--dark-text-secondary);
  font-size: 13px;
}

.change-password-button {
  padding: 10px 20px;
  background-color: transparent;
  color: var(--accent-yellow);
  border: 1px solid var(--accent-yellow);
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.change-password-button:hover {
  background-color: var(--accent-yellow);
  color: var(--dark-bg);
}

.security-danger-box {
  padding: 20px;
  background-color: var(--dark-surface-hover);
  border: 1px solid #e03131;
  border-radius: 8px;
}

.security-danger-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.security-danger-text {
  color: #ff6b6b;
  font-size: 14px;
  margin-bottom: 8px;
}

.security-danger-subtext {
  color: var(--dark-text-secondary);
  font-size: 13px;
}

.delete-account-button {
  padding: 10px 20px;
  background-color: transparent;
  color: #ff6b6b;
  border: 1px solid #ff6b6b;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.delete-account-button:hover {
  background-color: #ff6b6b;
  color: var(--dark-bg);
}

.subscription-warning-modal {
  background-color: var(--dark-surface);
  padding: 30px;
  border-radius: 12px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border: 1px solid var(--dark-border);
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

.modal-header h3 {
  margin: 0;
  color: var(--accent-yellow);
  font-size: 1.2rem;
  width: 100%;
  text-align: center;
}

.modal-header .close-button {
  position: absolute;
  right: 0;
  top: 50%;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  color: var(--dark-text-secondary);
}

.close-button:hover {
  color: var(--accent-yellow);
}

.modal-body {
  text-align: center;
}

.modal-body p {
  margin: 8px 0;
  color: var(--dark-text);
  font-size: 0.95rem;
  line-height: 1.5;
}

.modal-body .warning-submessage {
  color: var(--dark-text-secondary);
  font-size: 0.9rem;
}

.modal-body .manage-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--accent-yellow);
  color: var(--dark-bg);
  text-decoration: none;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.modal-body .manage-button:hover {
  background-color: var(--accent-yellow-hover);
  transform: translateY(-1px);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.warning-icon {
  color: var(--accent-yellow);
  margin-bottom: 16px;
}

.warning-action-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
  padding: 12px 24px;
  background-color: var(--accent-yellow);
  color: var(--dark-bg);
  border: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.warning-action-button:hover {
  background-color: var(--accent-yellow-hover);
  transform: translateY(-1px);
}

.warning-action-button svg {
  width: 16px;
  height: 16px;
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #ffffff;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.back-to-home-button {
  color: #fff;
}
