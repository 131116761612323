.contact-container {
  max-width: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
}

.help-center-header {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  position: relative;
  border-bottom: 1px solid #eee;
  background-color: #fff !important;
}

.website-link {
  text-decoration: none;
  color: #333;
  font-size: 0.9rem;
}

.help-center-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0;
  color: #333;
  letter-spacing: -0.5px;
}

.help-center-content {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-content h1 {
  font-size: 2.8em;
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 40px;
}

.contact-info-section {
  text-align: center;
  margin: 2rem 0;
}

.email-contact {
  font-size: 1rem;
  color: #666;
}

.email-link {
  color: #2b6cb0;
  text-decoration: none;
  font-weight: 500;
}

.email-link:hover {
  text-decoration: underline;
}

.faq-section {
  margin-bottom: 50px;
  padding: 30px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.faq-section h2 {
  font-size: 1.8em;
  color: #1a1a1a;
  margin-bottom: 30px;
  text-align: center;
  border-bottom: 2px solid #ffd700;
  padding-bottom: 10px;
}

.faq-item {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.faq-item h3 {
  color: #ffd700;
  margin-bottom: 10px;
  font-size: 1.2em;
}

.faq-item p {
  color: #666;
  line-height: 1.6;
}

.contact-footer {
  text-align: center;
  padding: 40px 0;
  background-color: #f8f9fa;
  border-top: 1px solid #eee;
}

.back-home {
  display: inline-block;
  padding: 12px 30px;
  background-color: #1a1a1a;
  color: white;
  text-decoration: none;
  border-radius: 25px;
  transition: all 0.3s ease;
  font-weight: 500;
}

.back-home:hover {
  background-color: #ffd700;
  color: #1a1a1a;
  transform: translateY(-2px);
}

.help-categories {
  padding: 2rem 0;
}

.category-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.category-item {
  background: #fff;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.category-item h2 {
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #f0f0f0;
}

.help-links {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.help-link {
  color: #4a5568;
  text-decoration: none;
  font-size: 0.95rem;
  padding: 0.5rem;
  border-radius: 6px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
}

.help-link:hover {
  background-color: #f7fafc;
  color: #2b6cb0;
  padding-left: 0.8rem;
}

.help-link::after {
  content: "›";
  margin-left: auto;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .category-grid {
    grid-template-columns: 1fr;
  }
}

.help-center-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.help-center-layout {
  flex: 1;
  padding-top: 120px;
  display: flex;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
}

.help-sidebar {
  flex: 0 0 300px;
}

.help-content {
  flex: 1;
  min-width: 0;
}

.help-sidebar {
  width: 300px;
  border-right: 1px solid #eee;
  padding: 1rem;
  height: calc(100vh - 80px);
  overflow-y: auto;
}

.category-section {
  margin-bottom: 2rem;
}

.category-section h2 {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #eee;
}

.category-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-item {
  width: 100%;
  text-align: left;
  padding: 0.8rem;
  border: none;
  background: none;
  color: #4a5568;
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.menu-item:hover {
  background-color: #f0f7ff !important;
  color: #1a73e8;
  padding-left: 1rem;
  transition: all 0.2s ease;
}

.menu-item.active {
  background-color: #e8f0fe;
  color: #1a73e8;
  font-weight: 500;
  border-radius: 4px;
}

.help-content {
  flex: 1;
  padding: 2rem;
  overflow-y: auto;
}

.content-article {
  max-width: 800px;
  margin: 0 auto;
}

.content-article h1 {
  font-size: 1.8rem;
  margin-bottom: 2rem;
  color: #1a202c;
}

.welcome-message {
  text-align: center;
  padding: 40px;
}

.welcome-message h2 {
  font-size: 2rem;
  color: #111827;
  margin-bottom: 1rem;
}

.welcome-message p {
  font-size: 1.1rem;
  color: #4b5563;
}

.welcome-content {
  display: flex;
  align-items: center;
  gap: 60px;
  max-width: 1000px;
  margin: 0 auto;
}

.welcome-text {
  flex: 1;
}

.welcome-text h2 {
  font-size: 2.5rem;
  color: #111827;
  margin-bottom: 1rem;
}

.welcome-text p {
  font-size: 1.25rem;
  color: #4b5563;
  line-height: 1.6;
}

.welcome-image-container {
  flex: 1;
}

.welcome-image {
  width: 100%;
  height: auto;
  max-width: 400px;
}

.help-content-wrapper {
  max-width: 800px;
  padding: 2rem;
}

.help-content-wrapper h2 {
  font-size: 1.8rem;
  color: #2d3748;
  margin: 2.5rem 0 1.5rem 0;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e2e8f0;
}

.help-content-wrapper h2:first-child {
  margin-top: 0;
}

.help-content-wrapper p {
  font-size: 1rem;
  line-height: 1.6;
  color: #4a5568;
  margin-bottom: 1.5rem;
}

.help-content-wrapper ul,
.help-content-wrapper ol {
  margin: 1.5rem 0;
  padding-left: 2rem;
}

.help-content-wrapper li {
  margin-bottom: 1rem;
  line-height: 1.5;
}

.steps-section {
  background-color: #f8fafc;
  padding: 2rem;
  border-radius: 8px;
  margin: 2rem 0;
}

.steps-section h3 {
  font-size: 1.4rem;
  color: #2d3748;
  margin-bottom: 1.5rem;
}

.note-section {
  background-color: #fffbeb;
  border-left: 4px solid #f59e0b;
  padding: 1.5rem;
  margin: 2rem 0;
  border-radius: 0 8px 8px 0;
}

.feature-details {
  background-color: #f1f5f9;
  padding: 1.5rem;
  border-radius: 8px;
  margin: 1.5rem 0;
}

.feature-details h3 {
  margin-bottom: 1rem;
}

.tutorial-video {
  border-radius: 8px;
  margin: 2rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.steps-section {
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  margin: 2rem 0;
}

.steps-section h3 {
  color: #2d3748;
  margin-bottom: 1rem;
}

.steps-section ol {
  padding-left: 1.5rem;
}

.steps-section li {
  margin-bottom: 1rem;
  line-height: 1.6;
  color: #4a5568;
}

.steps-section strong {
  color: #2b6cb0;
}

.note-section {
  background: #ebf8ff;
  padding: 1rem;
  border-left: 4px solid #4299e1;
  border-radius: 4px;
  margin-top: 2rem;
}

.note-section p {
  color: #2c5282;
  margin: 0;
}

.instruction-icon {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.add-subunit-instruction {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
}

.add-subunit-instruction p {
  text-align: center;
  max-width: 600px;
}

.example-screenshot {
  width: 100%;
  max-width: 500px;
  height: auto;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feature-benefits {
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: #f0f9ff;
  border-radius: 8px;
  border: 1px solid #bae6fd;
}

.feature-benefits h3 {
  color: #0369a1;
  margin-bottom: 1rem;
}

.feature-benefits ul {
  margin: 0;
  padding-left: 1.5rem;
}

.feature-benefits li {
  margin: 0.5rem 0;
  color: #0c4a6e;
}

.steps-section li p {
  margin: 0.5rem 0 1rem 0;
  color: #4a5568;
}

@media (max-width: 768px) {
  .help-center-layout {
    flex-direction: column;
    padding-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .help-sidebar {
    flex: none;
    width: 100%;
  }

  .welcome-content {
    flex-direction: column-reverse;
    text-align: center;
    gap: 30px;
  }

  .welcome-text h2 {
    font-size: 2rem;
  }

  .welcome-text p {
    font-size: 1.1rem;
  }
}

.contact-info-section {
  background-color: #f9fafb;
  padding: 24px;
  border-radius: 8px;
  margin: 20px 0;
}

.contact-info-section h3 {
  color: #111827;
  margin-bottom: 16px;
  font-size: 1.25rem;
}

.contact-info-section p {
  margin-bottom: 12px;
  line-height: 1.6;
}

.contact-info-section strong {
  color: #111827;
  font-weight: 600;
}
