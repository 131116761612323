.sidebar2 {
  background-color: #1e1e1e;
  /*border-right: 1px solid #444;*/
  padding: 20px 0;
  box-shadow: none;
  height: calc(100vh - 40px);
  border-radius: 0;
  position: sticky;
  top: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 40px);
}

.sidebar2 ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar2 li {
  margin-bottom: 5px;
}

.sidebar2 a {
  display: block;
  text-decoration: none;
  color: #e0e0e0;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 0;
  transition: background-color 0.2s ease;
  border-left: 3px solid transparent;
}

.sidebar2 a:hover {
  background-color: #2a2a2a;
  transform: none;
}

.sidebar2 a.active {
  font-weight: 500;
  background-color: #333;
  color: #fae150;
  border-left-color: #fae150;
}

.course-detail {
  display: flex;
  flex-direction: column;
}

.course-content {
  display: flex;
  flex-direction: row;
  background-color: #1e1e1e;
  border-radius: 24px;
  overflow: hidden;
}

.content-area {
  flex-grow: 1;
  padding: 20px;
  background-color: #1e1e1e;
  color: #e0e0e0;
}

.sidebar2::-webkit-scrollbar {
  width: 6px;
}

.sidebar2::-webkit-scrollbar-track {
  background: transparent;
}

.sidebar2::-webkit-scrollbar-thumb {
  background-color: #444;
  border-radius: 3px;
}

.sidebar2::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
