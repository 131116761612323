:root {
  --dark-bg: #1a1a1a;
  --dark-surface: #2a2a2a;
  --dark-surface-secondary: #333333;
  --dark-border: #404040;
  --dark-text: #ffffff;
  --dark-text-secondary: #b0b0b0;
  --accent-yellow: #fae150;
}

.todo-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px;
  background-color: var(--dark-bg);
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji",
    "Segoe UI Symbol";
  overflow: visible !important;
}

.todo-header {
  text-align: left;
  color: var(--dark-text);
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--dark-border);
  font-family: "DM Sans", sans-serif;
}

.list-form {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 20px;
  background-color: var(--dark-surface);
  border-radius: 12px;
  border: 1px solid var(--dark-border);
  margin-bottom: 24px;
  margin-top: 20px;
}

.list-form input {
  flex: 1;
  height: 44px;
  padding: 0 16px;
  background-color: var(--dark-surface-secondary);
  border: 1px solid var(--dark-border);
  border-radius: 8px;
  color: var(--dark-text);
  font-size: 14px;
  font-family: "DM Sans", sans-serif;
  transition: all 0.3s ease;
}

.list-form input:focus {
  border-color: var(--accent-yellow);
  box-shadow: 0 0 0 2px rgba(250, 225, 80, 0.1);
  outline: none;
}

.list-form input::placeholder {
  color: #808080;
}

.list-form input.sidebar-input {
  height: 36px;
  font-size: 13px;
  padding: 0 12px;
}

.list-form button {
  height: 44px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: transparent;
  border: 1px solid var(--dark-border);
  border-radius: 8px;
  color: var(--dark-text-secondary);
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.list-form button:hover {
  background-color: var(--dark-surface-secondary);
  border-color: var(--accent-yellow);
  color: var(--accent-yellow);
}

.list-form button img {
  width: 16px !important;
  height: 16px !important;
  opacity: 0.8 !important;
  filter: brightness(0) saturate(100%) invert(83%) sepia(3%) saturate(14%)
    hue-rotate(314deg) brightness(87%) contrast(84%) !important;
  transition: filter 0.2s ease !important;
}
.list-form button:hover img {
  opacity: 1 !important;
  filter: brightness(0) saturate(100%) invert(89%) sepia(19%) saturate(1401%)
    hue-rotate(332deg) brightness(103%) contrast(94%) !important;
}

.list-form input:focus {
  border-color: var(--accent-yellow);
  box-shadow: 0 0 0 2px rgba(250, 225, 80, 0.1);
}

.list-form button img {
  width: 16px;
  height: 16px;
}

.task-list {
  background-color: var(--dark-surface);
  border-radius: 16px;
  margin-bottom: 24px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  overflow: visible !important;
  position: relative;
  border: 1px solid var(--dark-border);
}

.task-list:hover {
}

.task-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--dark-border);
}

.task-list-header h2 {
  font-size: 18px;
  font-weight: 600;
  color: var(--dark-text);
}

.task-list-content {
  padding: 15px;
  position: relative;
}

.task-list-content ul {
  list-style-type: none;
  padding: 0;
  min-height: 8px;
}

.task-list-content li {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 8px;
  transition: none;
  color: var(--dark-text);
  background-color: var(--dark-surface-secondary);
  border: 1px solid var(--dark-border);
}

.task-list-content li:hover {
  background-color: transparent;
}

.task-list-content li input[type="checkbox"] {
  margin-right: 10px;
  border-radius: 2px;
  appearance: none;
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid black;
  outline: none;
  cursor: pointer;
}

.task-list-content li input[type="checkbox"]:checked {
  background-color: transparent;
  position: relative;
}

.task-list-content li input[type="checkbox"]:checked::after {
  content: "\2714";
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
}

.task-list-content li.completed span {
  text-decoration: line-through;
  color: #999;
}

.task-form {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
  padding: 12px;
  background-color: var(--dark-surface-secondary);
  border: 1px solid var(--dark-border);
  border-radius: 8px;
  transition: all 0.2s ease;
}

.task-form:hover {
  border-color: var(--accent-yellow);
  background-color: var(--dark-surface);
}

.task-form input {
  flex: 1;
  height: 32px;
  padding: 0 12px;
  background-color: var(--dark-surface);
  border: 1px solid var(--dark-border);
  border-radius: 6px;
  color: var(--dark-text);
  font-size: 13px;
  font-family: "DM Sans", sans-serif;
  transition: all 0.2s ease;
}

.task-form input::placeholder {
  color: var(--dark-text-secondary);
  opacity: 0.7;
}

.task-form input:focus {
  border-color: var(--accent-yellow);
  outline: none;
  box-shadow: 0 0 0 2px rgba(250, 225, 80, 0.1);
}

.task-form button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  min-width: 32px;
  padding: 0;
  background-color: transparent;
  border: 1px solid var(--dark-border);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.task-form button:hover {
  border-color: var(--accent-yellow);
  background-color: var(--dark-surface);
}

.task-form button img {
  width: 16px !important;
  height: 16px !important;
  opacity: 0.7;
  filter: brightness(0) saturate(100%) invert(70%) !important;
  transition: all 0.2s ease;
}

.task-form button:hover img {
  opacity: 1;
  filter: brightness(0) saturate(100%) invert(89%) sepia(19%) saturate(1401%)
    hue-rotate(332deg) brightness(103%) contrast(94%) !important;
}

.todo-menu-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  opacity: 0.7;
  filter: brightness(0) saturate(100%) invert(100%);
  transition: all 0.2s ease;
}

.todo-menu-icon:hover {
  opacity: 1;
  filter: brightness(0) saturate(100%) invert(89%) sepia(19%) saturate(1401%)
    hue-rotate(332deg) brightness(103%) contrast(94%);
}

.header-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.header-buttons span {
  color: var(--dark-text-secondary);
  cursor: pointer;
  transition: color 0.2s ease;
}

.header-buttons span:hover {
  color: var(--accent-yellow);
}

.list-menu {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  min-width: 200px;
  background-color: var(--dark-surface-secondary);
  border: 1px solid var(--dark-border);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  padding: 8px;
  z-index: 1000;
}

.list-menu button {
  display: block;
  width: 100%;
  padding: 5px 10px;
  margin-top: 5px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
}

.list-menu button:hover {
  background-color: #f5f5f5;
}

.task-list {
  position: relative;
}

.header-buttons {
  position: relative;
}

.list-form input,
.task-form input,
.task-list-header input,
.task-list-content input[type="text"] {
  flex-grow: 1;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  /*background-color: #f7f6f3;*/
  /* color: #231c16;*/
  outline: none; /* 포커스 시 기본 아웃라인 제거 */
  transition: none; /* 트랜지션 효과 제거 */
}

.list-form input:focus,
.task-form input:focus,
.task-list-header input:focus,
.task-list-content input[type="text"]:focus {
  border-color: #e0e0e0; /* 포커스 시 테두리 색상 유지 */
  box-shadow: none; /* 포커스 시 그림자 효과 제거 */
}

.task-context-menu {
  position: fixed;
  background-color: #2c2c2c;
  border: 1px solid #404040;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  min-width: 80px;
  width: fit-content;
}

.task-context-menu button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 10px 12px;
  margin: 2px 0;
  border: none;
  background-color: transparent;
  border-radius: 8px;
  color: var(--dark-text-secondary);
  font-size: 13px;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  line-height: 1;
}

.task-context-menu button:hover {
  background-color: var(--dark-surface) !important;
  color: var(--accent-yellow) !important;
}

.color-option.selected {
  border: 2px solid #000;
  box-shadow: 0 0 0 1px #e0e0e0;
}

.todo-list-menu-button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 10px 12px;
  margin: 2px 0;
  border: none;
  background-color: transparent;
  border-radius: 8px;
  color: var(--dark-text-secondary);
  font-size: 13px;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  line-height: 1;
}
.todo-list-menu-button:hover {
  background-color: var(--dark-surface) !important;
  color: var(--accent-yellow) !important;
}

.todo-list-menu-button img {
  width: 14px;
  height: 14px;
  opacity: 0.7;
  filter: brightness(0) saturate(100%) invert(70%) !important;
  transition: all 0.2s ease;
  position: relative;
  top: 2px;
  margin-right: 4px;
}

.todo-list-menu-button:hover img {
  opacity: 1 !important;
  filter: brightness(0) saturate(100%) invert(89%) sepia(19%) saturate(1401%)
    hue-rotate(332deg) brightness(103%) contrast(94%) !important;
}

/* 기존 스타일 유지하고 사이드바 모드를 위한 스타일 추가 */

.todo-container.sidebar-mode {
  padding: 16px;
  font-size: 0.9em;
}

.sidebar-mode .todo-header {
  font-size: 1.2em;
  margin-bottom: 16px;
  padding-left: 4px;
}

.sidebar-mode .list-form {
  flex-direction: row;
  gap: 8px;
  margin-bottom: 16px;
  padding: 0 4px;
}

.sidebar-mode .list-form input {
  width: 100%;
  padding: 5px;
  font-size: 0.9em;
}

.sidebar-mode .list-form button {
  padding: 5px;
  font-size: 0.9em;
}

.sidebar-mode .task-list {
  margin-bottom: 12px;
  padding: 12px;
  border-radius: 8px;
}

.sidebar-mode .task-list-header h2 {
  font-size: 1em;
}

.sidebar-mode .task-list-content {
  font-size: 0.9em;
}

.sidebar-mode .task-form {
  padding: 8px;
  margin-top: 8px;
}

.sidebar-mode .task-form input {
  height: 28px;
  font-size: 12px;
}

.sidebar-mode .task-form button {
  height: 28px;
  min-width: 28px;
}

.sidebar-mode .task-form button img {
  width: 14px !important;
  height: 14px !important;
}

/* 사이드바 내의 드래그 앤 드롭 영역 스타일 조정 */
.sidebar-mode .task-lists {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
}

.sidebar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 6px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

.sidebar-button img {
  width: 16px;
  height: 16px;
}

/* 사이드바 모드에서의 컨텍스트 메뉴 위치 조정 */
.sidebar-mode .list-menu,
.sidebar-mode .task-context-menu {
  top: calc(100% + 8px);
  right: 0;
}

/* 사이드바 모드일 때의 스타일 */
.list-form.sidebar-form {
  padding: 12px;
}

.list-form.sidebar-form input,
.list-form.sidebar-form button {
  height: 36px;
  font-size: 13px;
}

.list-form.sidebar-form button {
  padding: 0 12px;
}

.task-delete-button {
  width: 100%;
  padding: 6px 10px;
  border: none;
  background: none;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
}

.task-delete-button.dark-mode-delete {
  color: #ffffff;
}

.task-delete-button.dark-mode-delete:hover {
  background-color: var(--dark-surface);
  color: var(--accent-yellow);
}

.custom-confirm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-confirm-dialog {
  background-color: var(--dark-surface);
  border: 1px solid var(--dark-border);
  border-radius: 12px;
  padding: 24px;
  width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.custom-confirm-message {
  color: var(--dark-text);
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 1.5;
}

.custom-confirm-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.custom-confirm-button {
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.custom-confirm-cancel {
  background-color: transparent;
  border: 1px solid var(--dark-border);
  color: var(--dark-text-secondary);
}

.custom-confirm-cancel:hover {
  border-color: var(--accent-yellow);
  color: var(--accent-yellow);
}

.custom-confirm-delete {
  background-color: #e53935;
  border: none;
  color: white;
}

.custom-confirm-delete:hover {
  background-color: #d32f2f;
}

.task-priority {
  display: inline-block;
  min-width: 20px;
  margin-right: 8px;
  color: var(--accent-yellow);
  font-weight: 600;
}

.task-number {
  display: inline-block;
  min-width: 20px;
  color: var(--accent-yellow);
  font-weight: 500;
  margin-right: 8px;
}

.task-edit-input {
  background: none;
  border: none;
  outline: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  width: 100%;
  padding: 0;
  margin: 0;
}

.task-edit-input:focus {
  border-bottom: 1px dashed #666;
}

/* task-form 관련 스타일 수정 */
.task-form-container {
  position: relative;
  margin-top: 12px;
  z-index: 2;
  pointer-events: auto;
}

.task-list-content {
  padding: 15px;
  position: relative;
}

.task-list-content ul {
  min-height: 8px; /* 드래그 영역을 위한 최소 높이 설정 */
}

/* 드롭 영역 스타일 */
.task-list-content.drag-over ul {
  padding-bottom: 8px; /* 드래그 시 여유 공간 */
}
