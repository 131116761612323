.update-subscription-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
}

.update-subscription-content {
  background: white;
  padding: clamp(24px, 4vw, 48px);
  border-radius: 24px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);
  text-align: center;
  width: fit-content;
  min-width: 300px;
  max-width: 90%;
  margin: 20px auto;
}

.update-subscription-title {
  font-size: clamp(24px, 5vw, 42px);
  font-weight: 700;
  color: #231c16;
  margin-bottom: 24px;
  line-height: 1.2;
  display: inline-block;
  white-space: nowrap;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.update-subscription-message {
  font-size: clamp(16px, 3vw, 20px);
  color: #666;
  margin-bottom: 40px;
  line-height: 1.5;
}

.subscription-actions {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.renew-subscription-button {
  display: inline-block;
  padding: 16px 32px;
  background-color: #fae150;
  color: #231c16;
  border: none;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.2s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.renew-subscription-button:hover {
  background-color: #f8d730;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 480px) {
  .renew-subscription-button {
    padding: 12px 24px;
    font-size: 16px;
  }
}

.update-subscription-back-button {
  color: #231c16;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.update-subscription-back-button:hover {
  background-color: #f5f5f5;
  transform: translateX(-4px);
}
